import { Box, Button, chakra, Divider, FormControl, FormLabel, Img, Input, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import api from '../../api';
import state from '../../core/state';

import { helpers } from '../../core/helpers';
import PAEANRoutes from '../../core/routes/routes';

import PasswordField from '../shared/password-field/password-field';

import banner from '../../core/assets/img/login-banner.jpg';

import './login.scss';

export const Login = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const isError = username === '' || password === '';
  const setIsLoggedIn = state.useAuth((state) => state.setIsLoggedIn);
  const setRefreshToken = state.useRefreshToken((state) => state.setRefreshToken);
  const setRefreshTokenExpireTime = state.useRefreshToken((state) => state.setRefreshTokenExpireTime);
  const setUserInfo = state.currentUser((state) => state.setUserInfo);
  const setUserRoles = state.userRoles((state) => state.setUserRoles);
  const [errorLogin, setErrorlogin] = useState(false);

  const mutation = useMutation(api.auth.login, {
    onSuccess: (data) => {
      const accessToken = data?.data?.access_token;
      const refreshToken = data?.data?.refresh_token;
      const refreshTokenExp = data?.data?.exp_time;

      const parseJwt = (token) => {
        try {
          const base64Url = token.split('.')[1];
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          const jsonPayload = decodeURIComponent(
            window
              .atob(base64)
              .split('')
              .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              })
              .join('')
          );
          return JSON.parse(jsonPayload);
        } catch (e) {
          return null;
        }
      };

      const userData = parseJwt(accessToken);

      setIsLoggedIn(true, accessToken);
      setRefreshToken(refreshToken);
      setRefreshTokenExpireTime(refreshTokenExp);
      setUserInfo(userData.user);
      setUserRoles(userData.user.roles);

      navigate(PAEANRoutes.HOME);
    },
    onError: () => {
      setErrorlogin(true);
    },
  });

  const handleOnLoginSubmit = () => {
    const pass = helpers.hashPassword(password);
    setErrorlogin(false);
    mutation.mutate({ username, password: pass });
  };

  const renderProjectDetails = () => {
    return (
      <div className="login__project-details">
        <p>
          The <b>Interreg IPA Cross-border Cooperation Programme Croatia – Bosnia and Herzegovina – Montenegro 2014-2020</b> is a trilateral programme dedicated to achieve harmonious development of
          the cross-border area and the European Union as a whole.
        </p>
        <Divider my="20px" />

        <div>
          A lengthy title indeed, but every term in it carries great importance for Croatia’s, Bosnia and Herzegovina’s and Montenegro’s cooperation:
          <ul>
            <li>
              <b>Interreg</b> stands for territorial cooperation among countries of Europe. It is a framework developed by the EU that allows joint actions and policy exchanges between different
              states.
            </li>
            <li>
              <b>IPA</b> is an abbreviation for Instrument for Pre-Accession Assistance, meaning the EU framework that allows cooperation among EU Member States and candidate and/or potential
              candidate countries.
            </li>
            <li>
              <b>Cross-border Cooperation</b> (Programme), oftentimes shortened to CBC, refers to one of Interreg’s three strands of cooperation: cross-border, transnational and interregional.
            </li>
            <li>
              <b>Croatia, Bosnia and Herzegovina and Montenegro</b> refers to countries participating in the Programme which have agreed to jointly overcome challenges crossing national borders and
              mutually use untapped growth potential in the cross-border area.
            </li>
            <li>
              <b>2014 – 2020</b> stands for the current multiannual financial framework, i.e. the seven-year timeframe and planning horizon of using EU resources.
            </li>
          </ul>
        </div>
        <Divider my="20px" />
        <Table mt="30px" variant="striped" colorScheme="gray" size="sm">
          <Thead>
            <Tr>
              <Th>Priority axis</Th>
              <Th>Union support</Th>
              <Th>National counterpart</Th>
              <Th>Total funding</Th>
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>PA1</Td>
              <Td>EUR 8,573,297 </Td>
              <Td>EUR 1,512,935 </Td>
              <Td>EUR 10,086,232</Td>
            </Tr>
            <Tr>
              <Td>PA2</Td>
              <Td>EUR 14,288,830 </Td>
              <Td>EUR 2,521,559 </Td>
              <Td>EUR 16,810,389</Td>
            </Tr>
            <Tr>
              <Td>PA3</Td>
              <Td>EUR 17,146,595 </Td>
              <Td>EUR 3,025,870 </Td>
              <Td>EUR 20,172,465</Td>
            </Tr>
            <Tr>
              <Td>PA4</Td>
              <Td>EUR 11,431,063 </Td>
              <Td>EUR 2,017,247 </Td>
              <Td>EUR 13,448,310</Td>
            </Tr>
            <Tr>
              <Td>PA5</Td>
              <Td>EUR 5,715,531 </Td>
              <Td>EUR 1,008,625 </Td>
              <Td>EUR 6,724,156</Td>
            </Tr>
            <Tr>
              <Td>
                <b>Total</b>
              </Td>
              <Td>EUR 57,155,316 </Td>
              <Td>EUR 10,086,236 </Td>
              <Td>EUR 67,241,552</Td>
            </Tr>
          </Tbody>
        </Table>

        <Box mt="30px">
          <Text>Ime Vodećeg partnera i projektnih partnera:</Text>
          <ul>
            <li>TRA Agencija za razvoj opcine tešanj</li>
            <li>Osnovna škola josipa matoša vukovar</li>
            <li>UZPD</li>
            <li>Javna ustanova za djecu i osobe sa posebnim potrebama ZDK</li>
          </ul>
        </Box>

        <Divider my="20px" />

        <Text size="small" textAlign="center">
          <a href="https://www.interreg-hr-ba-me.eu/about-programme/programme-facts/">
            <span className="material-icons">language</span>Programska web stranica
          </a>
          <a href="https://www.interreg-hr-ba-me.eu/about-our-projects/">
            <span className="material-icons">language</span>Fond
          </a>
          <br />
          <Text textAlign="center" display="block" fontSize="sm" as="em" mt="20px">
            This publication (website, video) has been produced with the assistance of the European Union. The contents of this publication are the sole responsibility of 'JU ZDK' and can in no way be
            taken to reflect the views of the European Union.
          </Text>
        </Text>
      </div>
    );
  };

  return (
    <div className="login">
      <div className="login-form-wrapper">
        <h2 className="login-form-title">{t('APP_NAME')}</h2>
        <chakra.form
          onSubmit={(e) => {
            e.preventDefault();
            handleOnLoginSubmit();
          }}
          width="100%"
          className="login-form"
        >
          <Stack spacing="8">
            <FormControl id="username" isRequired>
              <FormLabel fontWeight="normal">{t('LOGIN_FORM.USERNAME')}</FormLabel>
              <Input name="username" type="text" required borderRadius="0" onChange={(e) => setUsername(e.target.value)} />
            </FormControl>
            <PasswordField {...props} showLabel={true} onChange={(e) => setPassword(e.target.value)} required />
            {errorLogin && (
              <Text fontSize="sm" p={0} m={0} color="red">
                Neispravni pristupni podaci
              </Text>
            )}
            <Button disabled={isError} isLoading={mutation.isLoading} type="submit" colorScheme="pink" marginTop="20px" borderRadius="0" fontWeight="normal" w="auto">
              {t('LOGIN_FORM.SUBMIT')}
            </Button>
          </Stack>
        </chakra.form>
        <Img src={banner} alt="" w="100%" h="auto" mt="30px" />
      </div>
      {renderProjectDetails()}
    </div>
  );
};

export default Login;
