import { Box, Button, Checkbox, Divider, Flex, Heading, Input, ListItem, OrderedList, Spinner, Stack, Table, Tbody, Td, Text, Textarea, Th, Thead, Tr, UnorderedList } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../../api';
import constants from '../../../config/constants';
import routes from '../../../core/routes/routes';
import SpinnerCentered from '../../shared/spinner-centered/spinner-centered';

import './add-entry-diagnostic.scss';

const AddEntryDiagnostic = () => {
  const { clientId, id } = useParams();

  const navigate = useNavigate();

  const selectedDiagnostic = useQuery([constants.QUERY_NAME.HANDLE_FETCH_ENTRY_DIAGNOSTIC, id], ({ queryKey }) => api.entryDiagnostics.getEntryDiagnostic(queryKey[1]), { enabled: !!id });

  const selectedClientId = clientId || selectedDiagnostic?.data?.person_id;

  const selectedClient = useQuery([constants.QUERY_NAME.HANDLE_FETCH_CLIENT, selectedClientId], ({ queryKey }) => api.clients.getClient(queryKey[1]), { enabled: !!selectedClientId });

  const addEntryDiagnosticMutation = useMutation(api.entryDiagnostics.addEntryDiagnostic, {
    onSuccess: () => {
      toast.success('Ulazna dijagnostika uspješno kreirana.');
      navigate(`${routes.CLIENTS}/${selectedClientId}`);
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom kreiranja ulazne dijagnostike');
    },
  });

  const updateEntryDiagnosticMutation = useMutation(api.entryDiagnostics.updateEntryDiagnostic, {
    onSuccess: () => {
      toast.success('Ulazna dijagnostika uspješno ažurirana.');
      navigate(`${routes.CLIENTS}/${selectedClientId}`);
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom ažuriranja ulazne dijagnostike');
    },
  });

  if (selectedClient.data?.first_checkup_completed && !id) {
    toast.info('Ulazna dijagnostika za korisnika već postoji');
    navigate(`${routes.CLIENTS}/${id}`);
    return null;
  }

  const submitEntryDiagnostic = (values) => {
    if (id) {
      updateEntryDiagnosticMutation.mutate({ id, values });
    } else {
      addEntryDiagnosticMutation.mutate({ ...values, clientId: parseInt(clientId, 10) });
    }
  };

  const submittingData = addEntryDiagnosticMutation.isLoading || updateEntryDiagnosticMutation.isLoading;
  const isLoading = selectedDiagnostic.isLoading || selectedClient.isLoading;

  return (
    <Box className="add-entry-diagnostic">
      <Heading size="lg" textAlign="center" mb="50px">
        <Text>
          {selectedClient.data?.first_checkup_completed && <Text color={constants.PRIMARY_COLOR} display="inline">
            Uredi:
          </Text>}
          Opservacijski i razvojni izvještaj
        </Text>
        <Text>Ulazna dijagnostika</Text>
      </Heading>
      <SpinnerCentered show={isLoading} />
      {!isLoading && (
        <Box className="add-entry-diagnostic__form-wrapper">
          <Heading size="md" my="15px;">
            1. Lični podaci osobe sa posebnim potrebama
          </Heading>
          <Table variant="striped" size="md" padding="10px" marginTop="15px">
            <Thead>
              <Tr>
                <Th>Ime</Th>
                <Th>Prezime</Th>
                <Th>Datum rođenja</Th>
                <Th>Adresa stanovanja</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{selectedClient?.data?.first_name}</Td>
                <Td>{selectedClient?.data?.last_name}</Td>
                <Td>{selectedClient?.data?.birth_date && moment(selectedClient?.data?.birth_date).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)}</Td>
                <Td>
                  {selectedClient?.data?.address_street} {selectedClient?.data?.muncipality_residence} {selectedClient?.data?.postal_code} {selectedClient?.data?.city_of_residence}
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <Formik
            initialValues={{
              isExtendingEntryProcess: selectedDiagnostic?.data?.is_extending_entry_process || false,
              clientOnStimulationFrom: selectedDiagnostic?.data?.client_on_stimulation_from || '',
              clientOnStimulationTo: selectedDiagnostic?.data?.client_on_stimulation_to || '',
              extendingEntryProcessTo: selectedDiagnostic?.data?.extending_entry_process_to || '',
              isAddingToGroupForWorkingArea: selectedDiagnostic?.data?.is_adding_to_group_for_working_area || false,
              addingToGroupForWorkingAreaFrom: selectedDiagnostic?.data?.adding_to_group_for_working_area_from || '',
              addingToGroupForWorkingAreaTo: selectedDiagnostic?.data?.adding_to_group_for_working_area_to || '',
              isAddingToGroupForStimulationAndCare: selectedDiagnostic?.data?.is_adding_to_group_for_stimulation_and_care || false,
              addingToGroupForStimulationAndCareFrom: selectedDiagnostic?.data?.adding_to_group_for_stimulation_and_care_from || '',
              isAddingToOtherCenterForStimulation: selectedDiagnostic?.data?.is_adding_to_center_for_stimulation || false,
              AddingToOtherCenterForStimulationDetails: selectedDiagnostic?.data?.adding_to_center_for_stimulation_details || '',
              helpDetailsForProfessionalIntegration: selectedDiagnostic?.data?.help_details_for_professional_integration || '',
              selfCare: selectedDiagnostic?.data?.self_care || '',
              congitiveCompetents: selectedDiagnostic?.data?.congitive_competents || '',
              emotionalAndPhysicCompetents: selectedDiagnostic?.data?.emotional_and_physic_competents || '',
              socialSkills: selectedDiagnostic?.data?.social_skills || '',
              workingCompetentsAndPerspective: selectedDiagnostic?.data?.working_competents_and_perspective || '',
            }}
            onSubmit={(values) => {
              submitEntryDiagnostic(values);
            }}
          >
            <Form id="add-entry-diagnostic">
              <Divider my="30px" />
              <Heading size="md" my="15px">
                2. Stav radionice prema sposobnosti ostvarivanja postignuća osobe sa posebnim potrebama
              </Heading>
              <Text>
                2.1 Osoba sa posebnim potrebama je bila na podsticaju od
                <Flex display="inline-block" mx="10px">
                  <Field name="clientOnStimulationFrom">{({ field }) => <Input {...field} variant="flushed" type="text" id="from" placeholder="od" />}</Field>
                </Flex>
                do
                <Flex display="inline-block" mx="10px">
                  <Field name="clientOnStimulationTo">{({ field }) => <Input {...field} variant="flushed" type="text" id="to" placeholder="do" borderRadius="0" />}</Field>
                </Flex>
                na ulaznom postupku.
              </Text>
              <Divider my="15px" />
              <Box>
                <Text fontSize="medium" my="15px">
                  2.2 Osobi sa posebnim potrebama su potrebne dalje mjere podsticaja kroz:
                </Text>
                <UnorderedList listStyleType="none">
                  <ListItem>
                    <Stack direction="row">
                      <Field name="isExtendingEntryProcess">
                        {({ field }) => (
                          <Checkbox {...field} defaultChecked={selectedDiagnostic?.data?.is_extending_entry_process || false} variant="flushed" type="text" id="to" placeholder="" borderRadius="0">
                            produžavanje ulaznog postupka do
                          </Checkbox>
                        )}
                      </Field>
                      <Flex display="inline-block" mx="10px">
                        <Field name="extendingEntryProcessTo">{({ field }) => <Input {...field} variant="flushed" type="text" id="to" placeholder="do" borderRadius="0" />}</Field>
                      </Flex>
                    </Stack>
                  </ListItem>
                  <ListItem>
                    <Stack direction="row">
                      <Field name="isAddingToGroupForWorkingArea">
                        {({ field }) => (
                          <Checkbox
                            {...field}
                            defaultChecked={selectedDiagnostic?.data?.is_adding_to_group_for_working_area || false}
                            variant="flushed"
                            type="text"
                            id="to"
                            placeholder=""
                            borderRadius="0"
                          >
                            prijem u grupu obuke za radno područje
                          </Checkbox>
                        )}
                      </Field>
                      <Flex display="inline-block" mx="10px">
                        <Field name="addingToGroupForWorkingAreaFrom">{({ field }) => <Input {...field} variant="flushed" type="text" id="to" placeholder="od" borderRadius="0" />}</Field>
                      </Flex>
                      <Flex display="inline-block" mx="10px">
                        <Field name="addingToGroupForWorkingAreaTo">{({ field }) => <Input {...field} variant="flushed" type="text" id="to" placeholder="do" borderRadius="0" />}</Field>
                      </Flex>
                    </Stack>
                  </ListItem>
                  <ListItem>
                    <Stack direction="row">
                      <Field name="isAddingToGroupForStimulationAndCare">
                        {({ field }) => (
                          <Checkbox
                            {...field}
                            defaultChecked={selectedDiagnostic?.data?.is_adding_to_group_for_stimulation_and_care || false}
                            variant="flushed"
                            type="text"
                            id="to"
                            placeholder=""
                            borderRadius="0"
                          >
                            prijem u grupu za podsticaj i zbrinjavanje od
                          </Checkbox>
                        )}
                      </Field>
                      <Flex display="inline-block" mx="10px">
                        <Field name="addingToGroupForStimulationAndCareFrom">{({ field }) => <Input {...field} variant="flushed" type="text" id="to" placeholder="" borderRadius="0" />}</Field>
                      </Flex>
                    </Stack>
                  </ListItem>
                  <ListItem>
                    <Stack direction="row">
                      <Field name="isAddingToOtherCenterForStimulation">
                        {({ field }) => (
                          <Checkbox
                            {...field}
                            defaultChecked={selectedDiagnostic?.data?.is_adding_to_center_for_stimulation || false}
                            variant="flushed"
                            type="text"
                            id="to"
                            placeholder=""
                            borderRadius="0"
                          >
                            prijem u drugu ustanovu za zbrinjavanje / mjere zbrinjavanja, prijedlog:
                          </Checkbox>
                        )}
                      </Field>
                      <Flex display="inline-block" mx="10px">
                        <Field name="AddingToOtherCenterForStimulationDetails">{({ field }) => <Input {...field} variant="flushed" type="text" id="to" placeholder="" borderRadius="0" />}</Field>
                      </Flex>
                    </Stack>
                  </ListItem>
                </UnorderedList>
              </Box>
              <Divider my="15px" />
              <Box>
                <Text fontSize="medium" my="15px">
                  2.3 Osobi sa posebnim potrebama su za profesionalnu integraciju potrebna sljedeća pomoćna sredstva / tehnička radna sredstva:
                </Text>
                <Field name="helpDetailsForProfessionalIntegration">{({ field }) => <Textarea {...field} id="to" placeholder="Detaljan opis" borderRadius="0" />}</Field>
              </Box>
              <Divider my="15px" />

              <Box>
                <Text fontSize="medium" my="15px">
                  2.4 Rezimirani ili dopunjujući stav radionice, ostale napomene ili prijedlozi za dalji razvoj osobe sa posebnim potrebama po područjima:
                </Text>
                <OrderedList>
                  <ListItem my="30px">
                    <Text fontWeight="bold" fontSize="small" mb="15px">
                      BRIGA O SEBI:
                    </Text>
                    <Field name="selfCare">{({ field }) => <Textarea {...field} id="to" placeholder="Detaljan opis" borderRadius="0" />}</Field>
                  </ListItem>
                  <ListItem my="30px">
                    <Text fontWeight="bold" fontSize="small" mb="15px">
                      KOGNITIVNE KOMPETENCIJE:
                    </Text>
                    <Field name="congitiveCompetents">{({ field }) => <Textarea {...field} id="to" placeholder="Detaljan opis" borderRadius="0" />}</Field>
                  </ListItem>
                  <ListItem my="30px">
                    <Text fontWeight="bold" fontSize="small" mb="15px">
                      EMOCIONALNE I PSIHIČKE KOMPETENCIJE:
                    </Text>
                    <Field name="emotionalAndPhysicCompetents">{({ field }) => <Textarea {...field} id="to" placeholder="Detaljan opis" borderRadius="0" />}</Field>
                  </ListItem>
                  <ListItem my="30px">
                    <Text fontWeight="bold" fontSize="small" mb="15px">
                      SOCIJALNE VJEŠTINE:
                    </Text>
                    <Field name="socialSkills">{({ field }) => <Textarea {...field} id="to" placeholder="Detaljan opis" borderRadius="0" />}</Field>
                  </ListItem>
                  <ListItem my="30px">
                    <Text fontWeight="bold" fontSize="small" mb="15px">
                      RADNE KOMPETENCIJE I PERSPEKTIVA:
                    </Text>
                    <Field name="workingCompetentsAndPerspective">{({ field }) => <Textarea {...field} id="to" placeholder="Detaljan opis" borderRadius="0" />}</Field>
                  </ListItem>
                </OrderedList>
              </Box>
            </Form>
          </Formik>
          <Divider mt="30px" mb="15px" />
          <Box mb="30px">
            <Text>Korišteni instrumentarij za procjenu:</Text>
            <UnorderedList>
              <ListItem>
                <Text as="i">Shema vrednovanja; Potreba za pomoći u individualnom obliku života (H.M.B.- W/ Verzija 5/20001)- Lebenshilfe Plauen</Text>
              </ListItem>
              <ListItem>
                <Text as="i">Shema za praćenje radnih sposobnosti (?) (Izradio: LSD, Fr. Redner)</Text>
              </ListItem>
            </UnorderedList>
          </Box>
          <Flex padding="20px" alignItems="center" justifyContent="center">
            <Button form="add-entry-diagnostic" colorScheme="green" size="md" mr={3} borderRadius="0" type="submit" disabled={submittingData}>
              Spasi {submittingData && <Spinner size="sm" ml="10px" />}
            </Button>
            <Button as={Link} variant="ghost" size="md" to={`${routes.CLIENTS}/${selectedClientId}`}>
              Nazad
            </Button>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default AddEntryDiagnostic;
