import { usePagination } from '../hooks/usePagination';

import './pagination.scss';

const Pagination = (props) => {
  const { className, onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, previousText, nextText } = props;

  const DOTS = '...';

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="pagination-wrapper">
      <ul className={`pagination ${className}`}>
        <li className={`pagination__item pagination__item-previous-wrapper ${currentPage === 1 ? 'pagination__item-disabled' : ''}`} onClick={onPrevious}>
          <div className="pagination__item-previous">{previousText}</div>
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <li key={index} className="pagination__item pagination__item-dots">
                {DOTS}
              </li>
            );
          }
          return (
            <li key={index} className={`pagination__item ${pageNumber === currentPage ? 'pagination__item-selected' : ''}`} onClick={() => onPageChange(pageNumber)}>
              {pageNumber}
            </li>
          );
        })}
        <li className={`pagination__item pagination__item-next-wrapper ${currentPage === lastPage && 'pagination__item-disabled'}`} onClick={onNext}>
          <div className="pagination__item-next">{nextText}</div>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
