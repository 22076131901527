import Header from '../../features/header/header';

import state from '../state';

import banner from '../../core/assets/img/login-banner.jpg';

import './layout.scss';

function Layout(props) {
  const { children } = props;
  const isLoggedIn = state.useAuth((state) => state.isLoggedIn);

  return (
    <div className="layout__grid">
      {isLoggedIn && (
        <div className="layout__header">
          <Header />
        </div>
      )}
      <div className="layout__main">
        <div className="layout__main__container">{children}</div>
      </div>
      <div className="layout__footer">
        <img src={banner} alt="Interreg-IPA CBC" />
      </div>
    </div>
  );
}

export default Layout;
