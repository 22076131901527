import { Heading, Text } from '@chakra-ui/react';
import ServicesWidget from '../shared/widgets/services/services-widget';

const Services = () => {
  return (
    <div className="services">
      <Heading textAlign="center">Usluge</Heading>
      <Text my="10px">Šifrarnik usluga poslovnih jedinica sa pretragom po nazivu ili šifri usluge</Text>
      <ServicesWidget />
    </div>
  );
};

export default Services;
