import axios from '../config/axios-config';

const getEntryDiagnostics = async (data: any) => {
  const response = await axios({
    method: 'GET',
    url: `/entry-diagnostics/`,
    data,
  });
  return response.data || [];
};
const getEntryDiagnostic = async (id: any) => {
  const response = await axios({
    method: 'GET',
    url: `/entry-diagnostics/${id}`,
  });
  return response.data || [];
};

const getEntryDiagnosticForUser = async (id: any) => {
  const response = await axios({
    method: 'GET',
    url: `/entry-diagnostics/user/${id}`,
  });
  return response.data || [];
};

const addEntryDiagnostic = async (data: any) => {
  const response = await axios({
    method: 'POST',
    url: `/entry-diagnostics/`,
    data,
  });
  return response.data || [];
};

const updateEntryDiagnostic = async (data: any) => {
  const response = await axios({
    method: 'PUT',
    url: `/entry-diagnostics/${data?.id}`,
    data: data?.values,
  });
  return response.data || [];
};

const positions = {
  getEntryDiagnostics,
  getEntryDiagnostic,
  getEntryDiagnosticForUser,
  addEntryDiagnostic,
  updateEntryDiagnostic,
};

export default positions;
