import { Button, Flex, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';

const RangePickerField = (props) => {
  const { onDatesChange = () => {} } = props;

  const initialValues = {
    dateFrom: '',
    dateTo: '',
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onDatesChange(values);
      }}
    >
      <Form id="add-center">
        <Flex gap="10" w="100%" my="40px">
          <Flex w="100%" alignItems="center">
            <FormLabel>Od</FormLabel>
            <Field name="dateFrom">
              {({ field }) => (
                <FormControl>
                  <Input {...field} type="date" id="name" placeholder="Datum" borderRadius="0" required />
                </FormControl>
              )}
            </Field>
          </Flex>
          <Flex w="100%" alignItems="center">
            <FormLabel>Do</FormLabel>
            <Field name="dateTo">
              {({ field }) => (
                <FormControl>
                  <Input {...field} type="date" id="name" placeholder="Datum" borderRadius="0" required />
                </FormControl>
              )}
            </Field>
          </Flex>
          <Flex>
            <Button type="submit" m="0" borderRadius="0">
              Filtriraj
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Formik>
  );
};

export default RangePickerField;
