import 'dotenv/config';

// API config
const MAGIC_SALT = '$$$/P/A/E/A/N/S/A/L/T/$$$';
const devApiUrl = 'http://localhost:8000';
const prodApiUrl = 'https://jucentarzdk.com:8000';

const IS_PROD_ENV = process.env.REACT_APP_ENV?.trim() === 'production';
const API_URL = IS_PROD_ENV ? prodApiUrl : devApiUrl;

const DEFAULT_LANGUAGE = 'bs';

const CACHED_ITEM = {
  DARK_MODE: 'darkMode',
};

const MAX_MOBILE_WIDTH = 768;

const REQUEST_RETRY_COUNT = 3;

const USER_ROLE = {
  ADMIN: 'Admin',
  THERAPIST: 'Uposlenik',
};

const USER_TOKENS = {
  TOKEN: { name: 'token', cacheKey: 'token' },
  REFRESH_TOKEN: { name: 'refreshToken', cacheKey: 'refreshToken' },
  REFRESH_TOKEN_EXPIRE_TIME: { name: 'token_expiration', cacheKey: 'refreshTokenExpireTime' },
};

const CACHED_ITEMS = {
  RETRY_COUNT: 'retryCount',
  USER_INFO: 'user',
  DARK_MODE: 'darkMode',
};

const QUERY_NAME = {
  HANDLE_VIEW_WORK_SCHEDULE: 'handleViewWorkSchedule',
  HANDLE_FETCH_WORK_SCHEDULES: 'handleFetchworkSchedules',
  HANDLE_FETCH_EMPLOYEES: 'handleFetchEmployees',
  HANDLE_FETCH_EMPLOYEE: 'handleFetchEmployee',
  HANDLE_FETCH_WORKING_POSITIONS: 'handleFetchWorkingPositions',
  HANDLE_FETCH_WORKING_POSITION: 'handleFetchWorkingPosition',
  HANDLE_FETCH_ROLES: 'handleFetchRoles',
  HANDLE_FETCH_CENTERS: 'handleFetchCenters',
  HANDLE_FETCH_CENTER: 'handleFetchCenter',
  HANDLE_FETCH_LOCATIONS: 'handleFetchLocations',
  HANDLE_FETCH_SERVICES: 'handleFetchServices',
  HANDLE_FETCH_SERVICE: 'handleFetchServices',
  HANDLE_FETCH_CLIENTS: 'handleFetchClients',
  HANDLE_FETCH_CLIENT: 'handleFetchClient',
  HANDLE_FETCH_ENTRY_DIAGNOSTICS: 'handleFetchEntryDiagnostics',
  HANDLE_FETCH_ENTRY_DIAGNOSTIC: 'handleFetchEntryDiagnostic',
  HANDLE_FETCH_DIAGNOSIS: 'handleFetchDiagnosis',
  HANDLE_FETCH_DIAGNOSE: 'handleFetchDiagnosie',
  HANDLE_FETCH_CHECKUP_TYPES: 'handleFetchCheckupTypes',
  HANDLE_FETCH_CHECKUP_TYPES_FOR_CHECKUPS: 'handleFetchCheckupTypesForCheckups',
  HANDLE_FETCH_CHECKUP_TYPES_FOR_VIEW_CHECKUP: 'handleFetchCheckupTypesForViewCheckup',
  HANDLE_FETCH_CHECKUP_TYPE: 'handleFetchCheckupType',
  HANDLE_FETCH_CHECKUPS: 'handleFetchCheckups',
  HANDLE_FETCH_CHECKUP: 'handleFetchCheckup',
  HANDLE_FETCH_CHECKUP_FOR_VIEW: 'handleFetchCheckupForView',
  HANDLE_FETCH_CLIENT_DOCUMENT_TYPE: 'handleFetchClientDocumentType',
  HANDLE_FETCH_CLIENT_DOCUMENT_TYPES: 'handleFetchClientDocumentTypes',
  HANDLE_FETCH_GENERAL_DOCUMENT_TYPE: 'handleFetchGeneralDocumentType',
  HANDLE_FETCH_GENERAL_DOCUMENT_TYPES: 'handleFetchGeneralDocumentTypes',
  HANDLE_FETCH_GENERAL_DOCUMENTS: 'handleFetchGeneralDocuments',
  HANDLE_FETCH_CLIENT_DOCUMENTS: 'handleFetchClientDocuments',
  HANDLE_FETCH_CLIENT_DOCUMENT: 'handleFetchClientDocument',
  HANDLE_FETCH_RULES_DOCUMENTS: 'handleFetchRulesDocuments',
  HANDLE_FETCH_STATISTICS: 'handleFetchStatistics',
};

const MOMENT_FORMAT = {
  DATE_DOT_SEPARATED: 'DD. MMM. YYYY',
  DATE_TIME_DOT_SEPARATED: 'DD. MMM. YYYY u HH:mm',
  SYSTEM_DATE: 'YYYY-MM-DD',
  DATE_MONTH_YEAR: 'DD_MM_YYYY',
};

const ALERT_TYPE = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
};
const PRIMARY_COLOR = '#EB268F';
const SECONDARY_COLOR = '#68717C';

const WEEK_DAYS = ['Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota', 'Nedjelja'];

// Add all usersnames small caps
const HIDDEN_USERS = ['admin', 'administrator', 'adnandev', 'administracija'];

const PAGINATION_DEFAULT_PAGE_SIZE = 10;

// Export all constants that we need
const constants = {
  IS_PROD_ENV,
  API_URL,
  DEFAULT_LANGUAGE,
  CACHED_ITEM,
  MAX_MOBILE_WIDTH,
  REQUEST_RETRY_COUNT,
  USER_ROLE,
  MAGIC_SALT,
  USER_TOKENS,
  CACHED_ITEMS,
  QUERY_NAME,
  MOMENT_FORMAT,
  ALERT_TYPE,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  WEEK_DAYS,
  HIDDEN_USERS,
  PAGINATION_DEFAULT_PAGE_SIZE,
};

export default constants;
