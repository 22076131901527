import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import constants from './constants';
import languages from './languages';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: languages,
    fallbackLng: constants.DEFAULT_LANGUAGE,
    keySeparator: false,
    debug: constants.IS_PROD_ENV ? false : true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
