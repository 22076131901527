import axios from 'axios';
import constants from './constants';
import cacheHelper from '../core/helpers/cacheHelper';
import api from '../api';
import state from '../core/state';

// export default axios.create({
//   baseURL: constants.API_URL,
//   headers: {
//     "Content-type": "application/json"
//   }
// });
const instance = axios.create({
  baseURL: constants.API_URL,
});

let refreshInProgess = false;

instance.defaults.headers.common = {};

instance.interceptors.request.use(
  (config: any) => {
    config.headers.authorization = `JWT ${cacheHelper.getToken()}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (error?.response?.status === 401) {
      const currentTime = new Date(new Date().toISOString()).getTime();
      const expireTime = parseInt(cacheHelper.getRefreshTokenExpTime(), 10);
      const retryCount = JSON.parse(cacheHelper.getRetryCount()) || {};

      if (retryCount[error.config.url] >= constants.REQUEST_RETRY_COUNT) {
        cacheHelper.clearUserTokens();
        cacheHelper.clearRetryCount();
        window.location.href = '/';
        return;
      }

      if (!refreshInProgess && cacheHelper.getRefreshToken() && currentTime > expireTime) {
        refreshInProgess = true;
        const result = await api.auth.generateNewTokens(cacheHelper.getRefreshToken());
        if (result?.data) {
          const refreshTokenExp = result.data.exp_time;
          const accessToken = result.data.access_token;
          const refreshToken = result.data.refresh_token;
          state.useRefreshToken.setState((state) => state.setRefreshTokenExpireTime(refreshTokenExp));
          state.useAuth.setState((state) => state.setIsLoggedIn(true, accessToken));
          state.useRefreshToken.setState((state) => state.setRefreshToken(refreshToken));
          setTimeout(() => {
            cacheHelper.clearRetryCount();
          }, 500);
        }

        refreshInProgess = false;
      }

      retryCount[error.config.url] = retryCount[error.config.url] ? parseInt(retryCount[error.config.url], 10) + 1 : 1;
      cacheHelper.setRetryCount(retryCount);

      return axios(error.config);
    }

    if (error?.response?.status === 451) {
      cacheHelper.clearUserTokens();
      cacheHelper.clearRetryCount();
      window.location.href = '/';
      return;
    }

    return Promise.reject(error);
  }
);

export default instance;
