import axios from '../config/axios-config';

const getLocations = async (data: any) => {
  const response = await axios({
    method: 'GET',
    url: `/locations`,
    data,
  });
  return response.data || [];
};
const getLocation = async (id: any) => {
  const response = await axios({
    method: 'GET',
    url: `/locations/${id}`,
  });
  return response.data || [];
};

const locations = {
  getLocations,
  getLocation,
};

export default locations;
