import { Box } from '@chakra-ui/react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const UsersPerCenter = (props) => {
  const { centers } = props;
  if (!centers) return null;
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: 'Broj korisnika po centru',
      },
    },
  };

  const labels = centers.map((center) => center.name);
  const active = centers.map((center) => center.active_persons);
  const onWaitList = centers.map((center) => center.persons_on_wait_list);

  const data = {
    labels,
    datasets: [
      {
        label: 'Aktivni',
        data: active,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
      {
        label: 'Na listi čekanja',
        data: onWaitList,
        backgroundColor: 'rgba(255,255,0, 0.5)',
      },
    ],
  };

  return (
    <Box {...props}>
      <Bar options={options} data={data} />
    </Box>
  );
};

export default UsersPerCenter;
