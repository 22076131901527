import axios from '../config/axios-config';

const getServices = async () => {
  const response = await axios({
    method: 'GET',
    url: `/services`,
  });
  return response.data || [];
};

const getService = async (id: number) => {
  const response = await axios({
    method: 'GET',
    url: `/services/${id}`,
  });
  return response.data || [];
};

const addService = async (data: number) => {
  const response = await axios({
    method: 'POST',
    url: `/services`,
    data,
  });
  return response.data || [];
};

const updateService = async (data: { values: object; id: number }) => {
  const response = await axios({
    method: 'PUT',
    url: `/services/${data.id}`,
    data: data.values,
  });
  return response.data || [];
};

const services = {
  getServices,
  getService,
  addService,
  updateService,
};

export default services;
