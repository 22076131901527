import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, Input, Spinner, Textarea, Select, Checkbox, Progress } from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { useState } from 'react';

import constants from '../../../../config/constants';
import api from '../../../../api';

import SpinnerCentered from '../../spinner-centered/spinner-centered';
import FileUploadField from '../../file-upload-field/file-upload-field';

const AddClientDocumentModal = (props) => {
  const { id, clientId, showModal, onClose = () => {} } = props;
  const queryClient = useQueryClient();

  const selectedClientDocument = useQuery([constants.QUERY_NAME.HANDLE_FETCH_CLIENT_DOCUMENT, id], ({ queryKey }) => api.documentTypes.getClientDocumentType(queryKey[1]), { enabled: !!id });
  const documentTypes = useQuery(constants.QUERY_NAME.HANDLE_FETCH_CLIENT_DOCUMENT_TYPES, api.documentTypes.getClientDocumentTypes);
  const therapistTypes = useQuery(constants.QUERY_NAME.HANDLE_FETCH_WORKING_POSITIONS, api.workingPositions.getPositions);
  const [isProtected, setIsProtected] = useState(selectedClientDocument?.data?.protected || false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const addClientDocumentMutation = useMutation(api.documents.uploadClientDocument, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CLIENT);
      onClose();
      toast.success('Dokument uspješno dodan.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom dodavanja dokumenta.');
    },
  });

  const updateClientDocumentTypeMutation = useMutation(api.documentTypes.updateClientDocumentType, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CLIENT_DOCUMENTS);
      onClose();
      toast.success('Dokument uspješno ažuriran.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom ažuriranja dokumenta.');
    },
  });

  const setDocumentProtected = (e) => {
    setIsProtected(e.target.checked);
  };

  const progressLoad = (e) => {
    const { total, loaded } = e;
    const progress = (loaded / total) * 100;
    setUploadProgress(progress);
  };

  const handleOnSubmit = (values) => {
    const data = {
      ...values,
      isProtected,
      clientId,
      progressCallBack: progressLoad,
    };
    if (id) {
      // updateClientDocumentTypeMutation.mutate({ id, values });
    } else {
      addClientDocumentMutation.mutate(data);
    }
  };

  const renderModalHeaderContent = () => (selectedClientDocument.data?.name ? `Uredi ${selectedClientDocument.data?.name}` : 'Dodaj novi dokument');

  const renderProgressBar = () => {
    if (!uploadProgress) return null;
    return <Progress my="20px" value={uploadProgress} size="xs" colorScheme="pink" />;
  };

  const isLoading = addClientDocumentMutation.isLoading || updateClientDocumentTypeMutation.isLoading;

  return (
    <Modal isOpen={showModal} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalHeader>{renderModalHeaderContent()}</ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <SpinnerCentered show={selectedClientDocument.isLoading} />
        {!selectedClientDocument.isLoading && (
          <ModalBody>
            <Formik
              initialValues={{
                name: selectedClientDocument.data?.name || '',
                description: selectedClientDocument.data?.description || '',
                documentType: selectedClientDocument.data?.document_type || '',
                allowedPosition: '',
                file: '',
              }}
              onSubmit={(values) => handleOnSubmit(values)}
            >
              {({ values, setFieldValue }) => (
                <Form id="add-checkup-type" enctype="multipart/form-data">
                  <Field name="name">
                    {({ field }) => (
                      <FormControl>
                        <Input {...field} id="name" placeholder="Naziv dokumenta" borderRadius="0" required />
                      </FormControl>
                    )}
                  </Field>

                  <Field name="documentType">
                    {({ field }) => (
                      <FormControl mt="10px">
                        <Select {...field} id="documentType" placeholder="Tip dokumenta" borderRadius="0" required>
                          {documentTypes?.data?.map((documentType) => (
                            <option key={documentType?.name + documentType?.id} value={documentType.id}>
                              {documentType.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="protected">
                    {({ field }) => (
                      <FormControl mt="10px">
                        <Checkbox
                          {...field}
                          onChange={(e) => setDocumentProtected(e)}
                          defaultChecked={selectedClientDocument?.data?.protected || false}
                          variant="flushed"
                          type="text"
                          id="to"
                          placeholder=""
                          borderRadius="0"
                        >
                          Zaštiti dokument
                        </Checkbox>
                      </FormControl>
                    )}
                  </Field>

                  {isProtected && (
                    <Field name="allowedPosition">
                      {({ field }) => (
                        <FormControl mt="10px">
                          <Select {...field} id="allowedPosition" placeholder="Tip terapeuta koji moze vidjeti dokument" borderRadius="0" required>
                            {therapistTypes?.data?.map((therapistType) => (
                              <option key={therapistType?.name + therapistType?.id} value={therapistType.id}>
                                {therapistType.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  )}

                  <Field name="description">
                    {({ field }) => (
                      <FormControl my="10px">
                        <Textarea {...field} id="description" name="description" placeholder="Opis dokumenta" borderRadius="0" />
                      </FormControl>
                    )}
                  </Field>

                  {/* <Field name="file">
                    {({ field }) => (
                      <FormControl mt="10px">
                        <input
                          {...field}
                          id="file"
                          name="file"
                          type="file"
                          onChange={(event) => {
                            setFieldValue('file', event.currentTarget.files[0]);
                          }}
                          className="form-control"
                        />
                      </FormControl>
                    )}
                  </Field> */}

                  <FileUploadField
                    inputProps={{
                      id: 'file_upload',
                      name: 'file_upload',
                      onChange: (e) => setFieldValue('file', e.target.files[0]),
                      required: true,
                    }}
                    label="Odaberi fajl"
                    onRemove={() => {
                      setFieldValue('file', null);
                    }}
                  />
                </Form>
              )}
            </Formik>
            {renderProgressBar()}
          </ModalBody>
        )}

        <ModalFooter>
          <Button disabled={isLoading} form="add-checkup-type" colorScheme="green" size="sm" mr={3} borderRadius="0" type="submit">
            Spasi {isLoading && <Spinner size="sm" ml="10px" />}
          </Button>
          <Button variant="ghost" size="sm" onClick={onClose}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddClientDocumentModal;
