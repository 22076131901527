import { Box, Flex, Tooltip, FormControl, FormLabel, IconButton, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PasswordField = (props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const togglePasswordVisibility = () => setShow(!show);
  const { onPasswordChange = () => {}, showLabel, ...rest } = props;

  return (
    <FormControl id="password" isRequired>
      {showLabel && (
        <Flex justify="space-between">
          <FormLabel fontWeight="normal">{t('LOGIN_FORM.PASSWORD')}</FormLabel>
          <Tooltip label={t('LOGIN_FORM.FORGOT_PASSWORD_DESCRIPTION')}>
            <Box as="a" fontWeight="light" fontSize="sm">
              {t('LOGIN_FORM.FORGOT_PASSWORD')}
            </Box>
          </Tooltip>
        </Flex>
      )}
      <InputGroup>
        <Input onChange={(e) => onPasswordChange} name="password" type={show ? 'text' : 'password'} autoComplete="current-password" {...rest} borderRadius="0" />
        <InputRightElement>
          <IconButton
            bg="transparent !important"
            variant="ghost"
            aria-label={show ? 'Mask password' : 'Reveal password'}
            icon={show ? <span className="material-icons">visibility_off</span> : <span className="material-icons">visibility</span>}
            onClick={togglePasswordVisibility}
          />
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

export default PasswordField;
