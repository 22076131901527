import axios from '../config/axios-config';

const getPositions = async () => {
  const response = await axios({
    method: 'GET',
    url: `/working-positions`,
  });
  return response.data || [];
};

const getPosition = async (id: number) => {
  const response = await axios({
    method: 'GET',
    url: `/working-positions/${id}`,
  });
  return response.data || [];
};

const addPosition = async (data: number) => {
  const response = await axios({
    method: 'POST',
    url: `/working-positions/`,
    data,
  });
  return response.data || [];
};

const updatePosition = async (data: { values: object; id: number }) => {
  const response = await axios({
    method: 'PUT',
    url: `/working-positions/${data.id}`,
    data: data.values,
  });
  return response.data || [];
};

const workingPositions = {
  getPositions,
  getPosition,
  addPosition,
  updatePosition,
};

export default workingPositions;
