import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, Input, Spinner, Switch, Select, Text, Divider } from '@chakra-ui/react';

import { Formik, Field, Form } from 'formik';
import { useQueryClient, useMutation, useQuery } from 'react-query';

import api from '../../../../api';

import state from '../../../../core/state';

import { helpers } from '../../../../core/helpers';
import PasswordField from '../../password-field/password-field';
import constants from '../../../../config/constants';
import { toast } from 'react-toastify';

const AddEmployeeModal = () => {
  const queryClient = useQueryClient();
  const showModal = state.modals((state) => state.showAddEmployeeModal);
  const setShowModal = state.modals((state) => state.setShowAddEmployeeModal);

  const toggleHideModal = () => {
    setShowModal(false);
  };

  const addEmployeeMutation = useMutation(api.employees.addEmployee, {
    onSuccess: () => {
      // Invalidate and refetch
      toast.success('Uposlenik uspješno kreiran.');
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES);
      toggleHideModal();
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom kreiranja uposlenika.');
    },
  });

  const rolesQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_ROLES, api.roles.getRoles);
  const centersQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_WORK_SCHEDULES, api.centers.getCenters);
  const workingPositionsQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_WORKING_POSITIONS, api.workingPositions.getPositions);

  const submitEmployee = (values) => {
    addEmployeeMutation.mutate({
      username: values.username,
      email: values.email,
      password: helpers.hashPassword(values.password),
      firstName: values.firstName,
      lastName: values.lastName,
      centerId: parseInt(values.center),
      phone: values.phone,
      active: values.active,
      roleId: parseInt(values.userRole, 10),
      positionId: parseInt(values.therapistType, 10),
    });
  };

  return (
    <Modal isOpen={showModal} onClose={toggleHideModal}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalHeader>Dodaj novog uposlenika</ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <ModalBody>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              phone: '',
              email: '',
              center: '',
              password: '',
              active: true,
              therapistType: '',
              userRole: '',
              username: '',
            }}
            onSubmit={(values) => {
              submitEmployee(values);
            }}
          >
            <Form id="add-employee">
              <Field name="firstName">
                {({ field }) => (
                  <FormControl>
                    <Input {...field} id="firstName" placeholder="Ime" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>

              <Field name="lastName">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="lastName" name="lastName" placeholder="Prezime" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>

              <Field name="phone">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="phone" name="phone" placeholder="Telefon" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>

              <Field name="email">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="email" name="email" placeholder="Email" type="email" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>

              <Field name="center">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Select {...field} placeholder="Poslovna jedinica" borderRadius="0" title="Poslovna jedinica" aria-label="Poslovna jedinica" required>
                      {centersQuery.data?.map((center) => (
                        <option value={center.id} key={center.name}>
                          {center.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Field name="userRole">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Select {...field} placeholder="Tip korisnika" borderRadius="0" title="Tip korisnika" aria-label="Tip korisnika" required>
                      {rolesQuery.data?.map((role) => (
                        <option value={role.id} key={role.name}>
                          {role.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>

              <Field name="therapistType">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Select {...field} placeholder="Tip terapeuta" borderRadius="0" title="Tip terapeuta" aria-label="Tip terapeuta" required>
                      {workingPositionsQuery.data?.map((position) => (
                        <option value={position.id} key={position.name}>
                          {position.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>

              <Divider my={5} />
              <Text>Podaci za prijavu na sistem: </Text>

              <Field name="username">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="username" name="username" placeholder="Korisnicko ime" type="text" borderRadius="0" required oninput="this.value=this.value.replace(/[^A-Za-z\s]/g,'');" />
                  </FormControl>
                )}
              </Field>

              <Field name="password">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Field as={PasswordField} {...field} required placeholder="Šifra" />
                  </FormControl>
                )}
              </Field>

              <Divider my="10px" />

              <Field name="active">
                {({ field }) => (
                  <FormControl display="flex" alignItems="center" justifyContent="flex-start" mt="10px">
                    <label htmlFor="active" mb="0">
                      Aktivan:
                    </label>
                    <Switch {...field} id="active" colorScheme="pink" defaultChecked={true} ml="10px" />
                  </FormControl>
                )}
              </Field>
            </Form>
          </Formik>
        </ModalBody>

        <ModalFooter>
          <Button disabled={addEmployeeMutation.isLoading} form="add-employee" colorScheme="green" size="sm" mr={3} borderRadius="0" type="submit">
            Spasi {addEmployeeMutation.isLoading && <Spinner size="sm" ml="10px" />}
          </Button>
          <Button variant="ghost" size="sm" onClick={toggleHideModal}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddEmployeeModal;
