import axios from '../config/axios-config';

const getCheckupTypes = async () => {
  const response = await axios({
    method: 'GET',
    url: `/checkup-types`,
  });
  return response.data || [];
};

const getCheckupType = async (id: number) => {
  const response = await axios({
    method: 'GET',
    url: `/checkup-types/${id}`,
  });
  return response.data || [];
};

const addCheckupType = async (data: number) => {
  const response = await axios({
    method: 'POST',
    url: `/checkup-types`,
    data,
  });
  return response.data || [];
};

const updateCheckupType = async (data: { values: object; id: number }) => {
  const response = await axios({
    method: 'PUT',
    url: `/checkup-types/${data.id}`,
    data: data.values,
  });
  return response.data || [];
};

const diagnosis = {
  getCheckupTypes,
  getCheckupType,
  addCheckupType,
  updateCheckupType,
};

export default diagnosis;
