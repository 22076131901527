import axios from '../config/axios-config';

const getDiagnosis = async () => {
  const response = await axios({
    method: 'GET',
    url: `/diagnosis`,
  });
  return response.data || [];
};

const getDiagnose = async (id: number) => {
  const response = await axios({
    method: 'GET',
    url: `/diagnosis/${id}`,
  });
  return response.data || [];
};

const addDiagnose = async (data: number) => {
  const response = await axios({
    method: 'POST',
    url: `/diagnosis`,
    data,
  });
  return response.data || [];
};

const updateDiagnose = async (data: { values: object; id: number }) => {
  const response = await axios({
    method: 'PUT',
    url: `/diagnosis/${data.id}`,
    data: data.values,
  });
  return response.data || [];
};

const diagnosis = {
  getDiagnosis,
  getDiagnose,
  addDiagnose,
  updateDiagnose,
};

export default diagnosis;
