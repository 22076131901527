import PAEANRoutes from './routes';

import constants from '../../config/constants';

import PageNotFound from '../../features/page-not-found/page-not-found';
import Login from '../../features/login/login';
import Administration from '../../features/administration/administration';
import Employees from '../../features/employees/employees';
import Documents from '../../features/documents/documents';
import Reports from '../../features/reports/reports';
import Home from '../../features/home/home';
import Clients from '../../features/clients/clients';
import WorkSchedules from '../../features/work-schedules/work-schedules';
import Services from '../../features/services/services';
import AddClient from '../../features/clients/add-edit-client/add-edit-client';
import ViewClient from '../../features/clients/view-client/view-client';
import AddEntryDiagnostic from '../../features/entry-diagnostics/add-entry-diagnostic/add-entry-diagnostic';
import ViewEntryDiagnostic from '../../features/entry-diagnostics/view-entry-diagnostic/view-entry-diagnostic';
import Rules from '../../features/rules/rules';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

const routes = [
  {
    path: PAEANRoutes.NOT_FOUND,
    element: (
      <PublicRoute pageTitle={'PAGE_TITLE.PAGE_NOT_FOUND'}>
        <PageNotFound />
      </PublicRoute>
    ),
    pageTitle: 'PAGE_TITLE.PAGE_NOT_FOUND',
  },
  {
    path: PAEANRoutes.LOGIN,
    element: (
      <PublicRoute loginGuard pageTitle={'PAGE_TITLE.LOGIN'}>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: PAEANRoutes.ADMINISTRATION,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN]} pageTitle={'PAGE_TITLE.ADMINISTRATION'}>
        <Administration />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.EMPLOYEES,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.EMPLOYEES'}>
        <Employees />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.DOCUMENTS,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.DOCUMENTS'}>
        <Documents />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.RULES,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.RULES'}>
        <Rules />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.REPORTS,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.REPORTS'}>
        <Reports />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.HOME,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.HOME'}>
        <Home />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.CLIENTS,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.CLIENTS'}>
        <Clients />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.DATES,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.DATES'}>
        <WorkSchedules />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.SERVICES,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.SERVICES'}>
        <Services />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.ADD_CLIENT,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.ADD_CLIENT'}>
        <AddClient />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.EDIT_CLIENT,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.EDIT_CLIENT'}>
        <AddClient />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.VIEW_CLIENT,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.VIEW_CLIENT'}>
        <ViewClient />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.ADD_ENTRY_DIAGNOSTIC,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.ADD_ENTRY_DIAGNOSTIC'}>
        <AddEntryDiagnostic />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.EDIT_ENTRY_DIAGNOSTIC,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.EDIT_ENTRY_DIAGNOSTIC'}>
        <AddEntryDiagnostic />
      </PrivateRoute>
    ),
  },
  {
    path: PAEANRoutes.VIEW_ENTRY_DIAGNOSTIC,
    element: (
      <PrivateRoute expectedRoles={[constants.USER_ROLE.ADMIN, constants.USER_ROLE.THERAPIST]} pageTitle={'PAGE_TITLE.VIEW_ENTRY_DIAGNOSTIC'}>
        <ViewEntryDiagnostic />
      </PrivateRoute>
    ),
  },
];

export default routes;
