import { Table, Tbody, Tfoot, Tr, Td, Flex, Button, Th } from '@chakra-ui/react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import api from '../../../../api';
import constants from '../../../../config/constants';
import AddTherapistModal from '../../../shared/modals/add-therapist/add-therapist-modal';
import SpinnerCentered from '../../../shared/spinner-centered/spinner-centered';

import './styles/therapists.scss';

const Therapists = (props) => {
  const { showActions = false } = props;
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedTherapistTypeId, setSelectedTherapistTypeId] = useState(null);

  const therapistTypes = useQuery(constants.QUERY_NAME.HANDLE_FETCH_WORKING_POSITIONS, api.workingPositions.getPositions);

  const handleToggleAddEditModal = (data) => {
    const { id, showModal } = data;
    setShowAddEditModal(showModal);
    setSelectedTherapistTypeId(id);
  };

  return (
    <div className="therapists-widget">
      <div className="pa-widget__title-area">
        <h3 className="pa-widget-title">Terapeuti</h3>
        <Flex>
          <Button variant="outline" size="sm" ml="10px" onClick={() => handleToggleAddEditModal({ showModal: true, id: null })}>
            <span className="material-icons">person_add_alt_1</span>
          </Button>
        </Flex>
      </div>
      <div className="pa-widget__content-area">
        <Table size="sm" variant="striped" colorScheme="gray">
          <Tbody>
            <Tr>
              <Th>Pozicija</Th>
              <Th>Opis</Th>
              {showActions && <Th isNumeric>Akcije</Th>}
            </Tr>
            {therapistTypes.data?.map((type, index) => {
              return (
                <Tr key={type.name + index}>
                  <Td>{type.name}</Td>
                  <Td>{type.description}</Td>
                  {showActions && (
                    <Td isNumeric>
                      <Button size="xs" colorScheme="yellow" variant="ghost" onClick={() => handleToggleAddEditModal({ showModal: true, id: type.id })}>
                        Uredi
                      </Button>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
          <Tfoot></Tfoot>
        </Table>
        <SpinnerCentered show={therapistTypes.isLoading} />
        <AddTherapistModal showModal={showAddEditModal} id={selectedTherapistTypeId} onClose={() => handleToggleAddEditModal({ showModal: false, id: null })} />
      </div>
    </div>
  );
};

export default Therapists;
