import PAEANRoutes from '../../core/routes/routes';

import MainNavbar from './main-navbar';

import './styles/header.scss';

const Header = () => {
  return (
    <div className="header">
      <div className="header__logo-nav-wrapper">
        <div className="header__logo">
          <a href={PAEANRoutes.HOME}>
            <h3 className="header__logo-name">PAEAN</h3>
          </a>
        </div>
        <MainNavbar />
      </div>
    </div>
  );
};

export default Header;
