import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
  Divider,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import moment from 'moment';

import constants from '../../../../config/constants';
import api from '../../../../api';

import SpinnerCentered from '../../spinner-centered/spinner-centered';

const ViewCheckupModal = (props) => {
  const { checkupId, showModal, onClose = () => {} } = props;

  const selectedCheckup = useQuery([constants.QUERY_NAME.HANDLE_FETCH_CHECKUP_FOR_VIEW, checkupId], ({ queryKey }) => api.checkups.getCheckup(queryKey[1]), { enabled: !!checkupId });

  const checkupTypes = useQuery(constants.QUERY_NAME.HANDLE_FETCH_CHECKUP_TYPES_FOR_VIEW_CHECKUP, api.checkupTypes.getCheckupTypes);
  const employeesQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES, api.employees.getEmployees);

  const isLoading = selectedCheckup.isLoading || checkupTypes.isLoading;

  const getCheckupTypeName = (id) => {
    const checkupType = checkupTypes?.data?.find((checkupType) => checkupType.id === id);
    return checkupType?.name || null;
  };

  const getEmployeeName = (id) => {
    const selectedEmployee = employeesQuery?.data?.find((employee) => employee.id === id);
    return `${selectedEmployee?.first_name} ${selectedEmployee?.last_name}` || null;
  };

  return (
    <Modal isOpen={showModal} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalHeader>Pregled terapije</ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <SpinnerCentered show={isLoading} />
        {!isLoading && (
          <ModalBody>
            <Text size="sm" my="25px">
              Vrsta terapije:
              <Text ml="5px" color={constants.PRIMARY_COLOR} fontWeight="bold" display="inline">
                {getCheckupTypeName(selectedCheckup?.data?.created_by)}
              </Text>
            </Text>

            <Divider my="25px" />
            <Text fontWeight="bold" my="10px" textAlign="center">
              Informacije o terapiji
            </Text>
            <div dangerouslySetInnerHTML={{ __html: selectedCheckup?.data?.description }}></div>
            <Divider my="25px" />
            <Flex flexDirection="column">
              <Text size="sm" mt="5px" display="flex" justifyContent="space-between">
                Vrijeme i datum terapije:
                <Text ml="5px" color={constants.PRIMARY_COLOR} fontWeight="bold" display="inline">
                  {selectedCheckup?.data?.created_at && moment(selectedCheckup.data.created_at).format(constants.MOMENT_FORMAT.DATE_TIME_DOT_SEPARATED)}
                </Text>
              </Text>
              <Text size="sm" mt="5px" display="flex" justifyContent="space-between">
                Posljednje izmjene:
                <Text ml="5px" color={constants.PRIMARY_COLOR} fontWeight="bold" display="inline">
                  {selectedCheckup?.data?.updated_at && moment(selectedCheckup.data.updated_at).format(constants.MOMENT_FORMAT.DATE_TIME_DOT_SEPARATED)}
                </Text>
              </Text>
              <Text size="sm" mt="5px">
                Terapeut:
                <Text ml="5px" color={constants.PRIMARY_COLOR} fontWeight="bold" display="inline">
                  {getEmployeeName(selectedCheckup?.data?.created_by)}
                </Text>
              </Text>
            </Flex>
          </ModalBody>
        )}

        <ModalFooter>
          <Button variant="ghost" size="sm" onClick={onClose}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ViewCheckupModal;
