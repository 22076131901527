// import { Table, Tag, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, Flex, Button, Switch, Input, Tag } from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import api from '../../../../api';
import centersConstans from '../centers/centers-constants';
import constants from '../../../../config/constants';

import state from '../../../../core/state';

import './employees-widget.scss';
import { toast } from 'react-toastify';
import EditEmployeeModal from '../../modals/edit-employee/edit-employee-modal';
import AddEmployeeModal from '../../modals/add-employee/add-employee-modal';
import SpinnerCentered from '../../spinner-centered/spinner-centered';
import ChangePasswordModal from '../../modals/change-password/change-password';
import { helpers } from '../../../../core/helpers';
import Pagination from '../../pagination/pagination';

const EmployeesWidget = (props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { showActions, showTitle, searchBarWidth = '40%' } = props;
  const [filteredResults, setFilteredResults] = useState([]);
  const [centersObj, setCentersObj] = useState([]);

  const [showEditEmployeeModal, setShowEditEmployeeModal] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const setShowModal = state.modals((state) => state.setShowAddEmployeeModal);
  const centersQuery = useQuery(centersConstans.QUERY_NAME.FETCH_CENTERS, api.centers.getCenters);
  const employeesQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES, api.employees.getEmployees);

  const changeStatusMutation = useMutation(api.employees.changeStatus, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.FETCH_EMPLOYEES);
      toast.success('Status uspješno promjenjen!');
    },
    onError: () => {
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_WORK_SCHEDULES);
      toast.error('Došlo je do greške prilikom promjene statusa!');
    },
  });

  useEffect(() => {
    if (centersQuery.data) {
      const centersO = {};
      centersQuery.data.forEach((center) => (centersO[center.id] = center.name));
      setCentersObj(centersO);
    }
  }, [centersQuery.data]);

  const toggleShowModal = () => {
    setShowModal(true);
  };

  const columns = [
    {
      title: 'Ime',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Telefon',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Pozicije',
      dataIndex: 'email',
      key: 'positions',
    },
    {
      title: 'Poslovna Jedinica',
      dataIndex: 'center',
      key: 'center',
    },
  ];

  useEffect(() => {
    const employeesListRaw = employeesQuery?.data || [];
    setFilteredResults(employeesListRaw.slice((currentPage - 1) * constants.PAGINATION_DEFAULT_PAGE_SIZE, currentPage * constants.PAGINATION_DEFAULT_PAGE_SIZE));
  }, [currentPage, employeesQuery.data]);

  const searchEmployees = (input) => {
    const value = input.target.value;
    if (employeesQuery.data?.length && value.length >= 3) {
      const searchRes = employeesQuery.data?.filter((employee) => employee.first_name.toLowerCase().includes(value.toLowerCase()));
      setFilteredResults(searchRes);
    } else {
      setFilteredResults(employeesQuery.data);
    }
  };

  const toggleUserActivity = (id, active) => {
    changeStatusMutation.mutate({ id, active });
  };

  const handleToggleEditModal = (data) => {
    const { id, showModal } = data;
    setSelectedEmployeeId(id);
    setShowEditEmployeeModal(showModal);
  };

  const handleToggleChangePasswordModal = (data) => {
    const { id, showModal } = data;
    setSelectedEmployeeId(id);
    setShowChangePasswordModal(showModal);
  };

  const renderWorkingPositions = (positions) => {
    return positions.map((position, index) => (
      <Tag colorScheme="pink" mr="5px" key={position + index}>
        {position.name}
      </Tag>
    ));
  };

  const renderPagination = () => {
    const employeesListData = employeesQuery?.data;
    if (!employeesListData || !filteredResults.length) return null;
    return (
      <Pagination
        className="transactions-list__pagination"
        currentPage={currentPage}
        totalCount={employeesQuery?.data?.length}
        pageSize={10}
        onPageChange={setCurrentPage}
        previousText={'Prethodna'}
        nextText={t('Naredna')}
      />
    );
  };

  return (
    <div className="employees-widget">
      <div className="pa-widget__title-area">
        <h3 className="pa-widget-title">{showTitle && t('EMPLOYEES_WIDGET.TITLE')}</h3>
        <Input placeholder="Pretraga" onChange={searchEmployees} borderRadius="0" type="search" size="sm" width={searchBarWidth} />
        <Flex>
          {showActions && (
            <Button variant="outline" size="sm" ml="10px" onClick={toggleShowModal}>
              <span className="material-icons">person_add</span>
            </Button>
          )}
        </Flex>
      </div>
      <div className="pa-widget__content-area">
        <Table size="sm" variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {columns.map((column, index) => (
                <Th key={index + column.title}>{column.title}</Th>
              ))}

              {showActions && <Th>Status</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {filteredResults && filteredResults.length ? (
              filteredResults.map((employee, index) => {
                return (
                  !helpers.isUserHidden({ username: employee.username, isAdminPanel: showActions }) && (
                    <Tr key={employee.username + index}>
                      <Td>{`${employee.first_name} ${employee.last_name}`}</Td>
                      <Td>{employee.phone}</Td>
                      <Td>{employee.email}</Td>
                      <Td>{renderWorkingPositions(employee?.working_positions)}</Td>
                      <Td>{centersObj?.[employee.center_id]}</Td>
                      {showActions && (
                        <Fragment>
                          <Td>
                            <Switch id="active" colorScheme="pink" defaultChecked={employee.active} onChange={() => toggleUserActivity(employee.id, !employee.active)} ml="10px" />
                          </Td>
                          <Td isNumeric>
                            <Button size="xs" colorScheme="yellow" variant="ghost" onClick={() => handleToggleEditModal({ showModal: true, id: employee.id })}>
                              Uredi
                            </Button>
                            <Button size="xs" colorScheme="orange" variant="ghost" onClick={() => handleToggleChangePasswordModal({ showModal: true, id: employee.id })}>
                              <span className="material-icons">password</span>
                            </Button>
                          </Td>
                        </Fragment>
                      )}
                    </Tr>
                  )
                );
              })
            ) : (
              <Tr>
                <Td textAlign="center" colSpan={showActions ? 6 : 5}>
                  Nema rezultata
                </Td>
              </Tr>
            )}
          </Tbody>
          <Tfoot></Tfoot>
        </Table>
        {renderPagination()}
        <SpinnerCentered show={employeesQuery.isLoading} />
      </div>
      <AddEmployeeModal />
      <EditEmployeeModal showModal={showEditEmployeeModal} id={selectedEmployeeId} onClose={() => handleToggleEditModal({ id: null, showModal: false })} />
      <ChangePasswordModal showModal={showChangePasswordModal} id={selectedEmployeeId} onClose={() => handleToggleChangePasswordModal({ showModal: false, id: null })} />
    </div>
  );
};

export default EmployeesWidget;
