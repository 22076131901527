import axios from '../config/axios-config';

const getSchedules = async (showOwn: any) => {
  const response = await axios({
    method: 'GET',
    url: `/work-schedules`,
    params: showOwn,
  });
  return response.data || [];
};

const getSchedule = async (id: number) => {
  const response = await axios({
    method: 'GET',
    url: `/work-schedules/${id}`,
  });
  return response.data || [];
};

const addSchedule = async (data: any) => {
  const response = await axios({
    method: 'POST',
    url: `/work-schedules`,
    data,
  });
  return response.data || [];
};

const editSchedule = async (data: any) => {
  const response = await axios({
    method: 'PUT',
    url: `/work-schedules/${data?.data?.id}`,
    data: data?.data?.values,
  });
  return response.data || [];
};

const changeStatus = async (data: any) => {
  const response = await axios({
    method: 'PUT',
    url: `/work-schedules/${data.id}/complete`,
    data,
  });
  return response.data || [];
};

const deleteSchedule = async (id: number) => {
  const response = await axios({
    method: 'DELETE',
    url: `/work-schedules/${id}`,
  });
  return response.data;
};

const workingSchedules = {
  getSchedules,
  addSchedule,
  changeStatus,
  getSchedule,
  editSchedule,
  deleteSchedule,
};

export default workingSchedules;
