import { Heading, Text } from '@chakra-ui/react';

import EmployeesWidget from '../shared/widgets/employees/employees-widget';

const Employees = (props) => {
  return (
    <div>
      <Heading textAlign="center">Uposlenici</Heading>
      <Text my="10px">Lista uposlenika svih ili filtriranih poslovnih jedinica.</Text>
      <EmployeesWidget searchBarWidth="70%" />
    </div>
  );
};

export default Employees;
