import constants from '../../config/constants';

// Check if item exists in localStorage
const ifItemExists = (key) => {
  try {
    return JSON.parse(window.sessionStorage.getItem(key) || '');
  } catch {
    return false;
  }
};

//   DARK MODE
const getDarkMode = () => {
  return ifItemExists(constants.CACHED_ITEM.DARK_MODE) || false;
};
const setDarkMode = (darkMode) => {
  window.sessionStorage.setItem(constants.CACHED_ITEM.DARK_MODE, darkMode);
};
const clearDarkMode = () => {
  window.sessionStorage.removeItem(constants.CACHED_ITEM.DARK_MODE);
};

//    USER TOKENS
function setUserTokens(tokens) {
  for (const token in constants.USER_TOKENS) {
    const userToken = constants.USER_TOKENS[token];
    window.sessionStorage.setItem(userToken.cacheKey, tokens[userToken.name] || '');
  }
}

function clearUserTokens() {
  for (const token in constants.USER_TOKENS) {
    window.sessionStorage.removeItem(constants.USER_TOKENS[token].cacheKey);
  }
}

//  Token
function getToken() {
  return window.sessionStorage.getItem(constants.USER_TOKENS.TOKEN.cacheKey);
}

function setToken(token) {
  window.sessionStorage.setItem(constants.USER_TOKENS.TOKEN.cacheKey, token);
}
function clearToken() {
  window.sessionStorage.removeItem(constants.USER_TOKENS.TOKEN.cacheKey);
}

// Refresh token
function getRefreshToken() {
  return window.sessionStorage.getItem(constants.USER_TOKENS.REFRESH_TOKEN.cacheKey) || '';
}
function setRefreshToken(refreshToken) {
  window.sessionStorage.setItem(constants.USER_TOKENS.REFRESH_TOKEN.cacheKey, refreshToken);
}
function clearRefreshToken() {
  window.sessionStorage.removeItem(constants.USER_TOKENS.REFRESH_TOKEN.cacheKey);
}

// Refresh token exp time
function getRefreshTokenExpTime() {
  return window.sessionStorage.getItem(constants.USER_TOKENS.REFRESH_TOKEN_EXPIRE_TIME.cacheKey) || '';
}
function setRefreshTokenExpTime(expTime) {
  window.sessionStorage.setItem(constants.USER_TOKENS.REFRESH_TOKEN_EXPIRE_TIME.cacheKey, expTime);
}
function clearRefreshTokenExpTime() {
  window.sessionStorage.removeItem(constants.USER_TOKENS.REFRESH_TOKEN_EXPIRE_TIME.cacheKey);
}

// User info
function getUserInfo() {
  return ifItemExists(constants.CACHED_ITEMS.USER_INFO) || {};
}
function setUserInfo(userInfo) {
  window.sessionStorage.setItem(constants.CACHED_ITEMS.USER_INFO, JSON.stringify(userInfo));
}
function clearUserInfo() {
  window.sessionStorage.removeItem(constants.CACHED_ITEMS.USER_INFO);
}

//   RETRY COUNT

function getRetryCount() {
  return window.sessionStorage.getItem(constants.CACHED_ITEMS.RETRY_COUNT) || '{}';
}
function setRetryCount(count_nr) {
  window.sessionStorage.setItem(constants.CACHED_ITEMS.RETRY_COUNT, JSON.stringify(count_nr));
}
function clearRetryCount() {
  window.sessionStorage.removeItem(constants.CACHED_ITEMS.RETRY_COUNT);
}

// CEAR STORAGE
function clearSessionStorage() {
  window.sessionStorage.clear();
}

const cacheHelper = {
  setUserTokens,
  clearUserTokens,
  getToken,
  setToken,
  clearToken,
  getRefreshToken,
  setRefreshToken,
  clearRefreshToken,
  getRefreshTokenExpTime,
  setRefreshTokenExpTime,
  clearRefreshTokenExpTime,
  setRetryCount,
  getRetryCount,
  clearRetryCount,
  getUserInfo,
  setUserInfo,
  clearUserInfo,
  getDarkMode,
  setDarkMode,
  clearDarkMode,
  clearSessionStorage,
};

export default cacheHelper;
