import axios from '../config/axios-config';

const getStats = async (data:any) => {
  const response = await axios({
    method: 'GET',
    url: `/statistics`,
    params: data
  });
  return response.data || [];
};

const statistics = {
  getStats,
};

export default statistics;
