import { Heading, Divider } from '@chakra-ui/react';
import AddDateModal from '../shared/modals/add-work-schedule/add-work-schedule-modal';
import DatesWidget from '../shared/widgets/work-schedules/work-schedules-widget';

import DocumentsWidget from '../shared/widgets/documents/documents-widget';
// import InfoBoxWidget from '../shared/widgets/info-box/info-box-widget';
import ServicesWidget from '../shared/widgets/services/services-widget';
import UserHomeBox from './components/user-home-box';

import './home.scss';

const Home = () => {
  return (
    <div className="home">
      <div className="home__content">
        <UserHomeBox />
        <Divider />
        <Heading size="md" my="15px">
          Moje obaveze
        </Heading>
        <DatesWidget showHeader showActions isSpecificUser showOwn />
      </div>
      <div className="home__sidebar">
        {/* <Heading size="md" mb="10px">
          Posljednje obavjesti
        </Heading>
        <InfoBoxWidget limitTo="5" /> */}
        <Heading size="md" mt="30px" mb="10px">
          Šifrarnik
        </Heading>
        <ServicesWidget limitTo="5" searchBarWidth="100%" />
        <Heading size="md" mt="30px" mb="10px">
          Posljednji dokumenti
        </Heading>
        <DocumentsWidget limitTo="5" />
      </div>
      <AddDateModal />
    </div>
  );
};

export default Home;
