import { Heading, Text } from '@chakra-ui/react';
import RulesWidget from '../shared/widgets/rules/rules-widget';

const Rules = () => {
  return (
    <div className="documents">
      <Heading textAlign="center">Pravilnici</Heading>
      <Text>Lista pravilnika</Text>
      <RulesWidget showHeader />
    </div>
  );
};

export default Rules;
