import axios from '../config/axios-config';

// Client document types
const getClientDocumentTypes = async () => {
  const response = await axios({
    method: 'GET',
    url: `/document-types/client`,
  });
  return response.data || [];
};

const getClientDocumentType = async (id: number) => {
  const response = await axios({
    method: 'GET',
    url: `/document-types/client/${id}`,
  });
  return response.data || [];
};

const addClientDocumentType = async (data: number) => {
  const response = await axios({
    method: 'POST',
    url: `/document-types/client`,
    data,
  });
  return response.data || [];
};

const updateClientDocumentType = async (data: { values: object; id: number }) => {
  const response = await axios({
    method: 'PUT',
    url: `/document-types/client/${data.id}`,
    data: data.values,
  });
  return response.data || [];
};

// General document types
const getGeneralDocumentTypes = async () => {
  const response = await axios({
    method: 'GET',
    url: `/document-types/general`,
  });
  return response.data || [];
};

const getGeneralDocumentType = async (id: number) => {
  const response = await axios({
    method: 'GET',
    url: `/document-types/general/${id}`,
  });
  return response.data || [];
};

const addGeneralDocumentType = async (data: number) => {
  const response = await axios({
    method: 'POST',
    url: `/document-types/general`,
    data,
  });
  return response.data || [];
};

const updateGeneralDocumentType = async (data: { values: object; id: number }) => {
  const response = await axios({
    method: 'PUT',
    url: `/document-types/general/${data.id}`,
    data: data.values,
  });
  return response.data || [];
};

const documentTypes = {
  // Client document types
  getClientDocumentTypes,
  getClientDocumentType,
  addClientDocumentType,
  updateClientDocumentType,
  // General document types
  getGeneralDocumentTypes,
  getGeneralDocumentType,
  addGeneralDocumentType,
  updateGeneralDocumentType,

};

export default documentTypes;
