import { useTranslation } from 'react-i18next';
import { Table, Thead, Tbody, Tr, Th, Td, Select, Flex, Button, Input, Tag, TagLabel } from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import { toast } from 'react-toastify';

import routes from '../../../../core/routes/routes';
import constants from '../../../../config/constants';
import api from '../../../../api';

import state from '../../../../core/state';
import { CLIENT_LIST_TYPE } from './clients-widget-constants';

import SpinnerCentered from '../../spinner-centered/spinner-centered';
import Pagination from '../../pagination/pagination';

import ConfirmDialog from '../../confirm-dialog/confirm-dialog';

import './clients-widget.scss';

const ClientsWidget = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { showActions, showTitle, searchBarWidth = '40%' } = props;

  const [filteredResults, setFilteredResults] = useState([]);
  const columns = ['Ime', 'Datum rođenja', 'Općina prebivališta', 'Dijagnoza/Teškoća', 'Djeca/Odrasli'];

  const [isOnWaitListFilter, setIsOnWaitListFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteClientConfirmModal, setShowDeleteClientConfirmModal] = useState(false);
  const [selectedClientToDeleteName, setSelectedClientToDeleteName] = useState('');
  const [selectedClientToDeleteId, setSelectedClientToDeleteId] = useState('');

  const clientsList = useQuery(constants.QUERY_NAME.HANDLE_FETCH_CLIENTS, api.clients.getClients);
  const centersList = useQuery(constants.QUERY_NAME.HANDLE_FETCH_CENTERS, api.centers.getCenters);

  const userInfo = state.currentUser((state) => state.userInfo);

  const isAdmin = userInfo.roles.includes('Admin');

  useEffect(() => {
    const clientsListRaw = clientsList?.data || [];
    if (isOnWaitListFilter) {
      setFilteredResults(clientsListRaw);
    } else {
      setFilteredResults(clientsListRaw.slice((currentPage - 1) * constants.PAGINATION_DEFAULT_PAGE_SIZE, currentPage * constants.PAGINATION_DEFAULT_PAGE_SIZE));
    }
  }, [currentPage, clientsList.data, isOnWaitListFilter]);

  const deleteClientMutation = useMutation(api.clients.deleteClient, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CLIENTS);
      toast.success('Korisnik uspješno uklonjen.');
      handleToggleDeleteClientDialog();
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom brisanja korisnika.');
    },
  });

  const searchEmployees = (input) => {
    const value = input.target.value;
    if (clientsList.data?.length && value.length >= 3) {
      const searchRes = clientsList?.data?.filter(
        (client) => client?.first_name?.toLowerCase().includes(value.toLowerCase()) || client?.last_name?.toLowerCase().includes(value.toLowerCase()) || client?.identification_number?.includes(value)
      );
      setFilteredResults(searchRes);
    } else {
      const clientsListRaw = clientsList?.data || [];
      setCurrentPage(1);
      setFilteredResults(clientsListRaw.slice((currentPage - 1) * constants.PAGINATION_DEFAULT_PAGE_SIZE, currentPage * constants.PAGINATION_DEFAULT_PAGE_SIZE));
    }
  };

  const filterByCenter = (input) => {
    setIsOnWaitListFilter(false);
    const value = input.target.value;
    if (value === 'Sve ustanove') {
      const clientsListRaw = clientsList?.data || [];
      setCurrentPage(1);
      setFilteredResults(clientsListRaw.slice((currentPage - 1) * constants.PAGINATION_DEFAULT_PAGE_SIZE, currentPage * constants.PAGINATION_DEFAULT_PAGE_SIZE));
    } else {
      const searchRes = clientsList?.data?.filter((client) => client.center_id === parseInt(value, 10));
      setFilteredResults(searchRes);
    }
  };

  const filterByUserType = (input) => {
    const value = parseInt(input.target.value, 10) || 0;
    switch (value) {
      case CLIENT_LIST_TYPE.WATCH_LIST:
        const watchListClients = clientsList?.data?.filter((client) => client.is_on_watch_list === true);
        setFilteredResults(watchListClients);
        break;
      case CLIENT_LIST_TYPE.WAIT_LIST:
        const waitListClients = clientsList?.data?.filter((client) => client.is_on_wait_list === true);
        setFilteredResults(waitListClients);
        setIsOnWaitListFilter(true);
        break;
      default:
        const clientsListRaw = clientsList?.data || [];
        setCurrentPage(1);
        setFilteredResults(clientsListRaw.slice((currentPage - 1) * constants.PAGINATION_DEFAULT_PAGE_SIZE, currentPage * constants.PAGINATION_DEFAULT_PAGE_SIZE));
        setIsOnWaitListFilter(false);
        break;
    }
  };

  const viewClient = (e, id) => {
    e.stopPropagation();
    navigate(`${routes.CLIENTS}/${id}`);
  };

  const editClient = (e, id) => {
    e.stopPropagation();
    navigate(`${routes.CLIENTS}/uredi/${id}`);
  };

  const handleDeleteClient = () => {
    deleteClientMutation.mutate({ clientId: parseInt(selectedClientToDeleteId, 10) });
  };

  const handleToggleDeleteClientDialog = (e, props) => {
    if (e) e.stopPropagation();
    if (props) {
      const { clientId, clientName } = props;
      setSelectedClientToDeleteName(clientName);
      setSelectedClientToDeleteId(clientId);
      setShowDeleteClientConfirmModal(true);
    } else {
      setSelectedClientToDeleteName(null);
      setSelectedClientToDeleteId(null);
      setShowDeleteClientConfirmModal(false);
    }
  };

  const isChild = (date) => {
    if (!date) return null;
    const years = moment().diff(date, 'years', true);
    return years >= 18 ? 'Odrasli' : 'Djeca';
  };

  const getRowBg = (isOnWatchList, isOnWaitlist, rowIndex) => {
    if (isOnWatchList) return 'green.100';
    if (isOnWaitlist) return 'yellow.100';
    if (rowIndex % 2 === 0) return 'gray.100';
  };

  const renderTableBody = () => {
    if (!filteredResults?.length) {
      return (
        <Tbody>
          <Tr>
            <Td textAlign="center" colSpan={showActions ? 6 : 5}>
              Nema rezultata
            </Td>
          </Tr>
        </Tbody>
      );
    }

    return (
      <Tbody>
        {filteredResults.map((client, index) => {
          return isOnWaitListFilter ? (
            client.is_on_wait_list && (
              <Tr
                key={index + client.id + client.first_name + client.last_name}
                onClick={(e) => viewClient(e, client.id)}
                bgColor={getRowBg(client.is_on_watch_list, client.is_on_wait_list, index)}
                className="clients-widget__table-row-clickable"
              >
                <Td>
                  {client.first_name} {client.last_name}
                </Td>
                <Td textAlign="center">{client.birth_date && moment(client.birth_date).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)} </Td>
                <Td textAlign="center">{client.muncipality_residence}</Td>
                <Td textAlign="center">
                  {client.diagnosis?.map((diagnose) => (
                    <Tag key={diagnose.id + diagnose.name} colorScheme="green" mr="5px">
                      <TagLabel> {diagnose.name}</TagLabel>
                    </Tag>
                  ))}
                </Td>
                <Td textAlign="center">{isChild(client.birth_date)}</Td>
                {showActions && (
                  <Fragment>
                    <Td isNumeric>
                      <Button
                        onClick={(e) => {
                          viewClient(e, client.id);
                        }}
                        ml="5px"
                        size="xs"
                        colorScheme="blue"
                        variant="ghost"
                      >
                        Pogledaj
                      </Button>
                      <Button
                        onClick={(e) => {
                          editClient(e, client.id);
                        }}
                        size="xs"
                        colorScheme="yellow"
                        variant="ghost"
                      >
                        Uredi
                      </Button>
                      {isAdmin && (
                        <Button
                          onClick={(e) => {
                            handleToggleDeleteClientDialog(e, { clientId: client.id, clientName: `${client.first_name} ${client.last_name}` });
                          }}
                          size="xs"
                          colorScheme="red"
                          variant="ghost"
                        >
                          Obriši
                        </Button>
                      )}
                    </Td>
                  </Fragment>
                )}
              </Tr>
            )
          ) : (
            <Tr
              key={index + client.id + client.first_name + client.last_name}
              onClick={(e) => viewClient(e, client.id)}
              bgColor={getRowBg(client.is_on_watch_list, client.is_on_wait_list, index)}
              className="clients-widget__table-row-clickable"
            >
              <Td>
                {client.first_name} {client.last_name}
              </Td>
              <Td textAlign="center">{client.birth_date && moment(client.birth_date).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)} </Td>
              <Td textAlign="center">{client.muncipality_residence}</Td>
              <Td textAlign="center">
                {client.diagnosis?.map((diagnose) => (
                  <Tag key={diagnose.id + diagnose.name} colorScheme="green" mr="5px">
                    <TagLabel> {diagnose.name}</TagLabel>
                  </Tag>
                ))}
              </Td>
              <Td textAlign="center">{isChild(client.birth_date)}</Td>
              {showActions && (
                <Fragment>
                  <Td isNumeric>
                    <Button
                      onClick={(e) => {
                        viewClient(e, client.id);
                      }}
                      ml="5px"
                      size="xs"
                      colorScheme="blue"
                      variant="ghost"
                    >
                      Pogledaj
                    </Button>
                    <Button
                      onClick={(e) => {
                        editClient(e, client.id);
                      }}
                      size="xs"
                      colorScheme="yellow"
                      variant="ghost"
                    >
                      Uredi
                    </Button>
                    {isAdmin && (
                      <Button
                        onClick={(e) => {
                          handleToggleDeleteClientDialog(e, { clientId: client.id, clientName: `${client.first_name} ${client.last_name}` });
                        }}
                        size="xs"
                        colorScheme="red"
                        variant="ghost"
                      >
                        Obriši
                      </Button>
                    )}
                  </Td>
                </Fragment>
              )}
            </Tr>
          );
        })}
      </Tbody>
    );
  };

  const renderPagination = () => {
    const clientsListData = clientsList?.data;
    if (!clientsListData || !filteredResults.length || isOnWaitListFilter) return null;
    return (
      <Pagination
        className="transactions-list__pagination"
        currentPage={currentPage}
        totalCount={clientsList?.data?.length}
        pageSize={10}
        onPageChange={setCurrentPage}
        previousText={'Prethodna'}
        nextText={'Naredna'}
      />
    );
  };

  return (
    <div className="clients-widget">
      <div className="pa-widget__title-area">
        <h3 className="pa-widget-title">{showTitle && t('EMPLOYEES_WIDGET.TITLE')}</h3>
        <Input placeholder="Pretraga po imenu ili maticnom broju" onChange={searchEmployees} borderRadius="0" type="text" size="sm" width={searchBarWidth} />
        <Flex>
          <Select borderRadius="0" size="sm" width="180px" mr="5" onChange={filterByUserType}>
            <option value={CLIENT_LIST_TYPE.ALL}>Svi korisnici</option>
            <option value={CLIENT_LIST_TYPE.WAIT_LIST}>Na listi čekanja</option>
            <option value={CLIENT_LIST_TYPE.WATCH_LIST}>Na listi praćenja</option>
          </Select>

          <Select borderRadius="0" size="sm" width="180px" onChange={filterByCenter}>
            <option value="Sve ustanove">Sve poslovne jedinice</option>
            {centersList?.data?.map((center, index) => (
              <option value={center.id} key={center.name + index}>
                {center.name}
              </option>
            ))}
          </Select>
          {showActions && (
            <Button as={Link} to="/korisnici/dodaj" variant="outline" size="sm" ml="10px">
              <span className="material-icons">person_add</span>
            </Button>
          )}
        </Flex>
      </div>
      <div className="pa-widget__content-area">
        <Table size="sm" colorScheme="gray">
          <Thead>
            <Tr>
              {columns.map((column, index) => (
                <Th key={column + index}>{column}</Th>
              ))}
            </Tr>
          </Thead>
          {renderTableBody()}
        </Table>
        {renderPagination()}
        <SpinnerCentered show={clientsList.isLoading} />
      </div>
      <ConfirmDialog
        isOpen={showDeleteClientConfirmModal}
        message={`Da li ste sigurni da želite izbrisati korisnika ${selectedClientToDeleteName}?`}
        onClose={(e) => handleToggleDeleteClientDialog(e)}
        onConfirm={(e) => handleDeleteClient(e)}
      />
      ;
    </div>
  );
};

export default ClientsWidget;
