import { Flex, Spinner } from '@chakra-ui/react';

const SpinnerCentered = (props) => {
  const { show, size = 'md' } = props;
  
  if (!show) return null;

  return (
    <Flex my="15px" align="center" justify="center">
      <Spinner thickness="3px" speed="0.5s" emptyColor="gray.200" color="#EB268F" size={size} label="Ucitavanje" />
    </Flex>
  );
};

export default SpinnerCentered;
