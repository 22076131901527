import { Box, Button, Checkbox, Divider, Flex, Heading, ListItem, OrderedList, Table, Tbody, Td, Text, Th, Thead, Tr, UnorderedList } from '@chakra-ui/react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import api from '../../../api';
import constants from '../../../config/constants';
import routes from '../../../core/routes/routes';
import SpinnerCentered from '../../shared/spinner-centered/spinner-centered';

const ViewEntryDiagnostic = () => {
  const { id } = useParams();
  const selectedDiagnostic = useQuery([constants.QUERY_NAME.HANDLE_FETCH_ENTRY_DIAGNOSTIC, id], ({ queryKey }) => api.entryDiagnostics.getEntryDiagnostic(queryKey[1]), { enabled: !!id });
  const selectedClient = useQuery([constants.QUERY_NAME.HANDLE_FETCH_CLIENT, selectedDiagnostic?.data?.person_id], ({ queryKey }) => api.clients.getClient(queryKey[1]), {
    enabled: !!selectedDiagnostic?.data?.person_id,
  });
  const employeesQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES, api.employees.getEmployees);
  const centersList = useQuery(constants.QUERY_NAME.HANDLE_FETCH_CENTERS, api.centers.getCenters);

  const getEmployeeName = (id) => {
    const selectedEmployee = employeesQuery?.data?.find((employee) => employee.id === id);
    return `${selectedEmployee?.first_name} ${selectedEmployee?.last_name}` || null;
  };

  const getCenterName = (id) => {
    const center = centersList?.data?.find((center) => center.id === id);
    return center?.name || null;
  };

  const isLoading = selectedDiagnostic.isLoading || selectedClient.isLoading || centersList.isLoading;

  const renderClientInfo = () => {
    if (!selectedClient?.data) return null;
    return (
      <div className="view-entry-diagnostic__wrapper">
        <Heading size="sm" my="15px;">
          1. Lični podaci osobe sa posebnim potrebama
        </Heading>
        <Table variant="striped" size="md" padding="10px" marginTop="15px">
          <Thead>
            <Tr>
              <Th>Ime</Th>
              <Th>Prezime</Th>
              <Th>Datum rođenja</Th>
              <Th>Adresa stanovanja</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{selectedClient?.data?.first_name}</Td>
              <Td>{selectedClient?.data?.last_name}</Td>
              <Td>{selectedClient?.data?.birth_date && moment(selectedClient?.data?.birth_date).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)}</Td>
              <Td>
                {selectedClient?.data?.address_street} {selectedClient?.data?.muncipality_residence} {selectedClient?.data?.postal_code} {selectedClient?.data?.city_of_residence}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </div>
    );
  };

  const renderDiagnosticData = () => {
    if (!selectedDiagnostic?.data) return null;
    return (
      <Box mt="50px">
        <Heading size="sm" my="15px;">
          2. Stav radionice prema sposobnosti ostvarivanja postignuća osobe sa posebnim potrebama
        </Heading>
        <Table variant="unstyled">
          <Tr border="1px">
            <Td border="1px">2.1</Td>
            <Td>
              Osoba sa posebnim potrebama je bila na podsticaju od <strong>{selectedDiagnostic?.data?.client_on_stimulation_from}</strong> do{' '}
              <strong>{selectedDiagnostic?.data?.client_on_stimulation_to}</strong> na ulaznom postupku
            </Td>
          </Tr>
          <Tr border="1px">
            <Td border="1px">2.2</Td>
            <Td>
              Osobi sa posebnim potrebama su potrebne dalje mjere podsticaja kroz
              <UnorderedList mt="15px" ml="5px" listStyleType="none">
                {selectedDiagnostic?.data?.is_extending_entry_process && (
                  <ListItem>
                    <Checkbox mr="10px" my="5px" isChecked={selectedDiagnostic?.data?.is_extending_entry_process} readOnly>
                      produžavanje ulaznog postupka do <strong>{selectedDiagnostic?.data?.extending_entry_process_to}</strong>
                    </Checkbox>
                  </ListItem>
                )}
                {selectedDiagnostic?.data?.is_adding_to_group_for_working_area && (
                  <ListItem>
                    <Checkbox mr="10px" my="5px" isChecked={selectedDiagnostic?.data?.is_adding_to_group_for_working_area} readOnly>
                      prijem u grupu obuke za radno područje od <strong>{selectedDiagnostic?.data?.adding_to_group_for_working_area_from}</strong> do{' '}
                      <strong>{selectedDiagnostic?.data.adding_to_group_for_working_area_to}</strong>.
                    </Checkbox>
                  </ListItem>
                )}
                {selectedDiagnostic?.data?.is_adding_to_group_for_stimulation_and_care && (
                  <ListItem>
                    <Checkbox mr="10px" my="5px" isChecked={selectedDiagnostic?.data?.is_adding_to_group_for_stimulation_and_care} readOnly>
                      prijem u grupu za podsticaj i zbrinjavanje od <strong>{selectedDiagnostic?.data?.adding_to_group_for_stimulation_and_care_from}</strong>
                    </Checkbox>
                  </ListItem>
                )}
                {selectedDiagnostic?.data?.is_adding_to_center_for_stimulation && (
                  <ListItem>
                    <Checkbox mr="10px" my="5px" isChecked={selectedDiagnostic?.data?.is_adding_to_center_for_stimulation} readOnly>
                      prijem u drugu ustanovu za zbrinjavanje / mjere zbrinjavanja prijedlog: <strong>{selectedDiagnostic?.data?.adding_to_center_for_stimulation_details}</strong>
                    </Checkbox>
                  </ListItem>
                )}
              </UnorderedList>
            </Td>
          </Tr>
          <Tr border="1px">
            <Td border="1px">2.3</Td>
            <Td>
              Osobi sa posebnim potrebama su za profesionalnu integraciju potrebna sljedeća pomoćna sredstva / tehnička radna sredstva:
              <Text><strong>{selectedDiagnostic?.data?.help_details_for_professional_integration}</strong></Text>
            </Td>
          </Tr>
          <Tr border="1px">
            <Td border="1px">2.4</Td>
            <Td>
              Rezimirani ili dopunjujući stav radionice, ostale napomene ili prijedlozi za dalji razvoj osobe sa posebnim potrebama po područjima:
              <OrderedList>
                <ListItem my="10px">
                  <Heading size="sm" my="15px;">
                    BRIGA O SEBI:
                  </Heading>
                  <Text> {selectedDiagnostic?.data?.self_care}</Text>
                </ListItem>
                <ListItem my="10px">
                  <Heading size="sm" my="15px;">
                    KOGNITIVNE KOMPETENCIJE:
                  </Heading>
                  <Text> {selectedDiagnostic?.data?.congitive_competents}</Text>
                </ListItem>
                <ListItem my="10px">
                  <Heading size="sm" my="15px;">
                    EMOCIONALNE I PSIHIČKE KOMPETENCIJE:
                  </Heading>
                  <Text> {selectedDiagnostic?.data?.emotional_and_physic_competents}</Text>
                </ListItem>
                <ListItem my="10px">
                  <Heading size="sm" my="15px;">
                    SOCIJALNE VJEŠTINE:
                  </Heading>
                  <Text> {selectedDiagnostic?.data?.social_skills}</Text>
                </ListItem>
                <ListItem my="10px">
                  <Heading size="sm" my="15px;">
                    RADNE KOMPETENCIJE I PERSPEKTIVA:
                  </Heading>
                  <Text> {selectedDiagnostic?.data?.working_competents_and_perspective}</Text>
                </ListItem>
              </OrderedList>
              <Divider mt="20px" mb="5px" />
              Korišteni instrumentarij za procjenu: - Shema vrednovanja; Potreba za pomoći u individualnom obliku života (H.M.B.- W/ Verzija 5/20001)- Lebenshilfe Plauen -Shema za praćenje radnih
              sposobnosti (?) (Izradio: LSD, Fr. Redner)
              <Flex mt="50px" width="100%" justifyContent="space-between">
                <Box>
                  <Text>Procjenu izvršio: </Text>
                  <Heading size="sm" my="15px;">
                    {getEmployeeName(selectedDiagnostic?.data?.employee_id)}
                    <Divider mt="5px" />
                  </Heading>
                </Box>
                <Box>
                  <Text>Mjesto i vrijeme izrade: </Text>
                  <Heading size="sm" my="15px;">
                    <Text>{getCenterName(selectedDiagnostic?.data?.checkup_location)}</Text> 
                    <Text>{selectedDiagnostic?.data?.created_at && moment(selectedDiagnostic?.data?.created_at).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)}</Text>
                    <Divider mt="5px" />
                  </Heading>
                </Box>
              </Flex>
            </Td>
          </Tr>
        </Table>
      </Box>
    );
  };

  return (
    <div className="add-entry-diagnostic">
      <Heading size="lg" textAlign="center" mb="50px">
        <Text>Opservacijski i razvojni izvještaj</Text>
        <Text>Ulazna dijagnostika</Text>
      </Heading>
      {renderClientInfo()}
      {renderDiagnosticData()}
      <Flex justify="center" my="15px">
        <Button as={Link} variant="ghost" size="md" to={`${routes.CLIENTS}/${selectedDiagnostic?.data?.person_id}`}>
          Nazad
        </Button>
      </Flex>
      <SpinnerCentered show={isLoading} />
    </div>
  );
};

export default ViewEntryDiagnostic;
