import { Button, Flex, Select, Table, Tag, TagLabel, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';

import constants from '../../../config/constants';
import api from '../../../api';
import AddCheckupModal from '../../shared/modals/add-checkup/add-checkup-modal';
import SpinnerCentered from '../../shared/spinner-centered/spinner-centered';
import ViewCheckupModal from '../../shared/modals/view-checkup/view-checkup-modal';

const CheckupsForClient = (props) => {
  const { showHeader, showTitle, showActions, checkups, clientId, isClientLoading } = props;

  const [checkupId, setScheduleId] = useState(null);
  const [showViewCheckupModal, setShowViewCheckupModal] = useState(false);
  const [showAddEditCheckupModal, setShowAddEditCheckupModal] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);

  const employeesQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES, api.employees.getEmployees);
  const checkupTypesQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_CHECKUP_TYPES_FOR_CHECKUPS, api.checkupTypes.getCheckupTypes);

  // Add Edit schedule toggler
  const handleToggleAddEditCheckupModal = (e, data) => {
    e.stopPropagation();
    const { id, show } = data;
    setScheduleId(id);
    setShowAddEditCheckupModal(show);
  };

  const handleCloseAddEditModal = () => {
    setScheduleId(null);
    setShowAddEditCheckupModal(false);
  };

  const handleToggleViewCheckupModal = (data) => {
    const { id, show } = data;
    setScheduleId(id);
    setShowViewCheckupModal(show);
  };

  const columns = [
    {
      title: 'Datum i vrijeme terapije',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Vrsta terapije',
      dataIndex: 'date',
      key: 'checkupType',
    },
    {
      title: 'Terapeut',
      dataIndex: 'date',
      key: 'checkupType',
    },
  ];

  useEffect(() => {
    setFilteredResults(checkups);
  }, [checkups]);

  const filterByType = (input) => {
    const value = input.target.value;
    if (value === 'sve') {
      setFilteredResults(checkups);
    } else {
      const searchRes = checkups?.filter((checkup) => value.includes(checkup.checkup_type));
      setFilteredResults(searchRes);
    }
  };

  const getCheckupTypeName = (id) => {
    const checkupType = checkupTypesQuery?.data?.find((checkupType) => checkupType.id === id);
    return checkupType?.name || null;
  };

  const getEmployeeName = (id) => {
    const selectedEmployee = employeesQuery?.data?.find((employee) => employee.id === id);
    return `${selectedEmployee?.first_name} ${selectedEmployee?.last_name}` || null;
  };

  const isLoading = checkupTypesQuery.isLoading || isClientLoading;

  const renderHeader = () => {
    return (
      showHeader && (
        <div className="pa-widget__title-area">
          <div className="pa-widget-title">{showTitle && <h3>Termini</h3>}</div>
          <Flex>
            <Select borderRadius="0" size="sm" width="180px" onChange={filterByType}>
              <option value="sve">Sve terapije</option>
              {checkupTypesQuery?.data?.map((checkupType) => (
                <option key={checkupType.id + checkupType.name} value={checkupType.id}>
                  {checkupType.name}
                </option>
              ))}
            </Select>
            <Button variant="outline" size="sm" ml="10px" onClick={(e) => handleToggleAddEditCheckupModal(e, { id: null, show: true })}>
              <span className="material-icons">fact_check</span>
            </Button>
          </Flex>
        </div>
      )
    );
  };

  const renderTable = () => {
    return (
      <Table size="sm" variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            {columns.map((column, index) => (
              <Th key={column.title + index}>{column.title}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {filteredResults && filteredResults.length ? (
            filteredResults.map((checkup) => {
              return (
                <Tr
                  key={checkup.id + checkup.created_at}
                  onClick={() => {
                    handleToggleViewCheckupModal({ id: checkup.id, show: true });
                  }}
                  cursor="pointer"
                >
                  <Td>{checkup?.created_at && moment(checkup.created_at).format(constants.MOMENT_FORMAT.DATE_TIME_DOT_SEPARATED)}</Td>
                  <Td>
                    {!checkupTypesQuery.isLoading && (
                      <Tag size="md" variant="subtle" colorScheme="cyan">
                        <TagLabel>{getCheckupTypeName(checkup.checkup_type)}</TagLabel>
                      </Tag>
                    )}
                  </Td>
                  <Td>{getEmployeeName(checkup.created_by)}</Td>
                  {showActions && (
                    <Td isNumeric>
                      <Button
                        onClick={() => {
                          handleToggleViewCheckupModal({ id: checkup.id, show: true });
                        }}
                        size="xs"
                        colorScheme="green"
                        variant="ghost"
                      >
                        Pogledaj
                      </Button>
                      <Button
                        onClick={(e) => {
                          handleToggleAddEditCheckupModal(e, { id: checkup.id, show: true });
                        }}
                        size="xs"
                        colorScheme="yellow"
                        variant="ghost"
                      >
                        Uredi
                      </Button>
                    </Td>
                  )}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td textAlign="center" colSpan={showActions ? 6 : 5}>
                Nema rezultata
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    );
  };

  return (
    <div className="documents-widget">
      {renderHeader()}
      <div className="pa-widget__content-area">
        <SpinnerCentered show={checkupTypesQuery.isLoading} />
        {!isLoading && renderTable()}
      </div>
      <AddCheckupModal checkupId={checkupId} clientId={clientId} showModal={showAddEditCheckupModal} onClose={handleCloseAddEditModal} />
      <ViewCheckupModal checkupId={checkupId} showModal={showViewCheckupModal} onClose={() => handleToggleViewCheckupModal({ id: null, show: false })} />
    </div>
  );
};

export default CheckupsForClient;
