import axios from '../config/axios-config';

const getEmployees = async () => {
  const response = await axios({
    method: 'GET',
    url: '/employees',
  });
  return response.data || response;
};

const getEmployee = async (id: number) => {
  const response = await axios({
    method: 'GET',
    url: `/employees/${id}`,
  });
  return response.data || [];
};

const addEmployee = async (data: any) => {
  const response = await axios({
    method: 'POST',
    url: `/employees`,
    data,
  });
  return response.data || [];
};

const updateEmployee = async (data: any) => {
  const response = await axios({
    method: 'PUT',
    url: `/employees/${data.id}`,
    data,
  });
  return response.data || [];
};

const changeStatus = async (data: any) => {
  const response = await axios({
    method: 'PUT',
    url: `/employees/change-status`,
    data,
  });
  return response.data || [];
};

const changePassword = async (data: any) => {
  const response = await axios({
    method: 'PUT',
    url: `/employees/change-password`,
    data,
  });
  return response.data || [];
};

const changeOwnPassword = async (data: any) => {
  const response = await axios({
    method: 'PUT',
    url: `/employees/change-own-password`,
    data,
  });
  return response.data || [];
};

const assignRole = async (data: any) => {
  const response = await axios({
    method: 'PUT',
    url: `/employees/assign-role`,
    data,
  });
  return response.data || [];
};

const unassignRole = async (data: any) => {
  const response = await axios({
    method: 'DELETE',
    url: `/employees/unassign-role`,
    data,
  });
  return response.data || [];
};

const assignWorkingPosition = async (data: any) => {
  const response = await axios({
    method: 'PUT',
    url: `/employees/assign-working-position`,
    data,
  });
  return response.data || [];
};

const unassignWorkingPosition = async (data: any) => {
  const response = await axios({
    method: 'DELETE',
    url: `/employees/unassign-working-position`,
    data,
  });
  return response.data || [];
};

const employees = {
  getEmployees,
  getEmployee,
  addEmployee,
  updateEmployee,
  unassignRole,
  assignRole,
  changeOwnPassword,
  changePassword,
  changeStatus,
  assignWorkingPosition,
  unassignWorkingPosition,
};

export default employees;
