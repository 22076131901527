import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { useTitle } from '../helpers/hooks/useTitle';

import state from '../state';
import PAEANRoutes from './routes';

import UnauthorizedAccess from '../../features/unauthorized-access/unauthorized-access';

const PrivateRoute = ({ pageTitle, expectedRoles, children }) => {
  const { t } = useTranslation();
  useTitle(t(pageTitle));

  const isLoggedIn = state.useAuth((state) => state.isLoggedIn);
  const userRoles = state.userRoles((state) => state.userRoles) || [];

  const rolesMatch = expectedRoles.some((r) => userRoles.indexOf(r) >= 0);

  if (isLoggedIn && rolesMatch) {
    return children;
  } else {
    if (isLoggedIn) {
      return <UnauthorizedAccess />;
    } else {
      return <Navigate to={PAEANRoutes.LOGIN} replace />;
    }
  }
};

export default PrivateRoute;
