import axios from '../config/axios-config';

const getClients = async (data: any) => {
  const response = await axios({
    method: 'GET',
    url: `/persons`,
    data,
  });
  return response.data || [];
};
const getClient = async (id: any) => {
  const response = await axios({
    method: 'GET',
    url: `/persons/${id}`,
  });
  return response.data || [];
};
const addClient = async (data: any) => {
  const response = await axios({
    method: 'POST',
    url: `/persons`,
    data,
  });
  return response.data || [];
};



const updateClient = async (data: any) => {
  const response = await axios({
    method: 'PUT',
    url: `/persons/${data?.id}`,
    data: data?.values,
  });
  return response.data || [];
};

const assignDiagnose = async (data: any) => {
  const response = await axios({
    method: 'POST',
    url: `/persons/assign-diagnose`,
    data,
  });
  return response.data || [];
};
const unassignDiagnose = async (data: any) => {
  const response = await axios({
    method: 'POST',
    url: `/persons/unassign-diagnose`,
    data,
  });
  return response.data || [];
};
const assignTherapist = async (data: any) => {
  const response = await axios({
    method: 'POST',
    url: `/persons/assign-therapist`,
    data,
  });
  return response.data || [];
};
const unassignTherapist = async (data: any) => {
  const response = await axios({
    method: 'POST',
    url: `/persons/unassign-therapist`,
    data,
  });
  return response.data || [];
};

const deleteClient = async (data: any) => {
  const response = await axios({
    method: 'POST',
    url: `/persons/delete-person`,
    data,
  });
  return response.data || [];
};

const positions = {
  getClients,
  getClient,
  addClient,
  updateClient,
  assignDiagnose,
  unassignDiagnose,
  deleteClient,
  assignTherapist,
  unassignTherapist,
};

export default positions;
