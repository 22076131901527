import { Button, Flex, Input, Link, Select, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '../../../../api';
import constants from '../../../../config/constants';
import state from '../../../../core/state';

import ConfirmDialog from '../../confirm-dialog/confirm-dialog';
import AddDocumentModal from '../../modals/add-document/add-document-modal';
import Pagination from '../../pagination/pagination';

import './documents-widget.scss';

const DocumentsWidget = (props) => {
  const { showHeader, showActions, showTitle, searchBarWidth = '60%', limitTo } = props;

  const [filteredResults, setFilteredResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteDialogMessage, setDeleteDialogMessage] = useState('');
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const userInfo = state.currentUser((state) => state.userInfo);

  const queryClient = useQueryClient();

  const documentTypes = useQuery(constants.QUERY_NAME.HANDLE_FETCH_GENERAL_DOCUMENT_TYPES, api.documentTypes.getGeneralDocumentTypes);

  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);

  const documentsQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_GENERAL_DOCUMENTS, api.documents.getGeneralDocumens);
  const employeesQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES, api.employees.getEmployees);

  const columns = [
    {
      title: 'Dokument',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tip dokumenta',
      dataIndex: 'docType',
      key: 'phone',
    },
    {
      title: 'Datum dodavanja',
      dataIndex: 'date',
      key: 'phone',
    },
    {
      title: 'Dodao',
      dataIndex: 'email',
      key: 'email',
    },
  ];

  useEffect(() => {
    const documentsListRaw = documentsQuery?.data || [];
    if (limitTo) {
      setFilteredResults(documentsListRaw.slice(0, limitTo));
    } else {
      setFilteredResults(documentsListRaw.slice((currentPage - 1) * constants.PAGINATION_DEFAULT_PAGE_SIZE, currentPage * constants.PAGINATION_DEFAULT_PAGE_SIZE));
    }
  }, [currentPage, documentsQuery?.data, limitTo]);

  const handleToggleDeleteDialog = (data) => {
    const { id, show, name } = data;
    setSelectedDocumentId(id);
    setShowDeleteDialog(show);
    setDeleteDialogMessage(`Da li ste sigurni da želite obrisati ${name}`);
  };

  const searchEmployees = (input) => {
    const value = input.target.value;
    if (documentsQuery?.data?.length && value.length >= 3) {
      const searchRes = documentsQuery?.data?.filter((document) => document.name.toLowerCase().includes(value.toLowerCase()));
      setFilteredResults(searchRes);
    } else {
      setFilteredResults(documentsQuery?.data);
    }
  };

  const filterByType = (input) => {
    const value = input.target.value;
    if (value === 'Svi tipovi') {
      setFilteredResults(documentsQuery?.data);
    } else {
      const searchRes = documentsQuery?.data?.filter((document) => document.document_type === parseInt(value, 10));
      setFilteredResults(searchRes);
    }
  };

  const getDocumentTypeName = (typeId) => {
    if (documentTypes?.data && typeId) {
      const typeName = documentTypes.data.find((documentType) => documentType.id === typeId);
      return typeName?.name;
    }
    return null;
  };

  const getEmployeeName = (id) => {
    if (employeesQuery?.data && id) {
      const employeeName = employeesQuery.data.find((documentType) => documentType.id === id);
      return `${employeeName?.first_name} ${employeeName?.last_name}`;
    }
    return null;
  };

  const documentsDownloadMutation = useMutation(api.documents.downloadGeneralDocument, {
    onSuccess: (fileData, variables) => {
      if (!(fileData.file instanceof Blob)) return;
      toast.success('Dokument uspješno preuzet.');
      const file = new Blob([fileData.file], { type: fileData.contentType });
      const fileURL = window.URL.createObjectURL(file);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', variables);
      tempLink.click();
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom preuzimanja dokumenta.');
    },
  });

  const deleteUserDocumentMutation = useMutation(api.documents.deleteClientDocument, {
    onSuccess: () => {
      toast.success('Dokument uspješno obrisan.');
      handleToggleDeleteDialog({
        name: null,
        id: null,
        show: false,
      });
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_GENERAL_DOCUMENTS);
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom brisanja dokumenta.');
    },
  });

  const downloadDocument = (id) => {
    documentsDownloadMutation.mutate(id);
  };

  const deleteUserDocument = (id) => {
    deleteUserDocumentMutation.mutate(id);
  };

  const renderPagination = () => {
    const documentsListData = documentsQuery?.data;
    if (!documentsListData || limitTo) return null;
    return (
      <Pagination
        className="transactions-list__pagination"
        currentPage={currentPage}
        totalCount={documentsQuery?.data?.length}
        pageSize={10}
        onPageChange={setCurrentPage}
        previousText={'Prethodna'}
        nextText={'Naredna'}
      />
    );
  };

  const canUserDelete = (document) => {
    const uploadedBy = document?.uploaded_by;

    if (uploadedBy) {
      const isOwner = userInfo.id === uploadedBy;

      const isAdmin = userInfo.roles.includes('Admin');

      return isOwner || isAdmin || deleteUserDocumentMutation.isLoading;
    } else {
      return true;
    }
  };

  return (
    <div className="documents-widget">
      {showHeader && (
        <div className="pa-widget__title-area">
          <div className="pa-widget-title">{showTitle && <h3>Dokumenti</h3>}</div>
          <Input placeholder="Pretraga" onChange={searchEmployees} borderRadius="0" type="search" size="sm" width={searchBarWidth} />
          <Flex>
            <Select borderRadius="0" size="sm" width="180px" onChange={filterByType}>
              <option value="Svi tipovi">Svi tipovi</option>
              {documentTypes?.data?.map((docType) => (
                <option value={docType.id} key={docType.id + docType.name}>
                  {docType.name}
                </option>
              ))}
            </Select>
            <Button variant="outline" size="sm" ml="10px" onClick={() => setShowAddDocumentModal(true)}>
              <span className="material-icons">note_add</span>
            </Button>
          </Flex>
        </div>
      )}
      <div className="pa-widget__content-area">
        <Table size="sm" variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {columns.map((column, index) => (
                <Th key={column.title + index}>{column.title}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {filteredResults && filteredResults.length ? (
              filteredResults?.map((document, index) => {
                return (
                  <Tr key={document.name + index}>
                    <Td>
                      <Link color="#EB268F" href={document.file} onClick={() => downloadDocument(document.file_name)} rel="noreferrer noopener">
                        {document.name}
                      </Link>
                    </Td>
                    <Td>{getDocumentTypeName(document.document_type)}</Td>
                    <Td>{document?.upload_date && moment(document.upload_date).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)}</Td>
                    <Td>{getEmployeeName(document.uploaded_by)}</Td>
                    {showActions && (
                      <Td isNumeric>
                        {canUserDelete(document) && (
                          <Button ml="5px" size="xs" colorScheme="red" variant="ghost" onClick={() => handleToggleDeleteDialog({ id: document.id, name: document.name, show: true })}>
                            Obriši
                          </Button>
                        )}
                      </Td>
                    )}
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td textAlign="center" colSpan={showActions ? 5 : 4}>
                  Nema rezultata
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        {renderPagination()}
        {/* <Flex my="15px" align="center" justify="center">
          <Spinner thickness="3px" speed="0.5s" emptyColor="gray.200" color="#2fb344" size="md" label="Ucitavanje" />
        </Flex> */}
      </div>
      <AddDocumentModal showModal={showAddDocumentModal} onClose={() => setShowAddDocumentModal(false)} />
      <ConfirmDialog
        isOpen={showDeleteDialog}
        message={deleteDialogMessage}
        onClose={() => handleToggleDeleteDialog({ name: null, id: null, show: false })}
        onConfirm={() => deleteUserDocument(selectedDocumentId)}
      />
    </div>
  );
};

export default DocumentsWidget;
