import { Button, Flex, Input, Link, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import api from '../../../../api';
import constants from '../../../../config/constants';

import AddRuleModal from '../../modals/add-rule/add-rule-modal';

import './rules-widget.scss';

const RulesWidget = (props) => {
  const { showHeader, showActions, showTitle, searchBarWidth = '60%' } = props;

  const [filteredResults, setFilteredResults] = useState([]);

  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);

  const documentsQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_RULES_DOCUMENTS, api.documents.getRulesDocumens);
  const employeesQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES, api.employees.getEmployees);

  const columns = [
    {
      title: 'Dokument',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Datum dodavanja',
      dataIndex: 'date',
      key: 'phone',
    },
    {
      title: 'Dodao',
      dataIndex: 'email',
      key: 'email',
    },
  ];

  useEffect(() => {
    setFilteredResults(documentsQuery?.data);
  }, [documentsQuery?.data]);

  const searchEmployees = (input) => {
    const value = input.target.value;
    if (documentsQuery?.data?.length && value.length >= 3) {
      const searchRes = documentsQuery?.data?.filter((document) => document.name.toLowerCase().includes(value.toLowerCase()));
      setFilteredResults(searchRes);
    } else {
      setFilteredResults(documentsQuery?.data);
    }
  };

  const getEmployeeName = (id) => {
    if (employeesQuery?.data && id) {
      const employeeName = employeesQuery.data.find((documentType) => documentType.id === id);
      return `${employeeName?.first_name} ${employeeName?.last_name}`;
    }
    return null;
  };

  const documentsDownloadMutation = useMutation(api.documents.downloadRulesDocument, {
    onSuccess: (fileData, variables) => {
      if (!(fileData.file instanceof Blob)) return;
      toast.success('Dokument uspješno preuzet.');
      const file = new Blob([fileData.file], { type: fileData.contentType });
      const fileURL = window.URL.createObjectURL(file);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', variables);
      tempLink.click();
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom preuzimanja dokumenta.');
    },
  });

  const downloadDocument = (id) => {
    documentsDownloadMutation.mutate(id);
  };

  return (
    <div className="rules-widget">
      {showHeader && (
        <div className="pa-widget__title-area">
          <div className="pa-widget-title">{showTitle && <h3>Dokumenti</h3>}</div>
          <Input placeholder="Pretraga" onChange={searchEmployees} borderRadius="0" type="search" size="sm" width={searchBarWidth} />
          <Flex>
            <Button variant="outline" size="sm" ml="10px" onClick={() => setShowAddDocumentModal(true)}>
              <span className="material-icons">note_add</span>
            </Button>
          </Flex>
        </div>
      )}
      <div className="pa-widget__content-area">
        <Table size="sm" variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {columns.map((column, index) => (
                <Th key={column.title + index}>{column.title}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {filteredResults && filteredResults.length ? (
              filteredResults?.map((document, index) => {
                return (
                  <Tr key={document.name + index}>
                    <Td>
                      <Link color="#EB268F" href={document.file} onClick={() => downloadDocument(document.file_name)} rel="noreferrer noopener">
                        {document.name}
                      </Link>
                    </Td>
                    <Td>{document?.upload_date && moment(document.upload_date).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)}</Td>
                    <Td>{getEmployeeName(document.uploaded_by)}</Td>
                    {showActions && (
                      <Td isNumeric>
                        <Button size="xs" colorScheme="yellow" variant="ghost">
                          Uredi
                        </Button>
                        <Button ml="5px" size="xs" colorScheme="red" variant="ghost">
                          Obriši
                        </Button>
                      </Td>
                    )}
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td textAlign="center" colSpan={showActions ? 5 : 4}>
                  Nema rezultata
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        {/* <Flex my="15px" align="center" justify="center">
          <Spinner thickness="3px" speed="0.5s" emptyColor="gray.200" color="#2fb344" size="md" label="Ucitavanje" />
        </Flex> */}
      </div>
      <AddRuleModal showModal={showAddDocumentModal} onClose={() => setShowAddDocumentModal(false)} />
    </div>
  );
};

export default RulesWidget;
