import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const UsersPerService = (props) => {
  const { services } = props;
  if (!services) return null;

  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
        display: true,
      },
      title: {
        display: false,
        text: 'Chart.js Horizontal Bar Chart',
      },
    },
  };

  const labels = services.map((service) => service.service_name);
  const personsTotal = services.map((service) => service.active_count);
  const personsOnWaitList = services.map((service) => service.on_wait_list_count);

  const data = {
    labels,
    datasets: [
      {
        label: 'Aktivni',
        data: personsTotal,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Na listi čekanja',
        data: personsOnWaitList,
        borderColor: 'rgb(255,255,0)',
        backgroundColor: 'rgba(255,255,0, 0.5)',
      },
    ],
  };

  return (
    <Box {...props}>
      <Bar options={options} data={data} />
    </Box>
  );
};

export default UsersPerService;
