import { Heading, Text } from '@chakra-ui/react';
import ClientsWidget from '../shared/widgets/clients/clients-widget';

const Clients = () => {
  return (
    <div className="clients">
      <Heading textAlign="center">Korisnici</Heading>
      <Text>Lista korisnika centra</Text>
      <ClientsWidget showActions />
    </div>
  );
};

export default Clients;
