import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, Flex, Button, Input } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import api from '../../../../api';
import constants from '../../../../config/constants';
import AddCheckupTypeModal from '../../modals/add-checkup-type/add-checkup-type-modal';
import SpinnerCentered from '../../spinner-centered/spinner-centered';

import './checkup-types-widget.scss';

const CheckupTypesWidget = (props) => {
  const { showActions, showTitle, searchBarWidth = '40%' } = props;

  const [filteredResults, setFilteredResults] = useState([]);
  const [selectedCheckupTypeId, setSelectedCheckupTypeId] = useState(null);
  const [showAddEditModal, setShowAddEditModal] = useState(false);

  const checkupTypes = useQuery(constants.QUERY_NAME.HANDLE_FETCH_CHECKUP_TYPES, api.checkupTypes.getCheckupTypes);

  const columns = [
    {
      title: 'Terapija',
      dataIndex: 'code',
      key: 'name',
    },
    {
      title: 'Opis',
      dataIndex: 'code',
      key: 'description',
    },
  ];

  useEffect(() => {
    setFilteredResults(checkupTypes.data);
  }, [checkupTypes.data]);

  const searchDiagnosis = (input) => {
    const value = input.target.value;
    if (checkupTypes.data?.length && value.length >= 1) {
      const searchRes = checkupTypes?.data?.filter((diagnose) => diagnose.name.toLowerCase().includes(value.toLowerCase()));
      setFilteredResults(searchRes);
    } else {
      setFilteredResults(checkupTypes.data);
    }
  };

  const handleToggleAddEditModal = (data) => {
    const { id, showModal } = data;
    setShowAddEditModal(showModal);
    setSelectedCheckupTypeId(id);
  };

  return (
    <div className="checkup-types-widget">
      <div className="pa-widget__title-area">
        <div className="pa-widget-title">{showTitle && <h3>Vrste Terapija</h3>}</div>
        <Input placeholder="Pretraga dijagnoza po nazivu" onChange={searchDiagnosis} borderRadius="0" type="search" size="sm" width={searchBarWidth} />
        <Flex>
          {showActions && (
            <Button variant="outline" size="sm" ml="10px" onClick={() => handleToggleAddEditModal({ id: null, showModal: true })}>
              <span className="material-icons">medical_services</span>
            </Button>
          )}
        </Flex>
      </div>
      <div className="pa-widget__content-area">
        <Table size="sm" variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {columns.map((column, index) => (
                <Th key={column.title + index}>{column.title}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {filteredResults && filteredResults.length
              ? filteredResults.map((diagnose, index) => {
                  return (
                    <Tr key={diagnose.name + index}>
                      <Td>{diagnose.name}</Td>
                      <Td>{diagnose.description}</Td>
                      {showActions && (
                        <Td isNumeric>
                          <Button size="xs" colorScheme="yellow" variant="ghost" onClick={() => handleToggleAddEditModal({ id: diagnose.id, showModal: true })}>
                            Uredi
                          </Button>
                        </Td>
                      )}
                    </Tr>
                  );
                })
              : !checkupTypes.isLoading && (
                  <Tr>
                    <Td textAlign="center" colSpan={showActions ? 5 : 4}>
                      Nema rezultata
                    </Td>
                  </Tr>
                )}
          </Tbody>
          <Tfoot></Tfoot>
        </Table>
        <SpinnerCentered show={checkupTypes.isLoading} />
        <AddCheckupTypeModal
          showModal={showAddEditModal}
          id={selectedCheckupTypeId}
          onClose={() => {
            handleToggleAddEditModal({ id: null, show: false });
          }}
        />
      </div>
    </div>
  );
};

export default CheckupTypesWidget;
