import { useState } from 'react';
import { Button, Divider, Flex, Heading, ListItem, Table, Tag, TagCloseButton, TagLabel, Tbody, Td, Text, Th, Thead, Tr, UnorderedList, Box } from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import { toast } from 'react-toastify';

import api from '../../../api';
import constants from '../../../config/constants';
import routes from '../../../core/routes/routes';

import AddDateModal from '../../shared/modals/add-work-schedule/add-work-schedule-modal';
import AddDocumentModal from '../../shared/modals/add-document/add-document-modal';
import SpinnerCentered from '../../shared/spinner-centered/spinner-centered';
import AssignDiagnoseModal from '../../shared/modals/assign-diagnose/assign-diagnose-modal';
import AssignTherapistModal from '../../shared/modals/assign-therapist/assign-therapist-modal';
import ConfirmDialog from '../../shared/confirm-dialog/confirm-dialog';
import CheckupsForClient from '../checkups-for-client/checkups-for-client';
import ClientDocumentsWidget from '../../shared/widgets/client-documents/client-documents-widget';

import './view-client.scss';

const ViewClient = () => {
  const queryClient = useQueryClient();

  const { id } = useParams();
  const [showAssignDiagnoseModal, setShowAssignDiagnoseModal] = useState(false);
  const [showAssignTherapistModal, setShowAssignTherapistModal] = useState(false);
  const [showUnAssignDiagnoseConfirmModal, setShowUnAssignDiagnoseConfirmModal] = useState(false);
  const [selectedDiagnoseToUnassignId, setSelectedDiagnoseToUnassignId] = useState(null);
  const [selectedDiagnoseToUnassignName, setSelectedDiagnoseToUnassignName] = useState(null);
  const [showUnAssignTherapistConfirmModal, setShowUnAssignTherapistConfirmModal] = useState(false);
  const [selectedTherapistToUnassignId, setSelectedTherapistToUnassignId] = useState(null);
  const [selectedTherapistToUnassignName, setSelectedTherapistToUnassignName] = useState(null);

  const selectedClient = useQuery([constants.QUERY_NAME.HANDLE_FETCH_CLIENT, id], ({ queryKey }) => api.clients.getClient(queryKey[1]), { enabled: !!id });
  const employeesQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES, api.employees.getEmployees);
  // const entryDiagnosticForUser = useQuery([constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES, id], ({ queryKey }) => api.entryDiagnostics.getEntryDiagnosticForUser(queryKey[1]), { enabled: !!id });
  const centersQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_WORK_SCHEDULES, api.centers.getCenters);

  const unassignDiagnoseMutation = useMutation(api.clients.unassignDiagnose, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CLIENT);
      toast.success('Dijagnoza uspješno uklonjena.');
      handleToggleUnassignDiagnoseDialog();
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom uklanjanja dijagnoze.');
    },
  });
  const unassignTherapistMutation = useMutation(api.clients.unassignTherapist, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CLIENT);
      toast.success('Terapeut uspješno uklonjen.');
      handleToggleUnassignTherapistDialog();
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom uklanjanja terapeuta.');
    },
  });

  const getEmployeeName = (id) => {
    const selectedEmployee = employeesQuery?.data?.find((employee) => employee.id === id);
    return `${selectedEmployee?.first_name} ${selectedEmployee?.last_name}` || null;
  };

  const toggleShowAssignDiagnoseModal = (show) => {
    setShowAssignDiagnoseModal(show);
  };

  const toggleShowAssignTherapistModal = (show) => {
    setShowAssignTherapistModal(show);
  };

  const handleUnassignDiagnose = () => {
    unassignDiagnoseMutation.mutate({ clientId: parseInt(id, 10), diagnose: parseInt(selectedDiagnoseToUnassignId, 10) });
  };

  const handleUnassignTherapist = () => {
    unassignTherapistMutation.mutate({ clientId: parseInt(id, 10), therapistId: parseInt(selectedTherapistToUnassignId, 10) });
  };

  const handleToggleUnassignDiagnoseDialog = (props) => {
    if (props) {
      const { diagnoseId, diagnoseName } = props;
      setSelectedDiagnoseToUnassignId(diagnoseId);
      setSelectedDiagnoseToUnassignName(diagnoseName);
      setShowUnAssignDiagnoseConfirmModal(true);
    } else {
      setSelectedDiagnoseToUnassignId(null);
      setSelectedDiagnoseToUnassignName(null);
      setShowUnAssignDiagnoseConfirmModal(false);
    }
  };
  const handleToggleUnassignTherapistDialog = (props) => {
    if (props) {
      const { therapistId, therapistName, therapistLastName } = props;
      setSelectedTherapistToUnassignId(therapistId);
      setSelectedTherapistToUnassignName(`${therapistName} ${therapistLastName}`);
      setShowUnAssignTherapistConfirmModal(true);
    } else {
      setSelectedTherapistToUnassignId(null);
      setSelectedTherapistToUnassignName(null);
      setShowUnAssignTherapistConfirmModal(false);
    }
  };

  const getCenterName = (id) => {
    if (centersQuery?.data && id) {
      const center = centersQuery.data.find((center) => center.id === id);
      return center.name;
    } else {
      return null;
    }
  };

  return selectedClient.isLoading ? (
    <SpinnerCentered show="true" />
  ) : (
    <div className="view-client">
      <Heading textAlign="center">
        <Text textColor={constants.PRIMARY_COLOR}>
          {selectedClient?.data?.first_name} {selectedClient?.data?.last_name}
          {selectedClient?.data?.is_on_wait_list && (
            <Tag size="md" colorScheme="teal">
              <TagLabel>Na listi čekanja</TagLabel>
            </Tag>
          )}
        </Text>
      </Heading>
      <div className="view-client__wrapper">
        <Tabs>
          <TabList>
            <Flex justifyContent="space-between" width="100%">
              <Flex>
                <Tab>Informacije</Tab>
                <Tab>Terapije</Tab>
                <Tab>Dokumenti</Tab>
              </Flex>
              <Button as={Link} to={`${routes.CLIENTS}/uredi/${id}`} size="sm">
                Uredi
              </Button>
            </Flex>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Heading size="sm" color={constants.PRIMARY_COLOR} colorScheme="pink" my="15px;">
                Osnovne informacije
              </Heading>
              <Table variant="striped" size="sm" padding="10px" marginTop="15px">
                <Thead>
                  <Tr>
                    <Th>Ime</Th>
                    <Th>Prezime</Th>
                    <Th>Rođeno prezime</Th>
                    <Th>JMBG</Th>
                    <Th>Majka</Th>
                    <Th>Otac</Th>
                    <Th>Spol</Th>
                    <Th>Datum rođenja</Th>
                    <Th>Mjesto rođenja</Th>
                    <Th>Grad</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{selectedClient?.data?.first_name}</Td>
                    <Td>{selectedClient?.data?.last_name}</Td>
                    <Td>{selectedClient?.data?.birth_last_name}</Td>
                    <Td>{selectedClient?.data?.identification_number}</Td>
                    <Td>{selectedClient?.data?.mother_name}</Td>
                    <Td>{selectedClient?.data?.father_name}</Td>
                    <Td>{selectedClient?.data?.sex}</Td>
                    <Td>{selectedClient?.data?.birth_date && moment(selectedClient?.data?.birth_date).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)}</Td>
                    <Td>{selectedClient?.data?.place_of_birth}</Td>
                    <Td>{selectedClient?.data?.birth_city}</Td>
                  </Tr>
                </Tbody>
              </Table>
              <Divider my="30px" />
              <Heading size="sm" color={constants.PRIMARY_COLOR} colorScheme="pink" my="15px;">
                Poslovna jedinica:
                <Text display="inline" ml="10px" color={constants.SECONDARY_COLOR}>
                  {getCenterName(selectedClient?.data?.center_id)}
                </Text>
              </Heading>
              <Divider my="30px" />
              <Heading size="sm" color={constants.PRIMARY_COLOR} colorScheme="pink" my="15px;">
                Kontakt informacije
              </Heading>
              <Table variant="striped" size="sm" padding="10px" marginTop="15px">
                <Thead>
                  <Tr>
                    <Th>Telefon</Th>
                    <Th>Adresa</Th>
                    <Th>Opština</Th>
                    <Th>Poštanski broj</Th>
                    <Th>Grad</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{selectedClient?.data?.phone}</Td>
                    <Td>{selectedClient?.data?.address_street}</Td>
                    <Td>{selectedClient?.data?.muncipality_residence}</Td>
                    <Td>{selectedClient?.data?.postal_code}</Td>
                    <Td>{selectedClient?.data?.city_of_residence}</Td>
                  </Tr>
                </Tbody>
              </Table>
              <Divider my="30px" />
              <Heading size="sm" color={constants.PRIMARY_COLOR} colorScheme="pink" my="15px;">
                Informacije o ličnim dokumentima
              </Heading>
              <Table variant="striped" size="sm" padding="10px" marginTop="15px">
                <Thead>
                  <Tr>
                    <Th>Broj lične karte</Th>
                    <Th>Lična karta izdata u</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{selectedClient?.data?.id_card_number}</Td>
                    <Td>{selectedClient?.data?.id_card_issued_location}</Td>
                  </Tr>
                </Tbody>
              </Table>
              {/* <Divider my="30px" />
              <Heading size="sm" color={constants.PRIMARY_COLOR} colorScheme="pink" my="15px;">
                Informacije o bankovnom računu
              </Heading>
              <Table variant="striped" size="sm" padding="10px" marginTop="15px">
                <Thead>
                  <Tr>
                    <Th>Banka</Th>
                    <Th>Broj žiro računa</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{selectedClient?.data?.bank_name}</Td>
                    <Td>{selectedClient?.data?.bank_account_number}</Td>
                  </Tr>
                </Tbody>
              </Table> */}
              <Divider my="30px" />
              <Flex justify-content="space-between">
                <Box w="100%">
                  <Heading size="sm" color={constants.PRIMARY_COLOR} colorScheme="pink" my="15px;">
                    Terapeuti koji rade sa korisnikom
                  </Heading>
                  <UnorderedList>
                    {selectedClient?.data?.employees?.map((employee) => (
                      <ListItem mb="10px" key={employee.first_name + employee.last_name + employee.id}>
                        <Tag>
                          <TagLabel>{`${employee.first_name} ${employee.last_name}`}</TagLabel>
                          <TagCloseButton
                            onClick={() => handleToggleUnassignTherapistDialog({ therapistId: employee.id, therapistName: employee.first_name, therapistLastName: employee.last_name })}
                          />
                        </Tag>
                      </ListItem>
                    ))}
                  </UnorderedList>

                  <Button variant="outline" size="sm" mt="15px" onClick={() => toggleShowAssignTherapistModal(true)}>
                    <span className="material-icons">badge</span> Dodijeli novog terapeuta
                  </Button>
                </Box>
                <Divider orientation="vertical" h="100%" />
                <Box w="100%">
                  <Heading size="sm" color={constants.PRIMARY_COLOR} colorScheme="pink" my="15px;">
                    Dijagnoza / vrsta teškoća
                  </Heading>
                  <UnorderedList>
                    {selectedClient?.data?.diagnosis?.map((diagnose) => (
                      <ListItem mb="10px" key={diagnose.name + diagnose.id}>
                        <Tag>
                          <TagLabel>{diagnose.name}</TagLabel>
                          <TagCloseButton onClick={() => handleToggleUnassignDiagnoseDialog({ diagnoseId: diagnose.id, diagnoseName: diagnose.name })} />
                        </Tag>
                      </ListItem>
                    ))}
                  </UnorderedList>

                  <Button variant="outline" size="sm" mt="15px" onClick={() => toggleShowAssignDiagnoseModal(true)}>
                    <span className="material-icons">health_and_safety</span> Dodijeli novu dijagnozu
                  </Button>
                </Box>
              </Flex>
              <Divider my="30px" />
              <Heading size="sm" color={constants.PRIMARY_COLOR} colorScheme="pink" my="15px;">
                Ostale informacije
              </Heading>
              <Table variant="striped" size="sm" padding="10px" marginTop="15px">
                <Thead>
                  <Tr>
                    <Th>Vrsta usluge koju korisnik koristi</Th>
                    <Th>Dodatne bilješke</Th>
                    <Th>Datum prijave</Th>
                    <Th>Datum posljednje izmjene</Th>
                    <Th>Terapeut koji je upisao korisnika</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{selectedClient?.data?.service_upon_arrival}</Td>
                    <Td>{selectedClient?.data?.note}</Td>
                    <Td>{selectedClient?.data?.created_at && moment(selectedClient?.data?.created_at).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)}</Td>
                    <Td>{selectedClient?.data?.updated_at && moment(selectedClient?.data?.updated_at).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)}</Td>
                    <Td>{getEmployeeName(selectedClient?.data?.created_by)}</Td>
                  </Tr>
                </Tbody>
              </Table>

              <Divider my="30px" />
              {/* Entry Diagnostic Part (Dynamic entry diagnostic forms), removed for now because of the need for multiple different diagnostics */}
              {/* <Heading size="sm" color={constants.PRIMARY_COLOR} colorScheme="pink" my="15px;">
                Ulazna dijagnostika
              </Heading> */}
              {/* {selectedClient?.data?.first_checkup_completed ? (
                <Table variant="striped" size="sm" padding="10px" marginTop="15px">
                  <Thead>
                    <Tr>
                      <Th>Datum ulazne dijagnostike</Th>
                      <Th>Terapeut koji je obavio ulaznu dijagnostiku</Th>
                      <Th>Akcije</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>{entryDiagnosticForUser?.data?.created_at && moment(entryDiagnosticForUser?.data?.created_at).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)}</Td>
                      <Td>{getEmployeeName(entryDiagnosticForUser?.data?.created_by)}</Td>
                      <Td>
                        <Button as={Link} colorScheme="yellow" size="xs" to={`/ulazne-dijagnostike/${entryDiagnosticForUser?.data?.id}`}>
                          Pogledaj
                        </Button>
                        <Button as={Link} colorScheme="blue" size="xs" ml="10px" to={`/ulazne-dijagnostike/uredi/${entryDiagnosticForUser?.data?.id}`}>
                          Uredi
                        </Button>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              ) : (
                <Flex my="15px">
                  <Button as={Link} to={`/ulazne-dijagnostike/dodaj/${id}`} colorScheme="gray" size="sm">
                    <i className="material-icons">add</i> Kreiraj ulaznu dijagnostiku
                  </Button>
                </Flex>
              )} */}
            </TabPanel>
            <TabPanel>
              <Heading size="sm" color={constants.PRIMARY_COLOR} colorScheme="pink" my="15px;">
                Terapije
              </Heading>
              <CheckupsForClient checkups={selectedClient?.data?.checkups} isClientLoading={selectedClient.isLoading} showHeader showActions clientId={id} />
            </TabPanel>
            <TabPanel>
              <Flex></Flex>
              <ClientDocumentsWidget showHeader clientId={id} documents={selectedClient?.data?.documents} />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <AddDateModal />
        <AddDocumentModal />
        <AssignDiagnoseModal
          showModal={showAssignDiagnoseModal}
          clientId={id}
          onClose={() => {
            toggleShowAssignDiagnoseModal(false);
          }}
        />
        <AssignTherapistModal
          showModal={showAssignTherapistModal}
          clientId={id}
          onClose={() => {
            toggleShowAssignTherapistModal(false);
          }}
        />
        <ConfirmDialog
          isOpen={showUnAssignDiagnoseConfirmModal}
          message={`Da li ste sigurni da želite ukloniti dijagnozu ${selectedDiagnoseToUnassignName}?`}
          onClose={() => handleToggleUnassignDiagnoseDialog()}
          onConfirm={() => handleUnassignDiagnose()}
        />
        <ConfirmDialog
          isOpen={showUnAssignTherapistConfirmModal}
          message={`Da li ste sigurni da želite ukloniti terapeuta ${selectedTherapistToUnassignName}?`}
          onClose={() => handleToggleUnassignTherapistDialog()}
          onConfirm={() => handleUnassignTherapist()}
        />
      </div>
      <Flex justifyContent="center" my="15px">
        <Button as={Link} size="lg" to={routes.CLIENTS}>
          Povratak na listu korisnika
        </Button>
      </Flex>
    </div>
  );
};

export default ViewClient;
