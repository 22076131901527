import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, Spinner, Select } from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import constants from '../../../../config/constants';
import api from '../../../../api';
import { toast } from 'react-toastify';
import SpinnerCentered from '../../spinner-centered/spinner-centered';
import { helpers } from '../../../../core/helpers';

const AssignTherapistModal = (props) => {
  const { clientId, showModal, onClose = () => {} } = props;
  const queryClient = useQueryClient();

  const employeesQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES, api.employees.getEmployees);

  const assignTherapistMutation = useMutation(api.clients.assignTherapist, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CLIENT);
      onClose();
      toast.success('Terapeut uspješno dodijeljen.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom dodijeljivanja terapeuta.');
    },
  });

  const handleOnSubmit = (values) => {
    assignTherapistMutation.mutate({ clientId: parseInt(clientId, 10), therapistId: parseInt(values.therapistId, 10) });
  };

  const isLoading = employeesQuery.isLoading || assignTherapistMutation.isLoading;
  return (
    <Modal isOpen={showModal} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalHeader>Dodijeli terapeuta</ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <SpinnerCentered show={isLoading} />
        {!employeesQuery.isLoading && (
          <ModalBody>
            <Formik
              initialValues={{
                therapistId: '',
              }}
              onSubmit={(values) => handleOnSubmit(values)}
            >
              <Form id="assign-diagnosis">
                <Field name="therapistId">
                  {({ field }) => (
                    <FormControl>
                      <Select {...field} placeholder="Odaberi terapeuta" borderRadius="0" title="Odaberi terapeuta" aria-label="Odaberi dijagnozu" required>
                        {employeesQuery.data?.map(
                          (employee) =>
                            !helpers.isUserHidden({ username: employee.username, isAdminPanel: false }) && (
                              <option value={employee.id} key={employee.first_name + employee.last_name}>
                                {employee.first_name} {employee.last_name}
                              </option>
                            )
                        )}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Form>
            </Formik>
          </ModalBody>
        )}

        <ModalFooter>
          <Button disabled={isLoading} form="assign-diagnosis" colorScheme="green" size="sm" mr={3} borderRadius="0" type="submit">
            Dodaj {isLoading && <Spinner size="sm" ml="10px" />}
          </Button>
          <Button variant="ghost" size="sm" onClick={onClose}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AssignTherapistModal;
