import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, Input, Spinner, Textarea } from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import constants from '../../../../config/constants';
import api from '../../../../api';
import { toast } from 'react-toastify';
import SpinnerCentered from '../../spinner-centered/spinner-centered';

const AddClientDocumentTypeModal = (props) => {
  const { id, showModal, onClose = () => {} } = props;
  const queryClient = useQueryClient();

  const selectedClientDocumentType = useQuery([constants.QUERY_NAME.HANDLE_FETCH_CLIENT_DOCUMENT_TYPE, id], ({ queryKey }) => api.documentTypes.getClientDocumentType(queryKey[1]), { enabled: !!id });

  const addClientDocumentTypeMutation = useMutation(api.documentTypes.addClientDocumentType, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CLIENT_DOCUMENT_TYPES);
      onClose();
      toast.success('Tip dokumenta uspješno kreiran.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom kreiranja tipa dokumenta.');
    },
  });

  const updateClientDocumentTypeMutation = useMutation(api.documentTypes.updateClientDocumentType, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CLIENT_DOCUMENT_TYPES);
      onClose();
      toast.success('Tip dokumenta uspješno ažuriran.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom ažuriranja tipa dokumenta.');
    },
  });

  const handleOnSubmit = (values) => {
    if (id) {
      updateClientDocumentTypeMutation.mutate({ id, values });
    } else {
      addClientDocumentTypeMutation.mutate(values);
    }
  };

  const renderModalHeaderContent = () => (selectedClientDocumentType.data?.name ? `Uredi ${selectedClientDocumentType.data?.name}` : 'Dodaj tip dokumenta');

  const isLoading = addClientDocumentTypeMutation.isLoading || updateClientDocumentTypeMutation.isLoading;

  return (
    <Modal isOpen={showModal} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalHeader>{renderModalHeaderContent()}</ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <SpinnerCentered show={selectedClientDocumentType.isLoading} />
        {!selectedClientDocumentType.isLoading && (
          <ModalBody>
            <Formik
              initialValues={{
                name: selectedClientDocumentType.data?.name || '',
                description: selectedClientDocumentType.data?.description || '',
              }}
              onSubmit={(values) => handleOnSubmit(values)}
            >
              <Form id="add-checkup-type">
                <Field name="name">
                  {({ field }) => (
                    <FormControl>
                      <Input {...field} id="name" placeholder="Naziv tipa dokumenta" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>

                <Field name="description">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Textarea {...field} id="description" name="description" placeholder="Opis tipa dokumenta" borderRadius="0" />
                    </FormControl>
                  )}
                </Field>
              </Form>
            </Formik>
          </ModalBody>
        )}

        <ModalFooter>
          <Button disabled={isLoading} form="add-checkup-type" colorScheme="green" size="sm" mr={3} borderRadius="0" type="submit">
            Spasi {isLoading && <Spinner size="sm" ml="10px" />}
          </Button>
          <Button variant="ghost" size="sm" onClick={onClose}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddClientDocumentTypeModal;
