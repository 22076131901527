import axios from '../config/axios-config';

const login = async (data: any) => {
  return await axios({
    method: 'POST',
    url: `/user/login`,
    data,
  });
};

const generateNewTokens = async (token: string) => {
  return await axios({
    method: 'POST',
    url: `/user/refresh-token`,
    data: { refresh_token: token },
  });
};

const auth = {
  login,
  generateNewTokens,
};

export default auth;
