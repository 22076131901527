import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, Input, Spinner, Textarea } from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import constants from '../../../../config/constants';
import api from '../../../../api';
import { toast } from 'react-toastify';
import SpinnerCentered from '../../spinner-centered/spinner-centered';

const AddServiceModal = (props) => {
  const { id, showModal, onClose = () => {} } = props;
  const queryClient = useQueryClient();

  const selectedService = useQuery([constants.QUERY_NAME.HANDLE_FETCH_SERVICE, id], ({ queryKey }) => api.services.getService(queryKey[1]), { enabled: !!id });

  const addServiceMutation = useMutation(api.services.addService, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_SERVICES);
      onClose();
      toast.success('Usluga uspješno kreirana.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom kreiranja usluge.');
    },
  });
  const updateServiceMutation = useMutation(api.services.updateService, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_SERVICES);
      onClose();
      toast.success('Usluga uspješno ažurirana.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom ažuriranja usluge.');
    },
  });

  const handleOnSubmit = (values) => {
    if (id) {
      updateServiceMutation.mutate({ id, values });
    } else {
      addServiceMutation.mutate(values);
    }
  };

  const renderModalHeaderContent = () => (selectedService.data?.name ? `Uredi ${selectedService.data?.name}` : 'Dodaj uslugu');

  const isLoading = addServiceMutation.isLoading || updateServiceMutation.isLoading;

  return (
    <Modal isOpen={showModal} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalHeader>{renderModalHeaderContent()}</ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <SpinnerCentered show={selectedService.isLoading} />
        {!selectedService.isLoading && (
          <ModalBody>
            <Formik
              initialValues={{
                name: selectedService.data?.name ||'',
                serviceId: selectedService.data?.service_id ||'',
                description: selectedService.data?.description ||'',
              }}
              onSubmit={(values) => handleOnSubmit(values)}
            >
              <Form id="add-center">
                <Field name="name">
                  {({ field }) => (
                    <FormControl>
                      <Input {...field} id="name" placeholder="Naziv usluge" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>

                <Field name="serviceId">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Input {...field} id="serviceId" placeholder="Šifra usluge" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>

                <Field name="description">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Textarea {...field} id="description" name="description" placeholder="Opis usluge" borderRadius="0" />
                    </FormControl>
                  )}
                </Field>
              </Form>
            </Formik>
          </ModalBody>
        )}

        <ModalFooter>
          <Button disabled={isLoading} form="add-center" colorScheme="green" size="sm" mr={3} borderRadius="0" type="submit">
            Spasi {isLoading && <Spinner size="sm" ml="10px" />}
          </Button>
          <Button variant="ghost" size="sm" onClick={onClose}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddServiceModal;
