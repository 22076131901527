import constants from '../../config/constants';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

const getUniqueObjectsFromArray = (arr, key) => {
  const unique = [];
  const distinct = [];
  for (let i = 0; i < arr.length; i++) {
    if (!unique[arr[i][key]]) {
      distinct.push(arr[i]);
      unique[arr[i][key]] = 1;
    }
  }
  return distinct;
};

const hashPassword = (password) => {
  return sha256(constants.MAGIC_SALT + password).toString(Base64);
};

const isUserHidden = (props) => {
  const { username, isAdminPanel } = props;
  const hide = constants.HIDDEN_USERS.includes(username.toLowerCase()) && !isAdminPanel;
  return hide;
};

export const helpers = {
  getUniqueObjectsFromArray,
  hashPassword,
  isUserHidden,
};
