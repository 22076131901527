import { QueryClient } from 'react-query';

// Query Client Config
const oneDayInMs = 86400000;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      retry: 3,
      staleTime: oneDayInMs,
      cacheTime: 0,
      refetchInterval: 0,
    },
    mutations: {
      // retry: 3,
      retryDelay: 1000,
    },
  },
});

export default queryClient;
