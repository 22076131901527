import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  Spinner,
  Switch,
  Select,
  Text,
  Divider,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
} from '@chakra-ui/react';

import { Formik, Field, Form } from 'formik';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useState } from 'react';

import api from '../../../../api';

import constants from '../../../../config/constants';

const EditEmployeeModal = (props) => {
  const { showModal, id, onClose = () => {} } = props;
  const queryClient = useQueryClient();
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedTherapistType, setSelectedTherapistType] = useState(null);

  // Mutations
  const updateEmployeeMutation = useMutation(api.employees.updateEmployee, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES);
      onClose();
      toast.success('Podaci uspješno izmjenjeni.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom izmjene podataka.');
    },
  });

  const assignRoleMutation = useMutation(api.employees.assignRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEE);
      toast.success('Uloga uspješno dodana.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom dodjeljivanja uloge.');
    },
  });

  const unassignRoleMutation = useMutation(api.employees.unassignRole, {
    onSuccess: () => {
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEE);
      toast.success('Uloga uspješno uklonjena.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom uklanjanja uloge.');
    },
  });

  const assignTherapistTypeMutation = useMutation(api.employees.assignWorkingPosition, {
    onSuccess: () => {
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEE);
      toast.success('Tip terapeuta uspješno dodan.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom dodavanja tipa terapeuta.');
    },
  });

  const unassignTherapistTypeMutation = useMutation(api.employees.unassignWorkingPosition, {
    onSuccess: () => {
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEE);
      toast.success('Tip terapeuta uspješno uklonjen.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom uklanjanja tipa terapeuta.');
    },
  });

  // Queries
  const rolesQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_ROLES, api.roles.getRoles);
  const centersQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_WORK_SCHEDULES, api.centers.getCenters);
  const workingPositionsQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_WORKING_POSITIONS, api.workingPositions.getPositions);
  const employeeQuery = useQuery([constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEE, id], ({ queryKey }) => api.employees.getEmployee(queryKey[1]), { enabled: !!id });
  const submitEmployee = (values) => {
    updateEmployeeMutation.mutate({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      centerId: values.center,
      phone: values.phone,
      active: values.active,
      roleId: values.userRole,
      positionId: values.therapistType,
      id: employeeQuery.data?.id,
    });
  };

  const handleAssignRole = (roleId) => {
    assignRoleMutation.mutate({ roleId: parseInt(roleId, 10), employeeId: id });
  };

  const handleUnassignRole = (roleId) => {
    unassignRoleMutation.mutate({ roleId: parseInt(roleId, 10), employeeId: id });
  };

  const handleAssignTherapistType = (roleId) => {
    assignTherapistTypeMutation.mutate({ positionId: parseInt(roleId, 10), employeeId: id });
  };
  const handleUnassignTherapistType = (roleId) => {
    unassignTherapistTypeMutation.mutate({ positionId: parseInt(roleId, 10), employeeId: id });
  };

  const renderRolesAssigning = () => {
    return (
      <>
        <Text fontSize="15px">Tip korisnika:</Text>
        <HStack my="15px">
          <FormControl>
            <Select onChange={(e) => setSelectedRole(e.target.value)} placeholder="Tip korisnika" borderRadius="0" title="Tip korisnika" aria-label="Tip korisnika">
              {rolesQuery.data?.map((role) => (
                <option value={role.id} key={role.name}>
                  {role.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <Button borderRadius="0" onClick={() => handleAssignRole(selectedRole)}>
            Dodaj
          </Button>
        </HStack>
        <HStack spacing={4} my="15px">
          {employeeQuery.data?.roles.map((role, index) => (
            <Tag size="md" key={role.id + index} borderRadius="full" variant="solid" colorScheme="pink">
              <TagLabel>{role.name}</TagLabel>
              <TagCloseButton onClick={() => handleUnassignRole(role.id)} />
            </Tag>
          ))}
        </HStack>
      </>
    );
  };

  const renderWorkingPositionsAssigning = () => {
    return (
      <>
        <Text fontSize="15px">Tip terapeuta:</Text>
        <HStack my="15px">
          <FormControl>
            <Select onChange={(e) => setSelectedTherapistType(e.target.value)} placeholder="Tip terapeuta" borderRadius="0" title="Tip korisnika" aria-label="Tip korisnika">
              {workingPositionsQuery.data?.map((position) => (
                <option value={position.id} key={position.name}>
                  {position.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <Button borderRadius="0" onClick={() => handleAssignTherapistType(selectedTherapistType)}>
            Dodaj
          </Button>
        </HStack>
        <HStack spacing={4} my="15px">
          {employeeQuery.data?.working_positions?.map((position, index) => (
            <Tag size="md" key={position.id + index} borderRadius="full" variant="solid" colorScheme="pink">
              <TagLabel>{position.name}</TagLabel>
              <TagCloseButton onClick={() => handleUnassignTherapistType(position.id)} />
            </Tag>
          ))}
        </HStack>
      </>
    );
  };

  return (
    <Modal isOpen={showModal} onClose={() => onClose()}>
      <ModalOverlay />
      {employeeQuery.isLoading ? (
        <Spinner />
      ) : (
        <ModalContent borderRadius="0">
          <ModalHeader>Uredi uposlenika: {employeeQuery.data?.first_name}</ModalHeader>
          <ModalCloseButton borderRadius="0" />
          <ModalBody>
            <Text fontSize="15px">Korisničko ime:</Text>
            <FormControl>
              <Input disabled value={employeeQuery.data?.username} id="username" placeholder="Korisnicko ime" borderRadius="0" required />
            </FormControl>

            <Formik
              initialValues={{
                firstName: employeeQuery.data?.first_name,
                lastName: employeeQuery.data?.last_name,
                phone: employeeQuery.data?.phone,
                email: employeeQuery.data?.email,
                center: employeeQuery.data?.center_id,
                active: employeeQuery.data?.active,
              }}
              onSubmit={(values) => {
                submitEmployee(values);
              }}
            >
              <Form id="add-employee">
                <Divider my="15px" />

                <Field name="firstName">
                  {({ field }) => (
                    <FormControl>
                      <Input {...field} id="firstName" placeholder="Ime" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>

                <Field name="lastName">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Input {...field} id="lastName" name="lastName" placeholder="Prezime" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>

                <Field name="phone">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Input {...field} id="phone" name="phone" placeholder="Telefon" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>

                <Field name="email">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Input {...field} id="email" name="email" placeholder="Email" type="email" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>

                <Field name="center">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Select {...field} placeholder="Poslovna jedinica" borderRadius="0" title="Poslovna jedinica" aria-label="Poslovna jedinica" required>
                        {centersQuery.data?.map((center) => (
                          <option value={center.id} key={center.name}>
                            {center.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>

                <Divider my="15px" />

                {renderRolesAssigning()}

                <Divider my="15px" />

                {renderWorkingPositionsAssigning()}

                <Divider my="15px" />

                <Field name="active">
                  {({ field }) => (
                    <FormControl display="flex" alignItems="center" justifyContent="center" mt="10px">
                      <label htmlFor="active" mb="0">
                        Aktivan:
                      </label>
                      <Switch {...field} id="active" colorScheme="pink" defaultChecked={employeeQuery.data?.active} ml="10px" />
                    </FormControl>
                  )}
                </Field>
              </Form>
            </Formik>
          </ModalBody>

          <ModalFooter>
            <Button disabled={updateEmployeeMutation.isLoading} form="add-employee" colorScheme="green" size="sm" mr={3} borderRadius="0" type="submit">
              Spasi {updateEmployeeMutation.isLoading && <Spinner size="sm" ml="10px" />}
            </Button>
            <Button variant="ghost" size="sm" onClick={() => onClose()}>
              Zatvori
            </Button>
          </ModalFooter>
        </ModalContent>
      )}
    </Modal>
  );
};

export default EditEmployeeModal;
