import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, AlertDialogCloseButton, Button, } from '@chakra-ui/react';

const ConfirmDialog = (props) => {
  const { message, isOpen, onClose = () => {}, onConfirm = () => {} } = props;
  return (
    <AlertDialog motionPreset="slideInBottom" onClose={onClose} isOpen={isOpen}>
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>Da li ste sigurni?</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{message}</AlertDialogBody>
        <AlertDialogFooter>
          <Button colorScheme="red" mr={3} onClick={() => onConfirm()}>
            Da, potvrdi
          </Button>
          <Button onClick={onClose}>Ne, otkaži</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmDialog;
