import { useState } from 'react';
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, Flex, Button } from '@chakra-ui/react';

import AddEditCenterModal from '../../modals/add-edit-center/add-edit-center-modal';

import { useQuery } from 'react-query';

import api from '../../../../api';
import constants from '../../../../config/constants';

import './centers-widget.scss';
import SpinnerCentered from '../../spinner-centered/spinner-centered';

const Centers = (props) => {
  const { showActions = false } = props;

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [selectedCenterId, setSelectedCenterId] = useState(null);
  const centers = useQuery(constants.QUERY_NAME.HANDLE_FETCH_CENTERS, api.centers.getCenters);
  const locations = useQuery(constants.QUERY_NAME.HANDLE_FETCH_LOCATIONS, api.locations.getLocations);

  const columns = [
    {
      title: 'Poslovna Jedinica',
    },
    {
      title: 'Lokacija',
    },
    {
      title: 'Adresa',
    },
  ];

  const handleToggleAddEditModal = (data) => {
    const { id, showModal } = data;
    setSelectedCenterId(id);
    setShowAddEditModal(showModal);
  };

  const getLocationName = (id) => {
    const selectedLocation = locations.data?.find((location) => location.id === id);
    return selectedLocation?.name || '';
  };

  return (
    <div className="centers-widget">
      <div className="pa-widget__title-area">
        <h3 className="pa-widget-title">Poslovne jedinice</h3>
        <Flex>
          <Button variant="outline" size="sm" ml="10px" onClick={() => handleToggleAddEditModal({ id: null, showModal: true })}>
            <span className="material-icons">domain_add</span>
          </Button>
        </Flex>
      </div>
      <div className="pa-widget__content-area">
        <Table size="sm" variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {columns.map((column, index) => (
                <Th key={column.title + index}>{column.title}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {centers.data?.map((center, index) => {
              return (
                <Tr key={(center.name, index)}>
                  <Td>{center.name}</Td>
                  <Td>{getLocationName(center.location_id)}</Td>
                  <Td>{center.address}</Td>
                  {showActions && (
                    <Td isNumeric>
                      <Button
                        size="xs"
                        colorScheme="yellow"
                        variant="ghost"
                        onClick={() => {
                          handleToggleAddEditModal({ showModal: true, id: center.id });
                        }}
                      >
                        Uredi
                      </Button>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
          <Tfoot></Tfoot>
        </Table>
        <SpinnerCentered show={centers.isLoading} />
        <AddEditCenterModal id={selectedCenterId} showModal={showAddEditModal} onClose={() => handleToggleAddEditModal({ id: null, showModal: false })} />
      </div>
    </div>
  );
};

export default Centers;
