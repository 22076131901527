import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { useTitle } from '../helpers/hooks/useTitle';

import state from '../state';
import PAEANRoutes from './routes';

const PublicRoute = ({ loginGuard, pageTitle, children }) => {
  const { t } = useTranslation();
  useTitle(t(pageTitle));

  const isLoggedIn = state.useAuth((state) => state.isLoggedIn);

  if (isLoggedIn && loginGuard) return <Navigate to={PAEANRoutes.HOME} replace />;

  return children;
};

export default PublicRoute;
