import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, Spinner, Textarea, Select } from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import constants from '../../../../config/constants';
import api from '../../../../api';
import { toast } from 'react-toastify';
import SpinnerCentered from '../../spinner-centered/spinner-centered';

const AddCheckupModal = (props) => {
  const { checkupId, clientId, showModal, onClose = () => {} } = props;
  const queryClient = useQueryClient();

  const selectedCheckup = useQuery([constants.QUERY_NAME.HANDLE_FETCH_CHECKUP, checkupId], ({ queryKey }) => api.checkups.getCheckup(queryKey[1]), { enabled: !!checkupId });

  const checkupTypes = useQuery(constants.QUERY_NAME.HANDLE_FETCH_CHECKUP_TYPES, api.checkupTypes.getCheckupTypes);

  const addCheckupTypeMutation = useMutation(api.checkups.addCheckup, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CLIENT);
      onClose();
      toast.success('Terapija uspješno kreirana.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom kreiranja terapije.');
    },
  });

  const updateCheckupTypeMutation = useMutation(api.checkups.updateCheckup, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CLIENT);
      onClose();
      toast.success('Terapija uspješno ažurirana.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom ažuriranja terapije.');
    },
  });

  const handleOnSubmit = (values) => {
    const data = { checkupType: parseInt(values.checkupType), clientId: parseInt(clientId, 10), description: values.description };
    if (checkupId) {
      updateCheckupTypeMutation.mutate({ id: checkupId, values: data });
    } else {
      addCheckupTypeMutation.mutate(data);
    }
  };

  const renderModalHeaderContent = () => (selectedCheckup.data?.name ? `Uredi ${selectedCheckup.data?.name}` : 'Dodaj terapiju');

  const isLoading = addCheckupTypeMutation.isLoading || updateCheckupTypeMutation.isLoading;

  return (
    <Modal isOpen={showModal} closeOnOverlayClick={false} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalHeader>{renderModalHeaderContent()}</ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <SpinnerCentered show={selectedCheckup.isLoading} />
        {!selectedCheckup.isLoading && (
          <ModalBody>
            <Formik
              initialValues={{
                checkupType: selectedCheckup.data?.checkup_type || '',
                description: selectedCheckup.data?.description || '',
              }}
              onSubmit={(values) => handleOnSubmit(values)}
            >
              <Form id="add-checkup-type">
                <Field name="checkupType">
                  {({ field }) => (
                    <FormControl>
                      <Select {...field} placeholder="Vrsta terapije" borderRadius="0" title="Vrsta terapije" aria-label="Vrsta terapije" required>
                        {checkupTypes?.data?.map((checkupType) => (
                          <option key={checkupType.id + checkupType.name} value={checkupType.id}>
                            {checkupType.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>

                <Field name="description">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Textarea {...field} id="description" name="description" placeholder="Detaljne informacije o terapiji" borderRadius="0" rows="15" />
                    </FormControl>
                  )}
                </Field>
              </Form>
            </Formik>
          </ModalBody>
        )}

        <ModalFooter>
          <Button disabled={isLoading} form="add-checkup-type" colorScheme="green" size="sm" mr={3} borderRadius="0" type="submit">
            Spasi {isLoading && <Spinner size="sm" ml="10px" />}
          </Button>
          <Button variant="ghost" size="sm" onClick={onClose}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddCheckupModal;
