import { Button, Checkbox, Flex, Input, Link, Select, Spinner, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';

import api from '../../../../api';
import state from '../../../../core/state';
import constants from '../../../../config/constants';

import WorkScheduleModal from '../../modals/view-work-schedule/work-schedule-modal';
import { toast } from 'react-toastify';
import AddWorkScheduleModal from '../../modals/add-work-schedule/add-work-schedule-modal';
import ConfirmDialog from '../../confirm-dialog/confirm-dialog';

const WorkSchedulesWidget = (props) => {
  const { showHeader, showTitle, searchBarWidth, showActions, isSpecificUser, showOwn } = props;
  const queryClient = useQueryClient();

  const [scheduleId, setScheduleId] = useState(null);
  const [showViewScheduleModal, setShowViewScheduleModal] = useState(false);
  const [showAddEditScheduleModal, setShowAddEditScheduleModal] = useState(false);
  const [filteredResults, setFilteredResults] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteDialogMessage, setDeleteDialogMessage] = useState('');

  const userInfo = state.currentUser((state) => state.userInfo);

  // Add Edit schedule toggler
  const handleToggleAddEditScheduleModal = (data) => {
    const { id, show } = data;
    setScheduleId(id);
    setShowAddEditScheduleModal(show);
  };

  // View Schedule Toggler
  const handleToggleViewScheduleModal = (data) => {
    const { id, show } = data;
    setScheduleId(id);
    setShowViewScheduleModal(show);
  };

  const columns = [
    {
      title: 'Termin',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Datum dodavanja',
      dataIndex: 'date',
      key: 'phone',
    },
    {
      title: 'Predviđeni datum završetka',
      dataIndex: 'date',
      key: 'phone',
    },
    {
      title: 'Status',
      dataIndex: 'date',
      key: 'phone',
    },
  ];

  const workSchedulesQuery = useQuery([constants.QUERY_NAME.HANDLE_FETCH_WORK_SCHEDULES, { showOwn: showOwn }], ({ queryKey }) => api.workSchedules.getSchedules(queryKey[1]));

  const changeWSStatusMutation = useMutation(api.workSchedules.changeStatus, {
    onError: () => {
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_WORK_SCHEDULES);
      toast.error('Došlo je do greške prilikom promjene statusa!');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_WORK_SCHEDULES);
      toast.success('Status uspješno promjenjen!');
    },
  });

  const deleteScheduleMutation = useMutation(api.workSchedules.deleteSchedule, {
    onError: () => {
      toast.error('Došlo je do greške prilikom brisanja obaveze!');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_WORK_SCHEDULES);
      toast.success('Obaveza uspješno obrisana!');
      setScheduleId(null);
      setShowDeleteDialog(false);
      setDeleteDialogMessage('');
    },
  });

  const handleToggleDeleteDialog = (data) => {
    const { id, show, name } = data;
    setScheduleId(id);
    setShowDeleteDialog(show);
    setDeleteDialogMessage(`Da li ste sigurni da želite obrisati ${name}`);
  };

  useEffect(() => {
    setFilteredResults(workSchedulesQuery.data);
  }, [workSchedulesQuery.data]);

  const searchEmployees = (input) => {
    const value = input.target.value;
    if (workSchedulesQuery.data?.length && value.length >= 3) {
      const searchRes = workSchedulesQuery.data?.filter((document) => document.name.toLowerCase().includes(value.toLowerCase()));
      setFilteredResults(searchRes);
    } else {
      setFilteredResults(workSchedulesQuery.data);
    }
  };

  const filterByType = (input) => {
    const value = input.target.value;
    if (value === 'Svi statusi') {
      setFilteredResults(workSchedulesQuery.data);
    } else {
      const searchRes = workSchedulesQuery.data?.filter((date) => value.includes(date.completed));

      setFilteredResults(searchRes);
    }
  };

  const renderSpinner = () => {
    const showSpinner = workSchedulesQuery.isFetching;
    return (
      showSpinner && (
        <Flex my="15px" align="center" justify="center">
          <Spinner hickness="3px" speed="0.5s" emptyColor="gray.200" color="#2fb344" size="md" label="Ucitavanje" />
        </Flex>
      )
    );
  };

  const renderHeader = () => {
    return (
      showHeader && (
        <div className="pa-widget__title-area">
          <div className="pa-widget-title">{showTitle && <h3>Termini</h3>}</div>
          <Input placeholder="Pretraga" onChange={searchEmployees} borderRadius="0" type="search" size="sm" width={searchBarWidth} />
          <Flex>
            <Select borderRadius="0" size="sm" width="180px" onChange={filterByType}>
              <option value="Svi statusi">Svi statusi</option>
              <option value={true}>Završeno</option>
              <option value={false}>Nezavršeno</option>
            </Select>
            <Button variant="outline" size="sm" ml="10px" onClick={() => handleToggleAddEditScheduleModal({ id: null, show: true })}>
              <span className="material-icons">alarm_add</span>
            </Button>
          </Flex>
        </div>
      )
    );
  };

  const renderTable = () => {
    const canUserUpdate = (schedule) => {
      const createdBy = schedule?.employees?.[0]?.id;

      if (createdBy) {
        const isOwner = userInfo.id === createdBy;

        const isAdmin = userInfo.roles.includes('Admin');

        return isOwner || isAdmin || changeWSStatusMutation.isLoading;
      } else {
        return true;
      }
    };
    return (
      <Table size="sm" variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            {columns.map((column, index) => (
              <Th textAlign="center" key={column.title + index}>{column.title}</Th>
            ))}
            {!isSpecificUser && <Th>Uposlenik</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {filteredResults && filteredResults.length ? (
            filteredResults.map((date) => {
              return (
                <Tr key={date.id + date.created_at}>
                  <Td>
                    {canUserUpdate(date) ? (
                      <Link color="#EB268F" rel="noreferrer noopener" onClick={() => handleToggleViewScheduleModal({ id: date.id, show: true })}>
                        {date.name}
                      </Link>
                    ) : (
                      date.name
                    )}
                  </Td>
                  <Td textAlign="center">{moment(date.created_at).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)}</Td>
                  <Td textAlign="center">{moment(date.expiring_at).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)}</Td>
                  <Td textAlign="center">
                    <Checkbox
                      size="sm"
                      isDisabled={!canUserUpdate(date)}
                      defaultIsChecked={date.completed}
                      colorScheme="pink"
                      onChange={() => changeWSStatusMutation.mutate({ id: date.id, completed: !date.completed })}
                    />
                  </Td>
                  {!isSpecificUser && <Td>{`${date.employees?.[0]?.first_name} ${date?.employees?.[0]?.last_name}`}</Td>}
                  {showActions && (
                    <Td isNumeric>
                      <Button
                        onClick={() => {
                          handleToggleAddEditScheduleModal({ id: date.id, show: true });
                        }}
                        size="xs"
                        colorScheme="yellow"
                        variant="ghost"
                      >
                        Uredi
                      </Button>
                      <Button ml="5px" size="xs" colorScheme="red" variant="ghost" onClick={() => handleToggleDeleteDialog({ id: date.id, name: date.name, show: true })}>
                        Obriši
                      </Button>
                    </Td>
                  )}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td textAlign="center" colSpan={showActions ? 6 : 5}>
                Nema rezultata
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    );
  };

  return (
    <div className="documents-widget">
      {renderHeader()}
      <div className="pa-widget__content-area">
        {renderSpinner()}
        {renderTable()}
      </div>
      <WorkScheduleModal scheduleId={scheduleId} showModal={showViewScheduleModal} toggleShowModal={() => handleToggleViewScheduleModal({ id: null, show: false })} />
      <AddWorkScheduleModal id={scheduleId} showModal={showAddEditScheduleModal} toggleModal={() => handleToggleAddEditScheduleModal({ id: null, show: false })} />
      <ConfirmDialog
        isOpen={showDeleteDialog}
        message={deleteDialogMessage}
        onClose={() => handleToggleDeleteDialog({ name: null, id: null, show: false })}
        onConfirm={() => deleteScheduleMutation.mutate(scheduleId)}
      />
    </div>
  );
};

export default WorkSchedulesWidget;
