import axios from '../config/axios-config';

const getCenters = async (data: any) => {
  const response = await axios({
    method: 'GET',
    url: `/centers`,
    data,
  });
  return response.data || [];
};
const getCenter = async (id: any) => {
  const response = await axios({
    method: 'GET',
    url: `/centers/${id}`,
  });
  return response.data || [];
};
const addCenter = async (data: any) => {
  const response = await axios({
    method: 'POST',
    url: `/centers/`,
    data,
  });
  return response.data || [];
};

const updateCenter = async (data: any) => {
  const response = await axios({
    method: 'PUT',
    url: `/centers/${data?.id}`,
    data: data?.values,
  });
  return response.data || [];
};

const positions = {
  getCenters,
  getCenter,
  addCenter,
  updateCenter,
};

export default positions;
