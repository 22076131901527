import { Heading, Text } from '@chakra-ui/react';
import DocumentsWidget from '../shared/widgets/documents/documents-widget';

const Documents = () => {
  return (
    <div className="documents">
      <Heading textAlign="center">Dokumenti</Heading>
      <Text>Lista dokumenata</Text>
      <DocumentsWidget showHeader showActions />
    </div>
  );
};

export default Documents;
