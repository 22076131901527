import employees from './employees-api';
import auth from './auth-api';
import workingPositions from './working-positions-api';
import centers from './centers-api';
import workSchedules from './work-schedules-api';
import roles from './roles-api';
import locations from './locations-api';
import services from './services-api';
import clients from './clients-api';
import entryDiagnostics from './entry-diagnostics-api';
import diagnosis from './diagnosis-api';
import checkupTypes from './checkup-types-api';
import checkups from './checkups-api';
import documentTypes from './document-types-api';
import documents from './documents-api';
import statistics from './statistics-api';

const api = {
  employees,
  auth,
  workingPositions,
  centers,
  workSchedules,
  roles,
  locations,
  services,
  clients,
  entryDiagnostics,
  diagnosis,
  checkupTypes,
  checkups,
  documentTypes,
  documents,
  statistics,
};

export default api;
