import { useEffect } from 'react';
import { QueryClientProvider } from 'react-query';
import { ChakraProvider } from '@chakra-ui/react';

import constants from '../config/constants';

import state from './state';
// import themes from './styles/themes';

// import { useTheme } from './helpers/hooks/useTheme';
import { useWindowSize } from './helpers/hooks/useWindowSize';

import Layout from './layout/layout';
import RouteRender from './routes/route-render';

import { ToastContainer } from 'react-toastify';

import queryClient from '../config/query-client-config';

import 'react-toastify/dist/ReactToastify.css';
import './styles/app.scss';

const App = () => {
  // const isDarkMode = state.useDarkMode((state) => state.isDarkMode);
  const setIsMobile = state.isMobileScreen((state) => state.setIsMobile);

  // Sets color theme according to state.
  // useTheme(isDarkMode ? themes.dark : themes.light);

  /* Watching for windwos width changes and checking wether the screen resolution is mobile or desktop 
  and update the Zustand state accordingly. */
  const [width] = useWindowSize();
  useEffect(() => {
    const isMobileResMatch = width <= constants.MAX_MOBILE_WIDTH;
    setIsMobile(isMobileResMatch);
  }, [width, setIsMobile]);

  const renderRoutes = () => {
    return <RouteRender />;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <Layout>
        <ChakraProvider>{renderRoutes()}</ChakraProvider>
        <ToastContainer />
      </Layout>
    </QueryClientProvider>
  );
};

export default App;
