import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box } from '@chakra-ui/react';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DatesPerCenter = (props) => {
  const { centers } = props;
  if (!centers) return null;

  const labels = centers.map((center) => center.name);
  const stats = centers.map((center) => center.total_checkups);
  const data = {
    labels,
    datasets: [
      {
        label: 'Broj tretmana',
        data: stats,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };
  return (
    <Box {...props}>
      <Line options={options} data={data} />
    </Box>
  );
};

export default DatesPerCenter;
