import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, Spinner, Select } from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import constants from '../../../../config/constants';
import api from '../../../../api';
import { toast } from 'react-toastify';
import SpinnerCentered from '../../spinner-centered/spinner-centered';

const AssignDiagnoseModal = (props) => {
  const { clientId, showModal, onClose = () => {} } = props;
  const queryClient = useQueryClient();

  const diagnosis = useQuery(constants.QUERY_NAME.HANDLE_FETCH_DIAGNOSIS, api.diagnosis.getDiagnosis);

  const assignDiagnosisMutation = useMutation(api.clients.assignDiagnose, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CLIENT);
      onClose();
      toast.success('Dijagnoza uspješno dodijeljena.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom dodijeljivanja dijagnoze.');
    },
  });

  const handleOnSubmit = (values) => {
    assignDiagnosisMutation.mutate({ clientId: parseInt(clientId, 10), diagnose: parseInt(values.diagnose, 10) });
  };

  const isLoading = diagnosis.isLoading || assignDiagnosisMutation.isLoading;

  return (
    <Modal isOpen={showModal} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalHeader>Dodijeli dijagnozu</ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <SpinnerCentered show={isLoading} />
        {!diagnosis.isLoading && (
          <ModalBody>
            <Formik
              initialValues={{
                diagnose: '',
              }}
              onSubmit={(values) => handleOnSubmit(values)}
            >
              <Form id="assign-diagnosis">
                <Field name="diagnose">
                  {({ field }) => (
                    <FormControl>
                      <Select {...field} placeholder="Odaberi dijagnozu" borderRadius="0" title="Odaberi dijagnozu" aria-label="Odaberi dijagnozu" required>
                        {diagnosis.data?.map((diagnose) => (
                          <option value={diagnose.id} key={diagnose.name}>
                            {diagnose.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
              </Form>
            </Formik>
          </ModalBody>
        )}

        <ModalFooter>
          <Button disabled={isLoading} form="assign-diagnosis" colorScheme="green" size="sm" mr={3} borderRadius="0" type="submit">
            Dodaj {isLoading && <Spinner size="sm" ml="10px" />}
          </Button>
          <Button variant="ghost" size="sm" onClick={onClose}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AssignDiagnoseModal;
