import { useState, useEffect } from 'react';

/* 
This useWindowSize hook watches for window screen size changes and outputs result (width and height);
There is also implemented method to debounce between window screen change to avoit performance issues such as
rerendering of components of every pixel move etc. 
*/

export const useWindowSize = () => {
  const [dimension, setDimension] = useState([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      setDimension([window.innerWidth, window.innerHeight]);
    }, 100);
    window.addEventListener('resize', debouncedResizeHandler);
    return () => window.removeEventListener('resize', debouncedResizeHandler);
  }, []);
  return dimension;
};

const debounce = (fn, ms) => {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this);
    }, ms);
  };
};
