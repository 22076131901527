import { Button, Flex, Input, Link, Select, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import api from '../../../../api';
import constants from '../../../../config/constants';
import state from '../../../../core/state';

import AddClientDocumentModal from '../../modals/add-client-document/add-client-document-modal';

import './client-documents-widget.scss';

const ClientDocumentsWidget = (props) => {
  const { showHeader, showActions, showTitle, searchBarWidth = '60%', clientId, documents } = props;
  const currentUser = state.currentUser((state) => state.userInfo);

  const [filteredResults, setFilteredResults] = useState([]);
  const [showAddEditModal, setShowAddEditModal] = useState(false);

  const employeesQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES, api.employees.getEmployees);
  const documentTypes = useQuery(constants.QUERY_NAME.HANDLE_FETCH_CLIENT_DOCUMENT_TYPES, api.documentTypes.getClientDocumentTypes);

  const documentsDownloadMutation = useMutation(api.documents.downloadClientDocument, {
    onSuccess: (fileData, variables) => {
      if (!(fileData.file instanceof Blob)) return;
      toast.success('Dokument uspješno preuzet.');
      const file = new Blob([fileData.file], { type: fileData.contentType });
      const fileURL = window.URL.createObjectURL(file);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', variables);
      tempLink.click();
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom preuzimanja dokumenta.');
    },
  });

  const handleToggleAddEditModal = (data) => {
    const { showModal } = data;
    setShowAddEditModal(showModal);
  };

  const columns = [
    {
      title: 'Dokument',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tip dokumenta',
      dataIndex: 'date',
      key: 'phone',
    },
    {
      title: 'Datum dodavanja',
      dataIndex: 'date',
      key: 'phone',
    },
    {
      title: 'Dodao',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: <span className="material-icons">visibility</span>,
      dataIndex: 'visibility',
      key: 'visibility',
    },
  ];

  useEffect(() => {
    setFilteredResults(documents);
  }, [documents]);

  const searchEmployees = (input) => {
    const value = input.target.value;
    if (documents.length && value.length >= 3) {
      const searchRes = documents.filter((document) => document.name.toLowerCase().includes(value.toLowerCase()));
      setFilteredResults(searchRes);
    } else {
      setFilteredResults(documents);
    }
  };

  const filterByType = (input) => {
    const value = input.target.value;
    if (value === 'Svi tipovi') {
      setFilteredResults(documents);
    } else {
      const searchRes = documents.filter((document) => document?.document_type === parseInt(value, 10));
      setFilteredResults(searchRes);
    }
  };

  const getEmployeeName = (id) => {
    const selectedEmployee = employeesQuery?.data?.find((employee) => employee.id === id);
    return `${selectedEmployee?.first_name} ${selectedEmployee?.last_name}` || null;
  };

  const getDocumentType = (id) => {
    const documentType = documentTypes?.data?.find((employee) => employee.id === id);
    return documentType?.name || null;
  };

  const downloadDocument = (id) => {
    documentsDownloadMutation.mutate(id);
  };

  const canDownloadDocument = (canAccessRoles) => {
    if (!canAccessRoles.length) return true;

    const r = (el) => currentUser?.workingPositions?.includes(el.name);
    const canAccess = canAccessRoles.some(r);
    return canAccess;
  };
  return (
    <div className="documents-widget">
      {showHeader && (
        <div className="pa-widget__title-area">
          <div className="pa-widget-title">{showTitle && <h3>Dokumenti</h3>}</div>
          <Input placeholder="Pretraga" onChange={searchEmployees} borderRadius="0" type="search" size="sm" width={searchBarWidth} />
          <Flex>
            <Select borderRadius="0" size="sm" width="180px" onChange={filterByType}>
              <option value="Svi tipovi">Svi tipovi</option>
              {documentTypes?.data?.map((documentType, index) => (
                <option key={documentType.name + index} value={documentType.id}>
                  {documentType.name}
                </option>
              ))}
            </Select>
            <Button variant="outline" size="sm" ml="10px" onClick={() => handleToggleAddEditModal({ showModal: true })}>
              <span className="material-icons">note_add</span>
            </Button>
          </Flex>
        </div>
      )}
      <div className="pa-widget__content-area">
        <Table size="sm" variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              {columns.map((column, index) => (
                <Th key={column.title + index}>{column.title}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {filteredResults && filteredResults.length ? (
              filteredResults.map((document, index) => {
                return (
                  <Tr key={document.name + index}>
                    <Td>
                      {canDownloadDocument(document.allowed_employee_positions) || !document.protected ? (
                        <Link color="#EB268F" onClick={() => downloadDocument(document.file_name)}>
                          {document.name}
                        </Link>
                      ) : (
                        <span>{document.name}</span>
                      )}
                    </Td>
                    <Td>{getDocumentType(document.document_type)}</Td>
                    <Td>{document.upload_date && moment(document.upload_date).format(constants.MOMENT_FORMAT.DATE_TIME_DOT_SEPARATED)}</Td>
                    <Td>{getEmployeeName(document.uploaded_by)}</Td>
                    <Td>{document.protected && <span className="material-icons">lock</span>}</Td>
                    {showActions && (
                      <Td isNumeric>
                        <Button size="xs" colorScheme="yellow" variant="ghost">
                          Uredi
                        </Button>
                        <Button ml="5px" size="xs" colorScheme="red" variant="ghost">
                          Obriši
                        </Button>
                      </Td>
                    )}
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td textAlign="center" colSpan={showActions ? 6 : 5}>
                  Nema rezultata
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
        {/* <Flex my="15px" align="center" justify="center">
          <Spinner thickness="3px" speed="0.5s" emptyColor="gray.200" color="#2fb344" size="md" label="Ucitavanje" />
        </Flex> */}
      </div>
      <AddClientDocumentModal clientId={clientId} showModal={showAddEditModal} onClose={() => handleToggleAddEditModal({ showModal: false })} />
    </div>
  );
};

export default ClientDocumentsWidget;
