import axios from '../config/axios-config';

const getCheckups = async () => {
  const response = await axios({
    method: 'GET',
    url: `/checkups`,
  });
  return response.data || [];
};

const getCheckup = async (id: number) => {
  const response = await axios({
    method: 'GET',
    url: `/checkups/${id}`,
  });
  return response.data || [];
};

const addCheckup = async (data: number) => {
  const response = await axios({
    method: 'POST',
    url: `/checkups`,
    data,
  });
  return response.data || [];
};

const updateCheckup = async (data: { values: object; id: number }) => {
  const response = await axios({
    method: 'PUT',
    url: `/checkups/${data.id}`,
    data: data.values,
  });
  return response.data || [];
};

const diagnosis = {
  getCheckups,
  getCheckup,
  addCheckup,
  updateCheckup,
};

export default diagnosis;
