import { Heading, Flex } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import api from '../../../api';
import constants from '../../../config/constants';

import state from '../../../core/state';

ChartJS.register(ArcElement, Tooltip, Legend);

const UserHomeBox = () => {
  const date = moment().format('DD. MMM. YYYY');
  const userInfo = state.currentUser((state) => state.userInfo);
  const workSchedulesQuery = useQuery([constants.QUERY_NAME.HANDLE_FETCH_WORK_SCHEDULES, { showOwn: true }], ({ queryKey }) => api.workSchedules.getSchedules(queryKey[1]));

  const [completed, setCompleted] = useState(0);
  const [uncompleted, setUncompleted] = useState(0);
  const today = moment().format(constants.MOMENT_FORMAT.SYSTEM_DATE);
  useEffect(() => {
    if (workSchedulesQuery.data) {
      const schedules = workSchedulesQuery.data;
      const uncompleted = schedules.filter((schedule) => schedule.completed === false);
      const completed = schedules.filter((schedule) => moment(schedule.completed_at).format(constants.MOMENT_FORMAT.SYSTEM_DATE) === today && schedule.completed === true);
      setUncompleted(uncompleted.length);
      setCompleted(completed.length);
    }
  }, [workSchedulesQuery.data, today]);

  const data = {
    labels: ['Nezavršeno', 'Završeno'],
    datasets: [
      {
        label: 'Zavrseno nezavrseno',
        data: [uncompleted, completed],
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(75, 192, 192, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="user-home-box">
      <Flex justifyContent="space-between" textColor="gray.500">
        <Heading size="md">Zdravo {userInfo?.name}</Heading>
        <Heading size="md">{date}</Heading>
      </Flex>
      <Flex justifyContent="space-around" my="50px">
        <Flex alignItems="center" justifyContent="center" width="30%">
          <Doughnut data={data} />
        </Flex>
        <Flex flexDirection="column" justifyContent="center" alignItems="center">
          <Heading>
            {completed}/{uncompleted}
          </Heading>
          <Heading size="md">Završeno / Nezavršeno</Heading>
        </Flex>
      </Flex>
    </div>
  );
};

export default UserHomeBox;
