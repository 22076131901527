import { useMemo } from 'react';
import range from 'lodash/range';

export const usePagination = ({
  totalCount,
  pageSize,
  currentPage,
  siblingCount = 1, //determines how many numbers we render alongside currentPage. default is 1
}) => {
  const DOTS = '...';

  const paginationRange = useMemo(() => {
    const numberOfPages = Math.ceil(totalCount / pageSize);

    /*
      stores the amount of items we'd show on pagination depending on the siblingCount.
      takes into consideration siblingCount, first page, last page, current page and two slots for dots (1 ... 4 5 6 ... 10)
    */
    const paginatedNumbersToShow = siblingCount + 5;

    // if the numberOfPages is smaller than paginatedNumbersToShow, we return the range [1..numberOfPages], since we can show the whole range
    if (paginatedNumbersToShow >= numberOfPages) {
      // we'll increment end number for each range call since we need to include it in the range, as well
      return range(1, numberOfPages + 1);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, numberOfPages);

    // We don't want to show dots if the the current page is a sibling of first or last page
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < numberOfPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = numberOfPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount + 1);

      return [...leftRange, DOTS, numberOfPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(numberOfPages - rightItemCount + 1, numberOfPages + 1);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex + 1);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
