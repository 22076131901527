import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, Spinner } from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import api from '../../../../api';
import { helpers } from '../../../../core/helpers';
import PasswordField from '../../password-field/password-field';

const ChangePasswordModal = (props) => {
  const { showModal, id, onClose = () => {} } = props;

  const changePasswordMutation = useMutation(api.employees.changePassword, {
    onSuccess: () => {
      toast.success('Šifra uspješno promjenjena');
      onClose();
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom promjene šifre');
    },
  });

  const handleChangePassword = (values) => {
    changePasswordMutation.mutate({ id, password: helpers.hashPassword(values.password) });
  };

  return (
    <Modal isOpen={showModal} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalHeader>Promjeni šifru korisnika </ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <ModalBody>
          <Formik
            initialValues={{
              password: '',
            }}
            onSubmit={(values) => {
              handleChangePassword(values);
            }}
          >
            <Form id="add-center">
              <Field name="password">
                {({ field }) => (
                  <FormControl>
                    <PasswordField {...field} id="password" placeholder="Šifra" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>
            </Form>
          </Formik>
        </ModalBody>

        <ModalFooter>
          <Button disabled={changePasswordMutation.isLoading} form="add-center" colorScheme="green" size="sm" mr={3} borderRadius="0" type="submit">
            Spasi {changePasswordMutation.isLoading && <Spinner size="sm" ml="10px" />}
          </Button>
          <Button variant="ghost" size="sm" onClick={onClose}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
