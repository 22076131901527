import { Heading, Text } from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

import CentersWidget from '../shared/widgets/centers/centers-widget';
import ServicesWidget from '../shared/widgets/services/services-widget';
import Therapists from './components/therapists/therapists';
import EmployeesWidget from '../shared/widgets/employees/employees-widget';

import './administration.scss';
import DiagnosisWidget from '../shared/widgets/diagnosis/diagnosis-widget';
import CheckupTypesWidget from '../shared/widgets/checkup-types/checkup-types-widget';
import ClientDocumentTypesWidget from '../shared/widgets/client-document-types/client-document-types-widget';
import GeneralDocumentTypesWidget from '../shared/widgets/general-document-types/general-document-types-widget';

const Administration = () => {
  return (
    <div className="administration">
      <div className="administration__heading">
        <Heading textAlign="center">Administracija</Heading>
      </div>
      <div className="administration__content" width="100%">
        <Tabs orientation="vertical" isLazy colorScheme="pink">
          <TabList width="170px" textAlign="left">
            <Tab w="100%" textAlign="left" display="flex" justifyContent="left">
              <span className="material-icons">people</span> <Text ml="5px">Uposlenici</Text>
            </Tab>
            <Tab w="100%" textAlign="left" display="flex" justifyContent="left">
              <span className="material-icons">domain_add</span> <Text ml="5px">P. jedinice</Text>
            </Tab>
            <Tab w="100%" textAlign="left" display="flex" justifyContent="left">
              <span className="material-icons">badge</span>
              <Text ml="5px"> Terapeuti</Text>
            </Tab>
            <Tab w="100%" textAlign="left" display="flex" justifyContent="left">
              <span className="material-icons">assignment</span> <Text ml="5px">Usluge</Text>
            </Tab>
            <Tab w="100%" textAlign="left" display="flex" justifyContent="left">
              <span className="material-icons">health_and_safety</span> <Text ml="5px">Dijagnoze</Text>
            </Tab>
            <Tab w="100%" textAlign="left" display="flex" justifyContent="left">
              <span className="material-icons">medical_services</span> <Text ml="5px">Vrste Terapija</Text>
            </Tab>
            <Tab w="100%" textAlign="left" display="flex" justifyContent="left">
              <span className="material-icons">assignment_ind</span> <Text ml="5px">Korisnički Dokumenti</Text>
            </Tab>
            <Tab w="100%" textAlign="left" display="flex" justifyContent="left">
              <span className="material-icons">assignment</span> <Text ml="5px">Generalni Dokumenti</Text>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <EmployeesWidget showActions showTitle />
            </TabPanel>
            <TabPanel>
              <CentersWidget showActions />
            </TabPanel>
            <TabPanel>
              <Therapists showActions />
            </TabPanel>
            <TabPanel>
              <ServicesWidget showActions showTitle />
            </TabPanel>
            <TabPanel>
              <DiagnosisWidget showActions showTitle />
            </TabPanel>
            <TabPanel>
              <CheckupTypesWidget showActions showTitle />
            </TabPanel>
            <TabPanel>
              <ClientDocumentTypesWidget showActions showTitle />
            </TabPanel>
            <TabPanel>
              <GeneralDocumentTypesWidget showActions showTitle />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default Administration;
