import { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, Input, Spinner, Progress } from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';

import { useMutation, useQueryClient } from 'react-query';
import constants from '../../../../config/constants';
import api from '../../../../api';
import { toast } from 'react-toastify';
import FileUploadField from '../../file-upload-field/file-upload-field';

const AddRuleModal = (props) => {
  const { showModal, onClose = () => {} } = props;
  const queryClient = useQueryClient();

  const [uploadProgress, setUploadProgress] = useState(0);

  const addGeneralDocumentMutation = useMutation(api.documents.uploadRulesDocument, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_RULES_DOCUMENTS);
      onClose();
      toast.success('Dokument uspješno dodan.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom dodavanja dokumenta.');
    },
  });

  const progressLoad = (e) => {
    const { total, loaded } = e;
    const progress = (loaded / total) * 100;
    setUploadProgress(progress);
  };

  const handleOnSubmit = (values) => {
    const data = {
      ...values,
      progressCallBack: progressLoad,
    };
    addGeneralDocumentMutation.mutate(data);
  };

  const renderProgressBar = () => {
    if (!uploadProgress) return null;
    return <Progress my="20px" value={uploadProgress} size="xs" colorScheme="pink" />;
  };

  const isLoading = addGeneralDocumentMutation?.isLoading;

  return (
    <Modal isOpen={showModal} onClose={() => onClose()}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalHeader>Dodaj pravilnik</ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <ModalBody>
          <Formik
            initialValues={{
              name: '',
            }}
            onSubmit={(values) => handleOnSubmit(values)}
          >
            {({ values, setFieldValue }) => (
              <Form id="add-general-document">
                <Field name="name">
                  {({ field }) => (
                    <FormControl>
                      <Input {...field} id="name" placeholder="Naziv pravilnika" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>
                <br />

                <FileUploadField
                  inputProps={{
                    id: 'file_upload',
                    name: 'file_upload',
                    onChange: (e) => setFieldValue('file', e.target.files[0]),
                    required: true,
                  }}
                  label="Odaberi fajl"
                  onRemove={() => {
                    setFieldValue('file', null);
                  }}
                />
              </Form>
            )}
          </Formik>
          {renderProgressBar()}
        </ModalBody>

        <ModalFooter>
          <Button disabled={isLoading} form="add-general-document" colorScheme="green" size="sm" mr={3} borderRadius="0" type="submit">
            Spasi {isLoading && <Spinner size="sm" ml="10px" />}
          </Button>
          <Button variant="ghost" size="sm" onClick={() => onClose()}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddRuleModal;
