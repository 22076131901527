import { Badge, Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import api from '../../api';
import constants from '../../config/constants';
import { helpers } from '../../core/helpers';
import state from '../../core/state';
import WorkScheduleModal from '../shared/modals/view-work-schedule/work-schedule-modal';
import SpinnerCentered from '../shared/spinner-centered/spinner-centered';

import './work-schedules.scss';

const WorkSchedules = () => {
  const userInfo = state.currentUser((state) => state.userInfo);

  // const weeksInTheYear = moment().weeksInYear();
  const currentWeek = moment().isoWeek();
  const currentYear = moment().year();

  const [weekNumber, setWeekNumber] = useState(currentWeek);
  const [year, setYear] = useState(currentYear);
  const [days, setDays] = useState(null);
  const [scheduleId, setScheduleId] = useState(null);
  const [showViewScheduleModal, setShowViewScheduleModal] = useState(false);

  useEffect(() => {
    const daysInWeek = [1, 2, 3, 4, 5, 6, 7].map((d) => moment(`${year}-${weekNumber}-${d}`, 'YYYY-W-E'));
    setDays(daysInWeek);
  }, [year, weekNumber]);

  const employeesQuery = useQuery(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES, api.employees.getEmployees);

  const handleGoToNextWeek = () => {
    const weeksInTheYear = moment(year).weeksInYear();
    if (weekNumber === weeksInTheYear) {
      setWeekNumber(1);
      setYear(year + 1);
    } else {
      setWeekNumber(weekNumber + 1);
    }
  };
  const handleGoToPrevWeek = () => {
    if (weekNumber === 1) {
      const weeksInTheYear = moment().subtract(1, 'year').weeksInYear();
      setWeekNumber(weeksInTheYear);
      setYear(year - 1);
    } else {
      setWeekNumber(weekNumber - 1);
    }
  };

  const renderWeeksNavigation = () => {
    return (
      <Flex justifyContent="space-between" width="100%" my="15px">
        <Button onClick={() => handleGoToPrevWeek()}>
          <i className="material-icons">chevron_left</i> Prethodna
        </Button>
        <Button onClick={() => handleGoToNextWeek()}>
          Naredna <i className="material-icons">chevron_right</i>
        </Button>
      </Flex>
    );
  };

  const renderHeadingRow = () => {
    return (
      <div className="work-schedules__calendar__heading-row">
        <div className="work-schedules__calendar__heading-row-cell">
          Dan u sedmici <Divider /> Terapeut
        </div>
        {days.map((day, index) => (
          <div key={index + day} className={`work-schedules__calendar__heading-row-cell`}>
            <Text textTransform="uppercase" fontWeight="light">
              {constants.WEEK_DAYS[index]}
            </Text>
            <Text>{moment(day).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)}</Text>
          </div>
        ))}
      </div>
    );
  };

  const renderEmployeesWeek = (employee, index) => {
    if (!employee.active || helpers.isUserHidden({ username: employee.username, isAdminPanel: false })) return null;
    return (
      <div key={employee.first_name + index} className="work-schedules__calendar__employees-row">
        <div className="work-schedules__calendar__employees-row-cell">
          {employee.first_name} {employee.last_name}
        </div>
        {days?.map((day, index) => (
          <div key={day + index} className={`work-schedules__calendar__employees-row-cell ${markTodaysDate(day) ? 'work-schedules__calendar__current-date-active' : ''}`}>
            {renderSchedulesForDay({ day, employee })}
          </div>
        ))}
      </div>
    );
  };

  const renderSchedulesForDay = (data) => {
    const { day, employee } = data;
    const schedules = employee.work_schedules.filter((schedule) => moment(schedule.expiring_at, constants.MOMENT_FORMAT.SYSTEM_DATE).isSame(day, constants.MOMENT_FORMAT.SYSTEM_DATE));
    return schedules.map((schedule, index) => (
      <Badge
        className={userInfo?.id === schedule.created_by ? 'work-schedules__calendar__employees-row-cell__schedule-clickable' : ''}
        onClick={() => handleToggleViewScheduleModal({ id: schedule.id, show: userInfo?.id === schedule.created_by })}
        width="100%"
        display="flex"
        justifyContent="space-between"
        colorScheme={schedule.completed ? 'green' : 'blue'}
        my="5px"
        p="2px"
        key={schedule.id + index}
      >
        {schedule?.name.slice(0, 20)}
        {schedule?.name.length > 20 && '...'}
        {schedule.completed && <i className="material-icons work-schedules__calendar__employees-row-cell__icon-check">check_circle</i>}
      </Badge>
    ));
  };

  const markTodaysDate = (date) => {
    const todaysDate = moment().format(constants.MOMENT_FORMAT.SYSTEM_DATE);
    const dateToCheck = moment(date).format(constants.MOMENT_FORMAT.SYSTEM_DATE);
    return todaysDate === dateToCheck;
  };

  // View Schedule Toggler
  const handleToggleViewScheduleModal = (data) => {
    const { id, show } = data;
    if (show) {
      setScheduleId(id);
      setShowViewScheduleModal(true);
    } else {
      setScheduleId(null);
      setShowViewScheduleModal(false);
    }
  };

  return (
    <div className="work-schedules">
      <Heading textAlign="center" my="15px" fontSize="lg">
        Raspored obaveza za sedmicu
      </Heading>
      <Heading textAlign="center" fontSize="lg" textColor={constants.PRIMARY_COLOR}>
        {days && moment(days[0]).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)} - {days && moment(days[days.length - 1]).format(constants.MOMENT_FORMAT.DATE_DOT_SEPARATED)}
      </Heading>
      {renderWeeksNavigation()}
      <div className="work-schedules__calendar">
        {days && renderHeadingRow()}
        {employeesQuery.data?.map((employee, index) => renderEmployeesWeek(employee, index))}
        <SpinnerCentered show={employeesQuery.isLoading} />
      </div>
      <WorkScheduleModal scheduleId={scheduleId} showModal={showViewScheduleModal} toggleShowModal={() => handleToggleViewScheduleModal({ id: null, show: false })} />
    </div>
  );
};

export default WorkSchedules;
