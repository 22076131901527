import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, Input, Spinner, Textarea } from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import moment from 'moment';
import { toast } from 'react-toastify';

import api from '../../../../api';
import constants from '../../../../config/constants';
import SpinnerCentered from '../../spinner-centered/spinner-centered';

const AddWorkScheduleModal = (props) => {
  const { id, showModal, toggleModal = () => {} } = props;
  const queryClient = useQueryClient();

  const workSchedule = useQuery([constants.QUERY_NAME.HANDLE_VIEW_WORK_SCHEDULE, id], ({ queryKey }) => api.workSchedules.getSchedule(queryKey[1]), { enabled: !!id });

  // Add schedule
  const addSchedulMutation = useMutation(api.workSchedules.addSchedule, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_WORK_SCHEDULES);
      toggleModal();
      toast.success('Obaveza uspješno kreirana.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom kreiranja obaveze.');
    },
  });

  // Update schedule
  const updateScheduleMutation = useMutation(api.workSchedules.editSchedule, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_WORK_SCHEDULES);
      toggleModal();
      toast.success('Obaveza uspješno uređena.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom uređivanja obaveze.');
    },
  });

  const submit = (values) => {
    const data = { name: values.name, description: values.description, expiringAt: new Date(values.dateTo) };
    if (id) {
      updateScheduleMutation.mutate({ data: { values: data, id } });
    } else {
      addSchedulMutation.mutate(data);
    }
  };

  const renderModalHeaderContent = () => (workSchedule.data?.name ? `Uredi ${workSchedule.data?.name}` : 'Dodaj novu obavezu');

  const initialValues = {
    name: workSchedule.data?.name || '',
    dateTo: workSchedule.data?.expiring_at ? moment(workSchedule.data.expiring_at).format(constants.MOMENT_FORMAT.SYSTEM_DATE) : '',
    description: workSchedule.data?.description || '',
  };

  const isLoading = () => {
    return updateScheduleMutation.isLoading || addSchedulMutation.isLoading;
  };

  return (
    <Modal isOpen={showModal} onClose={() => toggleModal()}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalHeader>{renderModalHeaderContent()}</ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <ModalBody>
          <SpinnerCentered show={workSchedule.isLoading} />
          {!workSchedule.isLoading && (
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                submit(values);
              }}
            >
              <Form id="add-center">
                <Field name="name">
                  {({ field }) => (
                    <FormControl>
                      <Input {...field} id="name" placeholder="Naziv obaveze" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>

                <Field name="dateTo">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Input {...field} type="date" id="name" placeholder="Datum" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>

                <Field name="description">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Textarea {...field} type="date" id="name" placeholder="Opis obaveze" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>
              </Form>
            </Formik>
          )}
        </ModalBody>

        <ModalFooter>
          <Button disabled={isLoading()} form="add-center" colorScheme="green" size="sm" mr={3} borderRadius="0" type="submit">
            Spasi {isLoading() && <Spinner size="sm" ml="10px" />}
          </Button>
          <Button variant="ghost" size="sm" onClick={() => toggleModal()}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddWorkScheduleModal;
