import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, Input, Spinner, Select } from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import constants from '../../../../config/constants';
import api from '../../../../api';
import { toast } from 'react-toastify';
import SpinnerCentered from '../../spinner-centered/spinner-centered';

const AddEditCenterModal = (props) => {
  const { id, showModal, onClose = () => {} } = props;
  const queryClient = useQueryClient();

  const center = useQuery([constants.QUERY_NAME.HANDLE_FETCH_CENTER, id], ({ queryKey }) => api.centers.getCenter(queryKey[1]), { enabled: !!id });
  const locations = useQuery(constants.QUERY_NAME.HANDLE_FETCH_LOCATIONS, api.locations.getLocations);

  const addCenterMutation = useMutation(api.centers.addCenter, {
    onSuccess: () => {
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CENTERS);
      onClose();
      toast.success('Poslovna jedinica uspješno dodana.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom dodavanja poslovne jedinice.');
    },
  });

  const updateCenterMutation = useMutation(api.centers.updateCenter, {
    onSuccess: () => {
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CENTERS);
      onClose();
      toast.success('Poslovna jedinica uspješno izmjenjena.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom izmjene poslovne jedinice.');
    },
  });

  const handleSubmitData = (values) => {
    if (id) {
      updateCenterMutation.mutate({ values, id });
    } else {
      addCenterMutation.mutate(values);
    }
  };

  const isLoading = updateCenterMutation.isLoading || updateCenterMutation.isLoading;

  const renderModalHeaderContent = () => (center.data?.name ? `Uredi ${center.data?.name}` : 'Dodaj novu poslovnu jedinicu');

  return (
    <Modal isOpen={showModal} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalHeader>{renderModalHeaderContent()}</ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <ModalBody>
          <SpinnerCentered show={center.isLoading} />
          {!center.isLoading && (
            <Formik
              initialValues={{
                name: center.data?.name || '',
                location: center.data?.location_id || '',
                phone: center.data?.phone || '',
                phone2: center.data?.phone2 || '',
                email: center.data?.email || '',
                address: center.data?.address || '',
              }}
              onSubmit={(values) => {
                handleSubmitData(values);
              }}
            >
              <Form id="add-center">
                <Field name="name">
                  {({ field }) => (
                    <FormControl>
                      <Input {...field} id="name" placeholder="Naziv ustanove" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>

                <Field name="address">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Input {...field} id="address" name="address" placeholder="Adresa" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>

                <Field name="location">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Select {...field} placeholder="Lokacija" borderRadius="0" title="Lokacija" aria-label="Lokacija" required>
                        {locations.data?.map((location) => (
                          <option value={location.id}>{location.name}</option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>

                <Field name="phone">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Input {...field} id="phone" name="phone" placeholder="Telefon" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>

                <Field name="phone2">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Input {...field} id="phone2" name="phone2" placeholder="Telefon 2" borderRadius="0" />
                    </FormControl>
                  )}
                </Field>

                <Field name="email">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Input {...field} id="email" name="email" placeholder="Email" type="email" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>
              </Form>
            </Formik>
          )}
        </ModalBody>

        <ModalFooter>
          <Button disabled={isLoading} form="add-center" colorScheme="green" size="sm" mr={3} borderRadius="0" type="submit">
            Spasi {isLoading && <Spinner size="sm" ml="10px" />}
          </Button>
          <Button variant="ghost" size="sm" onClick={onClose}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddEditCenterModal;
