import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, FormControl, Input, Spinner, Textarea } from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { toast } from 'react-toastify';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import constants from '../../../../config/constants';
import api from '../../../../api';
import SpinnerCentered from '../../spinner-centered/spinner-centered';

const AddTherapistModal = (props) => {
  const { id, showModal, onClose = () => {} } = props;
  const queryClient = useQueryClient();

  const workingPosition = useQuery([constants.QUERY_NAME.HANDLE_FETCH_WORKING_POSITION, id], ({ queryKey }) => api.workingPositions.getPosition(queryKey[1]), { enabled: !!id });

  const addPositionMutation = useMutation(api.workingPositions.addPosition, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_WORKING_POSITIONS);
      onClose();
      toast.success('Tip terapeuta uspješno kreiran.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom kreiranja tipa terapeuta.');
    },
  });
  const updatePositionMutation = useMutation(api.workingPositions.updatePosition, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_WORKING_POSITIONS);
      onClose();
      toast.success('Tip terapeuta uspješno ažuriran.');
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom ažuriranja tipa terapeuta.');
    },
  });

  const handleOnSubmit = (values) => {
    if (id) {
      updatePositionMutation.mutate({ id, values });
    } else {
      addPositionMutation.mutate(values);
    }
  };

  const renderModalHeaderContent = () => (workingPosition.data?.name ? `Uredi ${workingPosition.data?.name}` : 'Dodaj tip terapeuta');

  const isLoading = addPositionMutation.isLoading || updatePositionMutation.isLoading;

  return (
    <Modal isOpen={showModal} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        <ModalHeader>{renderModalHeaderContent()}</ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <SpinnerCentered show={workingPosition.isLoading} />
        {!workingPosition.isLoading && (
          <ModalBody>
            <Formik
              initialValues={{
                name: workingPosition.data?.name || '',
                description: workingPosition.data?.description || '',
              }}
              onSubmit={(values) => handleOnSubmit(values)}
            >
              <Form id="add-center">
                <Field name="name">
                  {({ field }) => (
                    <FormControl>
                      <Input {...field} id="name" placeholder="Pozicija" borderRadius="0" required />
                    </FormControl>
                  )}
                </Field>

                <Field name="description">
                  {({ field }) => (
                    <FormControl mt="10px">
                      <Textarea {...field} id="description" name="description" placeholder="Opis pozicije" borderRadius="0" />
                    </FormControl>
                  )}
                </Field>
              </Form>
            </Formik>
          </ModalBody>
        )}

        <ModalFooter>
          <Button disabled={isLoading} form="add-center" colorScheme="green" size="sm" mr={3} borderRadius="0" type="submit">
            Spasi {isLoading && <Spinner size="sm" ml="10px" />}
          </Button>
          <Button variant="ghost" size="sm" onClick={onClose}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddTherapistModal;
