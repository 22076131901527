import axios from '../config/axios-config';

const getRoles = async (token: string) => {
  const roles = await axios({
    method: 'GET',
    url: `/roles`,
  });
  return roles.data || [];
};

const auth = {
  getRoles,
};

export default auth;
