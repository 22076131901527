import create from 'zustand';
import constants from '../config/constants';
import cacheHelper from './helpers/cacheHelper';

// Setting dark mode in Zustand state and local storage
const useDarkMode = create((set) => ({
  isDarkMode: cacheHelper.getDarkMode(),
  setIsDarkMode: (value) => {
    cacheHelper.setDarkMode(value);
    return set({ isDarkMode: value });
  },
}));

const isMobileScreen = create((set) => ({
  isMobile: window.innerWidth <= constants.MAX_MOBILE_WIDTH,
  setIsMobile: (value) => {
    return set({ isMobile: value });
  },
}));

const userRoles = create((set) => ({
  userRoles: cacheHelper.getUserInfo().roles,
  setUserRoles: (value) => {
    return set({ userRoles: value });
  },
}));

const currentUser = create((set) => ({
  userInfo: cacheHelper.getUserInfo(),
  setUserInfo: (value) => {
    if (value) {
      cacheHelper.setUserInfo(value);
      return set({ userInfo: value });
    }
    cacheHelper.clearUserInfo();
    return set({ userInfo: value });
  },
}));

const useAuth = create((set) => ({
  isLoggedIn: Boolean(cacheHelper.getToken()) || false,
  token: cacheHelper.getToken() || null,
  setIsLoggedIn: (value, token) => {
    if (value && token) {
      cacheHelper.setToken(token);
      set({ token });
      return set({ isLoggedIn: value });
    }
    cacheHelper.clearToken();
    return set({ isLoggedIn: value });
  },
}));

const useRefreshToken = create((set) => ({
  refreshToken: Boolean(cacheHelper.getRefreshToken()) || '',
  refreshTokenExpireTime: Boolean(cacheHelper.getRefreshTokenExpTime()) || '',
  setRefreshToken: (value) => {
    if (value) {
      cacheHelper.setRefreshToken(value);
      return set({ refreshToken: value });
    }
    cacheHelper.clearRefreshToken();
    return set({ refreshToken: value });
  },
  setRefreshTokenExpireTime: (value) => {
    if (value) {
      cacheHelper.setRefreshTokenExpTime(value);
      return set({ refreshTokenExpireTime: value });
    }
    cacheHelper.clearRefreshTokenExpTime();
    return set({ refreshTokenExpireTime: value });
  },
}));

const modals = create((set) => ({
  showAddEmployeeModal: false,
  showAddCenterModal: false,
  showAddTherapistModal: false,
  showAddServiceModal: false,
  showAddDocumentModal: false,
  showAddWorkScheduleModal: false,
  showViewWorkScheduleModal: false,
  setShowAddEmployeeModal: (value) => {
    return set({ showAddEmployeeModal: value });
  },
  setShowAddCenterModal: (value) => {
    return set({ showAddCenterModal: value });
  },
  setShowAddTherapistModal: (value) => {
    return set({ showAddTherapistModal: value });
  },
  setShowAddServiceModal: (value) => {
    return set({ showAddServiceModal: value });
  },
  setShowAddDocumentModal: (value) => {
    return set({ showAddDocumentModal: value });
  },
  setShowAddWorkScheduleModal: (value) => {
    return set({ showAddWorkScheduleModal: value });
  },
  setShowViewWorkScheduleModal: (value) => {
    return set({ showViewWorkScheduleModal: value.isVisible, viewWorkScheduleId: value.id });
  },
}));

const state = {
  useDarkMode,
  isMobileScreen,
  userRoles,
  modals,
  useAuth,
  useRefreshToken,
  currentUser,
};

export default state;
