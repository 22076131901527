const routes = {
  HOME: '/',
  NOT_FOUND: '*',
  LOGIN: '/prijava',
  CLIENTS: '/korisnici',
  VIEW_CLIENT: '/korisnici/:id',
  ADD_CLIENT: '/korisnici/dodaj',
  EDIT_CLIENT: '/korisnici/uredi/:id',
  DATES: '/termini',
  REPORTS: '/izvjestaji',
  EMPLOYEES: '/uposlenici',
  ADMINISTRATION: '/administracija',
  DOCUMENTS: '/dokumenti',
  RULES: '/pravilnici',
  SERVICES: '/usluge',
  EENTRY_DIAGNOSTICS: '/ulazne-dijagnostike',
  ADD_ENTRY_DIAGNOSTIC: '/ulazne-dijagnostike/dodaj/:clientId',
  VIEW_ENTRY_DIAGNOSTIC: '/ulazne-dijagnostike/:id',
  EDIT_ENTRY_DIAGNOSTIC: '/ulazne-dijagnostike/uredi/:id',
};

export default routes;
