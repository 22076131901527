import { Box, Button, Flex, Heading } from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { useQuery } from 'react-query';
import * as XLSX from 'xlsx';

import api from '../../api';
import constants from '../../config/constants';

import RangePickerField from '../shared/range-picker-field/range-picker-field';
import DatesPerCenter from './charts/dates-per-center';
import UsersPerCenter from './charts/users-per-center';
import UsersPerEmployee from './charts/users-per-employee';
import UsersPerService from './charts/users-per-service';

import './reports.scss';

const Reports = () => {
  const [dates, setDates] = useState({ dateFrom: moment('01/01/1970').toISOString(), dateTo: moment().toISOString() });

  const statsQuery = useQuery([constants.QUERY_NAME.HANDLE_FETCH_STATISTICS, dates], ({ queryKey }) => api.statistics.getStats(queryKey[1]));
  const setFilterDates = (data) => {
    const { dateFrom, dateTo } = data;
    setDates({ dateFrom: moment(dateFrom).toISOString(), dateTo: moment(dateTo).toISOString() });
  };

  const getTotalTreatments = () => {
    let totalTreatments = 0;
    const statsPerCenter = statsQuery?.data?.stats_per_center;
    if (statsPerCenter) {
      statsPerCenter.forEach((center) => {
        totalTreatments += center.total_checkups;
      });
    }
    return totalTreatments;
  };

  const getTotalUserss = () => {
    let totalUsers = 0;
    const statsPerCenter = statsQuery?.data?.stats_per_center;
    if (statsPerCenter) {
      statsPerCenter.forEach((center) => {
        totalUsers += center.active_persons;
      });
    }
    return totalUsers;
  };

  const getTotalUserssOnWaitLIst = () => {
    let totalUsers = 0;
    const statsPerCenter = statsQuery?.data?.stats_per_center;
    if (statsPerCenter) {
      statsPerCenter.forEach((center) => {
        totalUsers += center.persons_on_wait_list;
      });
    }
    return totalUsers;
  };

  const downloadReport = () => {
    const dateFrom = moment(dates.dateFrom).format(constants.MOMENT_FORMAT.DATE_MONTH_YEAR);
    const dateTo = moment(dates.dateTo).format(constants.MOMENT_FORMAT.DATE_MONTH_YEAR);
    const centers = [];
    const services = [];
    const employees = [];
    const fileName = `izvjestaj_za_period_${dateFrom}-${dateTo}.xlsx`;

    const center = statsQuery?.data?.stats_per_center;
    const service = statsQuery?.data?.stats_per_service;
    const employee = statsQuery?.data?.users_per_employee;

    center.forEach((c) => {
      centers.push({
        'POSLOVNA JEDINICA': c.name,
        'AKTIVNIH KORISNIKA': c.active_persons,
        'KORISNIKA NA LISTI ČEKANJA': c.persons_on_wait_list,
        'UKUPNO KORISNIKA': c.total_persons_count,
        'BROJ TRETMANA': c.total_checkups,
      });
    });

    service.forEach((s) => {
      services.push({
        USLUGA: s.service_name,
        'AKTIVNIH KORISNIKA': s.active_count,
        'KORISNIKA NA LISTI ČEKANJA': s.on_wait_list_count,
        'UKUPNO KORISNIKA': s.total_persons,
      });
    });

    employee.forEach((u) => {
      const isUserHidden = constants.HIDDEN_USERS.includes(u?.employee_username?.toLowerCase());
      if (!isUserHidden) {
        employees.push({
          TERAPEUT: u.employee_name,
          'KORISNIKA NA PRAĆENJU': u.total_persons_count,
        });
      }
    });

    const ws1 = XLSX.utils.json_to_sheet(centers);
    const ws2 = XLSX.utils.json_to_sheet(services);
    const ws3 = XLSX.utils.json_to_sheet(employees);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, 'Po poslovnoj jedinici');
    XLSX.utils.book_append_sheet(wb, ws2, 'Po uslugama');
    XLSX.utils.book_append_sheet(wb, ws3, 'Po terapeutu');

    XLSX.writeFile(wb, fileName);
  };

  return (
    <div className="reports">
      <Heading textAlign="center">Izvještaji</Heading>
      <Box width="100%" display="flex" justifyContent="flex-end">
        <Button display="flex" alignItems="center" marginRight="0" color="green.400" onClick={() => downloadReport()}>
          <i className="material-icons">download</i>Preuzmi
        </Button>
      </Box>
      <div className="reports__wrapper">
        <RangePickerField display="inline" onDatesChange={setFilterDates} />
        <Box backgroundColor="white" className="reports__block" padding="10px" mt="50px">
          <Heading size="md">Broj tretmana</Heading>
          <DatesPerCenter centers={statsQuery?.data?.stats_per_center} />
          <Heading size="sm">Ukupan broj tretmana: {getTotalTreatments()}</Heading>
        </Box>

        <Box backgroundColor="white" className="reports__block" padding="10px" mt="50px">
          <Heading size="md">Broj korisnika</Heading>
          <UsersPerCenter centers={statsQuery?.data?.stats_per_center} />
          <Heading size="sm">Aktivnih korisnika: {getTotalUserss()}</Heading>
          <Heading size="sm">Korisnika na listi čekanja: {getTotalUserssOnWaitLIst()}</Heading>
        </Box>

        <Flex w="100%" justifyContent="space-around">
          <Box backgroundColor="white" className="reports__block" padding="10px" mt="50px" w="100%">
            <Heading size="md">Broj korisnika po vrsti usluge</Heading>
            <UsersPerService services={statsQuery?.data?.stats_per_service} />
          </Box>
          <Box backgroundColor="white" className="reports__block" padding="10px" mt="50px" w="100%">
            <Heading size="md">Broj korisnika na praćenju kod terapeuta</Heading>
            <UsersPerEmployee employees={statsQuery?.data?.users_per_employee} />
          </Box>
        </Flex>

        <Box backgroundColor="white" className="reports__block" padding="10px" mt="50px">
          <Heading size="md">Ukupno korisnika</Heading>
          <Flex mt="20px" padding="10px">
            {statsQuery?.data?.stats_per_center?.map((center, index) => (
              <Flex key={index + center.name} flexDirection="column" alignItems="center" width="100%">
                <Heading>{center.total_persons_count}</Heading>
                <Heading size="md">{center.name}</Heading>
              </Flex>
            ))}
          </Flex>
        </Box>
      </div>
    </div>
  );
};

export default Reports;
