import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Spinner, Flex, Switch, Divider } from '@chakra-ui/react';
import moment from 'moment';
import { Fragment } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import api from '../../../../api';
import constants from '../../../../config/constants';
import QUERY_NAME from '../../../../config/constants';

const WorkScheduleModal = (props) => {
  const { scheduleId, showModal, toggleShowModal = () => {} } = props;
  const queryClient = useQueryClient();

  const toggleHideModal = () => {
    toggleShowModal(false);
  };

  const workSchedule = useQuery([QUERY_NAME.HANDLE_VIEW_WORK_SCHEDULE, scheduleId], ({ queryKey }) => api.workSchedules.getSchedule(queryKey[1]), { enabled: !!scheduleId });

  const changeWSStatusMutation = useMutation(api.workSchedules.changeStatus, {
    onError: () => {
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES);
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_VIEW_WORK_SCHEDULE);
      toast.error('Došlo je do greške prilikom promjene statusa!');
    },
    onSuccess: () => {
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_EMPLOYEES);
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_VIEW_WORK_SCHEDULE);
      toast.success('Status uspješno promjenjen!');
    },
  });

  const renderModalContent = () =>
    workSchedule.data ? (
      <Fragment>
        <ModalHeader>{workSchedule.data?.name}</ModalHeader>
        <ModalCloseButton borderRadius="0" />
        <ModalBody>
          {workSchedule.data?.description}
          <Divider my="15px" />
          <Flex justifyContent="space-between">
            <p>
              Završeno:
              <Switch
                defaultIsChecked={workSchedule?.data?.completed}
                colorScheme="pink"
                onChange={() => changeWSStatusMutation.mutate({ id: workSchedule.data?.id, completed: !workSchedule.data?.completed })}
              />
            </p>
            <p>Kreirano: {moment(workSchedule.data.created_at).format(constants.MOMENT_FORMAT.DATE_TIME_DOT_SEPARATED)}</p>
          </Flex>
        </ModalBody>
      </Fragment>
    ) : null;

  const showLoader = () =>
    workSchedule.isLoading ? (
      <Flex my="15px" align="center" justify="center">
        <Spinner />
      </Flex>
    ) : null;

  return (
    <Modal isOpen={showModal} onClose={toggleHideModal}>
      <ModalOverlay />
      <ModalContent borderRadius="0">
        {showLoader()}
        {renderModalContent()}
        <ModalFooter>
          <Button variant="ghost" size="sm" onClick={toggleHideModal}>
            Zatvori
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WorkScheduleModal;
