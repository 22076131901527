import { Heading, Button, FormControl, Input, Spinner, Textarea, Select, Divider, Flex, Box, Checkbox } from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import './add-client.scss';
import { Link, useNavigate } from 'react-router-dom';
import routes from '../../../core/routes/routes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import api from '../../../api';
import { toast } from 'react-toastify';
import constants from '../../../config/constants';
import { useParams } from 'react-router-dom';
import SpinnerCentered from '../../shared/spinner-centered/spinner-centered';

const AddClient = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const navigate = useNavigate();

  const selectedClient = useQuery([constants.QUERY_NAME.HANDLE_FETCH_CLIENT, id], ({ queryKey }) => api.clients.getClient(queryKey[1]), { enabled: !!id });
  const diagnosis = useQuery(constants.QUERY_NAME.HANDLE_FETCH_DIAGNOSIS, api.diagnosis.getDiagnosis);
  const cities = useQuery(constants.QUERY_NAME.HANDLE_FETCH_LOCATIONS, api.locations.getLocations);
  const services = useQuery(constants.QUERY_NAME.HANDLE_FETCH_SERVICES, api.services.getServices);

  const addClientMutation = useMutation(api.clients.addClient, {
    onSuccess: () => {
      toast.success('Korisnik uspješno kreiran.');
      navigate(routes.CLIENTS);
    },
    onError: () => {
      toast.error('Došlo je do greške prilikom kreiranja korisnika');
    },
  });

  const updateClientMutation = useMutation(api.clients.updateClient, {
    onSuccess: () => {
      // Invalidate and refetch
      toast.success('Korisnik uspješno ažuriran.');
      queryClient.invalidateQueries(constants.QUERY_NAME.HANDLE_FETCH_CLIENTS);
    },

    onError: () => {
      toast.error('Došlo je do greške prilikom ažuriranja podataka.');
    },
  });

  const submitEmployee = (values) => {
    if (id) {
      updateClientMutation.mutate({ id, values });
    } else {
      addClientMutation.mutate(values);
    }
  };

  const renderHeaderContent = () => (selectedClient.data?.id ? `Uredi ${selectedClient.data?.first_name}` : 'Dodaj novog korisnika centra');

  return (
    <div className="add-client">
      <SpinnerCentered show={selectedClient.isLoading} />
      <Heading textAlign="center">{!selectedClient.isLoading && renderHeaderContent()}</Heading>
      {!selectedClient.isLoading && (
        <div className="add-client__form-wrapper">
          <Formik
            initialValues={{
              identificationNumber: selectedClient?.data?.identification_number || '',
              firstName: selectedClient?.data?.first_name || '',
              lastName: selectedClient?.data?.last_name || '',
              birthLastName: selectedClient?.data?.birth_last_name || '',
              motherName: selectedClient?.data?.mother_name || '',
              fatherName: selectedClient?.data?.father_name || '',
              sex: selectedClient?.data?.sex || '',
              birthDate: selectedClient?.data?.birth_date || '',
              placeOfBirth: selectedClient?.data?.place_of_birth || '',
              birthCity: selectedClient?.data?.birth_city || '',
              muncipalityOfResidence: selectedClient?.data?.muncipality_residence || '',
              address: selectedClient?.data?.address_street || '',
              postalCode: selectedClient?.data?.postal_code || '',
              cityOfResidence: selectedClient?.data?.city_of_residence || '',
              idCardNumber: selectedClient?.data?.id_card_number || '',
              idCardIssuedBy: selectedClient?.data?.id_card_issued_location || '',
              occupation: selectedClient?.data?.occupation || '',
              phone: selectedClient?.data?.phone || '',
              bankName: selectedClient?.data?.bank_name || '',
              bankTransactionNumber: selectedClient?.data?.bank_account_number || '',
              serviceUponArrival: selectedClient?.data?.service_upon_arrival || '',
              diagnose: '',
              note: selectedClient?.data?.note || '',
              centerId: selectedClient?.data?.center_id || '',
              isOnWaitList: selectedClient?.data?.is_on_wait_list || false,
              serviceId: selectedClient?.data?.service_id || '',
            }}
            onSubmit={(values) => {
              submitEmployee(values);
            }}
          >
            <Form id="add-edit-employee">
              <Heading size="sm" mb="15px;">
                Osnovne informacije
              </Heading>
              <Field name="firstName">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="firstName" placeholder="Ime" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>
              <Field name="lastName">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="lastName" placeholder="Prezime" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>
              <Field name="birthLastName">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="birthLastName" placeholder="Rođeno prezime" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>
              <Field name="identificationNumber">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="identificationNumber" placeholder="JMBG" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>
              <Field name="motherName">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="motherName" placeholder="Majka" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>
              <Field name="fatherName">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="fatherName" placeholder="Otac" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>
              <Field name="sex">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Select {...field} placeholder="Spol" borderRadius="0" title="Spol" aria-label="Spol" required>
                      <option value="M">Muški</option>
                      <option value="Z">Ženski</option>
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Field name="birthDate">
                {({ field }) => (
                  <FormControl mt="10px">
                    <label htmlFor="birthDate">Datum rođenja</label>
                    <Input {...field} type="date" id="birthDate" placeholder="Datum rođenja" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>
              <Field name="placeOfBirth">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="placeOfBirth" placeholder="Mjesto rođenja" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>
              <Field name="birthCity">
                {({ field }) => (
                  <FormControl mt="10px" mb="30px">
                    <Input {...field} id="birthCity" placeholder="Grad" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>
              <Divider />
              <Heading size="sm" my="15px;">
                Informacije o mjestu stanovanja
              </Heading>

              <Field name="address">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="address" placeholder="Adresa stanovanja" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>
              <Field name="muncipalityOfResidence">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="muncipalityOfResidence" placeholder="Opština stanovanja" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>
              <Field name="postalCode">
                {({ field }) => (
                  <FormControl mt="10px" mb="30px">
                    <Input {...field} id="postalCode" placeholder="Poštanski broj" borderRadius="0" required />
                  </FormControl>
                )}
              </Field>
              <Field name="cityOfResidence">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Select {...field} placeholder="Grad stanovanja" borderRadius="0" title="Grad stanovanja" aria-label="Grad stanovanja" required>
                      {cities.data?.map((city) => (
                        <option value={city.name} key={city.name}>
                          {city.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Divider />
              <Heading size="sm" my="15px;">
                Dokumenti
              </Heading>
              <Field name="idCardNumber">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="idCardNumber" placeholder="Broj lične karte" borderRadius="0" />
                  </FormControl>
                )}
              </Field>
              <Field name="idCardIssuedBy">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="idCardIssuedBy" placeholder="Mjesto izdavanja lične karte" borderRadius="0" />
                  </FormControl>
                )}
              </Field>

              <Field name="occupation">
                {({ field }) => (
                  <FormControl mt="10px" mb="30px">
                    <Input {...field} id="occupation" placeholder="Zanimanje" borderRadius="0" />
                  </FormControl>
                )}
              </Field>
              <Divider />
              <Heading size="sm" my="15px;">
                Kontakt informacije
              </Heading>
              <Field name="phone">
                {({ field }) => (
                  <FormControl mt="10px" mb="30px">
                    <Input {...field} id="phone" placeholder="Telefon" borderRadius="0" />
                  </FormControl>
                )}
              </Field>

              {/* <Divider />
              <Heading size="sm" my="15px;">
                Informacije o bankovnom računu
              </Heading>
              <Field name="bankName">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Input {...field} id="bankName" placeholder="Ime banke" borderRadius="0" />
                  </FormControl>
                )}
              </Field>

              <Field name="bankTransactionNumber">
                {({ field }) => (
                  <FormControl mt="10px" mb="30px">
                    <Input {...field} id="bankTransactionNumber" placeholder="Broj žiro računa" borderRadius="0" />
                  </FormControl>
                )}
              </Field> */}
              <Divider />
              {!id && (
                <Box>
                  <Heading size="sm" my="15px;">
                    Dijagnoza / vrsta teškoća
                  </Heading>
                  <Field name="diagnose">
                    {({ field }) => (
                      <FormControl mt="10px" mb="30px">
                        <Select {...field} placeholder="Dijagnoza" borderRadius="0" title="Dijagnoza" aria-label="Dijagnoza" required>
                          {diagnosis.data?.map((diagnose) => (
                            <option value={diagnose.id} key={diagnose.name}>
                              {diagnose.name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Field>
                  <Divider />
                </Box>
              )}
              <Heading size="sm" my="15px;">
                Usluga koju korisnik koristi
              </Heading>
              <Field name="serviceId">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Select {...field} placeholder="Usluga koju korisnik koristi" borderRadius="0" title="Usluga koju korisnik koristi" aria-label="Usluga koju korisnik koristi" required>
                      {services?.data?.map((service) => (
                        <option value={service.id}>{service.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Heading size="sm" my="15px;">
                Ostale informacije
              </Heading>
              <Field name="serviceUponArrival">
                {({ field }) => (
                  <FormControl mt="10px">
                    <Select {...field} placeholder="Vrsta usluge pri prijemu" borderRadius="0" title="Vrsta usluge pri prijemu" aria-label="Vrsta usluge pri prijemu" required>
                      <option value="Rana intervencija">Rana intervencija</option>
                      <option value="Dnevni centar">Dnevni centar</option>
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Field name="note">
                {({ field }) => (
                  <FormControl mt="10px" mb="30px">
                    <Textarea {...field} type="date" id="name" placeholder="Ostale informacije" borderRadius="0" />
                  </FormControl>
                )}
              </Field>

              <Field name="isOnWaitList">
                {({ field }) => (
                  <FormControl mt="10px" mb="30px">
                    <Checkbox {...field} defaultChecked={selectedClient?.data?.is_on_wait_list || false} variant="flushed" type="text" id="to" placeholder="" borderRadius="0">
                      <label>Na listi čekanja</label>
                    </Checkbox>
                  </FormControl>
                )}
              </Field>
              <Field name="isOnWatchList">
                {({ field }) => (
                  <FormControl mt="10px" mb="30px">
                    <Checkbox {...field} defaultChecked={selectedClient?.data?.is_on_watch_list || false} variant="flushed" type="text" id="to" placeholder="" borderRadius="0">
                      <label>Na listi praćenja</label>
                    </Checkbox>
                  </FormControl>
                )}
              </Field>
            </Form>
          </Formik>
          <Divider />
          <Flex padding="20px" alignItems="center" justifyContent="center">
            <Button isLoading={updateClientMutation.isLoading} form="add-edit-employee" colorScheme="green" size="md" mr={3} borderRadius="0" type="submit">
              Spasi {addClientMutation.isLoading && <Spinner size="sm" ml="10px" />}
            </Button>
            <Button as={Link} variant="ghost" size="md" to={routes.CLIENTS}>
              Zatvori
            </Button>
          </Flex>
        </div>
      )}
    </div>
  );
};

export default AddClient;
