import { AxiosResponse } from 'axios';
import axios from '../config/axios-config';

export interface UseMutationUploadFileVariables {
  allowedPosition: string;
  clientId: string;
  description: string;
  documentType: string;
  name: string;
  isProtected: string;
  progressCallBack?: (progressEvent: ProgressEvent) => void;
  file: File;
}

const uploadClientDocument = async (data: UseMutationUploadFileVariables) => {
  const post_path = '/documents/upload/client';

  const { allowedPosition, clientId, description, file, documentType, name, isProtected, progressCallBack } = data;

  let fd = new FormData();

  //* order of appends matter, add file as last one, api endpoint is express with multer.
  fd.append('allowedPosition', allowedPosition);
  fd.append('clientId', clientId);
  fd.append('description', description);
  fd.append('file', file);
  fd.append('documentType', documentType);
  fd.append('name', name);
  fd.append('isProtected', isProtected);

  const axiosResponse = await axios.post<any, AxiosResponse<any>, FormData>(post_path, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    onUploadProgress: (progressEvent: ProgressEvent) => {
      progressCallBack && progressCallBack(progressEvent);
    },
  });
  return axiosResponse;
};

const uploadGeneralDocument = async (data: UseMutationUploadFileVariables) => {
  const post_path = '/documents/upload/general';

  const { clientId, description, file, documentType, name, progressCallBack } = data;

  let fd = new FormData();

  //* order of appends matter, add file as last one, api endpoint is express with multer.
  fd.append('clientId', clientId);
  fd.append('description', description);
  fd.append('file', file);
  fd.append('documentType', documentType);
  fd.append('name', name);

  const axiosResponse = await axios.post<any, AxiosResponse<any>, FormData>(post_path, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    onUploadProgress: (progressEvent: ProgressEvent) => {
      progressCallBack && progressCallBack(progressEvent);
    },
  });
  return axiosResponse;
};

const uploadRulesDocument = async (data: UseMutationUploadFileVariables) => {
  const post_path = '/documents/upload/rules';

  const { description, file, name, progressCallBack } = data;

  let fd = new FormData();

  //* order of appends matter, add file as last one, api endpoint is express with multer.
  fd.append('description', description);
  fd.append('file', file);
  fd.append('name', name);

  const axiosResponse = await axios.post<any, AxiosResponse<any>, FormData>(post_path, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Accept: '*/*',
    },
    onUploadProgress: (progressEvent: ProgressEvent) => {
      progressCallBack && progressCallBack(progressEvent);
    },
  });
  return axiosResponse;
};

const downloadClientDocument = async (name: string) => {
  const response = await axios({
    method: 'GET',
    responseType: 'blob',
    url: `/documents/download/client/${name}`,
  });
  const file = {
    file: response?.data,
    contentType: response.headers.contentType,
  };
  return file || {};
};

const downloadGeneralDocument = async (name: string) => {
  const response = await axios({
    method: 'GET',
    responseType: 'blob',
    url: `/documents/download/general/${name}`,
  });
  const file = {
    file: response?.data,
    contentType: response.headers.contentType,
  };
  return file || {};
};

const downloadRulesDocument = async (name: string) => {
  const response = await axios({
    method: 'GET',
    responseType: 'blob',
    url: `/documents/download/rules/${name}`,
  });
  const file = {
    file: response?.data,
    contentType: response.headers.contentType,
  };
  return file || {};
};

const getGeneralDocumens = async () => {
  const response = await axios({
    method: 'GET',
    url: '/documents/general',
  });
  return response.data || [];
};

const getRulesDocumens = async () => {
  const response = await axios({
    method: 'GET',
    url: '/documents/rules',
  });
  return response.data || [];
};

const deleteClientDocument = async (id: number) => {
  const response = await axios({
    method: 'DELETE',
    url: '/documents/general',
    params: { id },
  });
  return response.data || [];
};

const documents = {
  uploadClientDocument,
  uploadGeneralDocument,
  uploadRulesDocument,

  downloadClientDocument,
  downloadGeneralDocument,
  downloadRulesDocument,

  getGeneralDocumens,
  getRulesDocumens,

  deleteClientDocument,
};

export default documents;
