import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

import state from '../../core/state';

import constants from '../../config/constants';
import routes from '../../core/routes/routes';
import PAEANRoutes from '../../core/routes/routes';

import cacheHelper from '../../core/helpers/cacheHelper';

import './styles/main-navbar.scss';

const MainNavbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setIsLoggedIn = state.useAuth((state) => state.setIsLoggedIn);
  const setUserRoles = state.userRoles((state) => state.setUserRoles);

  const userRoles = state.userRoles((state) => state.userRoles);

  const menuItems = [
    {
      route: routes.HOME,
      icon: 'home',
      name: t('MAIN_MENU.HOME'),
    },
    {
      route: routes.CLIENTS,
      icon: 'people',
      name: t('MAIN_MENU.CLIENTS'),
    },
    {
      route: routes.DATES,
      icon: 'date_range',
      name: t('MAIN_MENU.DATES'),
    },
    {
      route: routes.REPORTS,
      icon: 'analytics',
      name: t('MAIN_MENU.REPORTS'),
    },
    {
      route: routes.EMPLOYEES,
      icon: 'badge',
      name: t('MAIN_MENU.EMPLOYEES'),
    },
    {
      route: routes.DOCUMENTS,
      icon: 'text_snippet',
      name: t('MAIN_MENU.DOCUMENTS'),
    },
    {
      route: routes.RULES,
      icon: 'gavel',
      name: t('MAIN_MENU.RULES'),
    },
    {
      route: routes.SERVICES,
      icon: 'ballot',
      name: t('MAIN_MENU.SERVICES'),
      subItems: [],
    },
    {
      route: routes.ADMINISTRATION,
      icon: 'admin_panel_settings',
      name: t('MAIN_MENU.ADMINISTRATION'),
      canAccess: [constants.USER_ROLE.ADMIN],
    },
  ];

  const isAccessible = (canAccess) => {
    if (canAccess.length < 0) {
      return true;
    } else {
      if (userRoles?.length > -1) {
        const r = (el) => userRoles.includes(el);
        const res = canAccess.some(r);
        return res;
      }
    }
  };

  const renderMenuItems = () => {
    return menuItems.map((menuItem) => {
      if (menuItem.canAccess && isAccessible(menuItem.canAccess)) {
        return (
          <li key={menuItem.name}>
            <NavLink to={menuItem.route} rel="noreferrer noopener">
              <i className="material-icons">{menuItem.icon}</i>
              {menuItem.name}
            </NavLink>
          </li>
        );
      } else if (!menuItem.canAccess) {
        return (
          <li key={menuItem.name}>
            <NavLink to={menuItem.route} rel="noreferrer noopener">
              <i className="material-icons">{menuItem.icon}</i>
              {menuItem.name}
            </NavLink>
          </li>
        );
      } else {
        return null;
      }
    });
  };

  const logout = () => {
    setIsLoggedIn(false);
    setUserRoles([]);
    cacheHelper.clearSessionStorage();

    navigate(PAEANRoutes.HOME);
  };

  return (
    <div className="main-navbar">
      <ul className="main-navbar__items">
        {renderMenuItems()}{' '}
        <li>
          <Button onClick={() => logout()}>
            <i className="material-icons">power_settings_new</i> Odjava
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default MainNavbar;
